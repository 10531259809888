import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Obfuscate from 'react-obfuscate'

class Footer extends Component {

	render() {
		return (
      <div>
        <footer>
          <p><strong>{this.props.name}</strong><br /><br />{this.props.y_tunnus}<br />
          <Obfuscate
          email={this.props.mail}
          headers={
            {subject:'Yhteydenotto reseptori.fi'}
          }/></p>
          {this.props.aboutUs && 
          <Link to={this.props.aboutUs}>
          Meistä
          </Link>
          }
        </footer>
      </div>
			)
	}
}

export default Footer
