import { actionUtils } from '../../ducks/common'


export const types = {
	SESSION_EXPIRES: Symbol('pharmacist/ui/SESSION_EXPIRES'),
	SESSION_CONTINUED: Symbol('pharmacist/ui/SESSION_CONTINUED'),
	SHOW_SYSTEM_STATUS: Symbol('pharmacist/ui/SHOW_SYSTEM_STATUS'),
	HIDE_SYSTEM_STATUS: Symbol('pharmacist/ui/HIDE_SYSTEM_STATUS'),
	TOGGLE_PRESCRIPTION_LIST_SELECTOR: Symbol('pharmacist/ui/TOGGLE_PRESCRIPTION_LIST_SELECTOR'),
}


let initialState = {
	sessionExpires: true,
	showSystemStatus: true,
	prescriptionListSelectorOpen: false,
}

export default (state = initialState, action) => {

	switch (action.type) {

		case types.SESSION_EXPIRES:
			return {
				...state,
				sessionExpires: true
			}

		case types.SESSION_CONTINUED:
			return {
				...state,
				sessionExpires: false
			}

		case types.SHOW_SYSTEM_STATUS:
		case types.HIDE_SYSTEM_STATUS:
			return {
				...state,
				showSystemStatus: !state.showSystemStatus
			}
		case types.TOGGLE_PRESCRIPTION_LIST_SELECTOR:
			return {
				...state,
				prescriptionListSelectorOpen: !state.prescriptionListSelectorOpen,
			};
		default:
			return state
	}
}

export const actions = {
	continueSession: () => actionUtils.action(types.SESSION_CONTINUED),
	hideSystemStatus: () => actionUtils.action(types.HIDE_SYSTEM_STATUS),
	showSystemStatus: () => actionUtils.action(types.SHOW_SYSTEM_STATUS),
	togglePrescriptionListSelector: () => actionUtils.action(types.TOGGLE_PRESCRIPTION_LIST_SELECTOR),
}

export const selectors = {
	getSystemStatus: (state) => (state.pharmacist.ui.showSystemStatus)
}