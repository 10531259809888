import React, { Component } from 'react'

export class BubbleSelf extends Component {
  render() {
    return (
      <div className="bubble--self__holder">
        <div className="bubble bubble--self">{this.props.content}</div>
      </div>
    )
  }
}

export default BubbleSelf