import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';

let isPrinting = false;

class PatientInfoContent extends Component {

    shouldComponentUpdate() {
        return false;
    }

    componentDidMount() {
        const ua = window.navigator.userAgent;
        if(this.props.printing && !isPrinting) {
            isPrinting = true;
            window.print();
            if(ua.indexOf('Trident/') > 0 || ua.indexOf('Edge/') > 0) {
                window.open('', '_self', ''); window.close();
            } 
            else {
                window.onafterprint = (e) => window.close();
            }
            
        }
    }

    render() {
        return (
            <div className={`${this.props.printing && 'patient-info'}`}>
                <div className="patient-info__header">
                    <h2>Potilasinfo</h2>
                    { !this.props.printing && <Link className="print" to="print" target="_blank"/> }
                </div>
                <p>
                    Hyvä asiakas, palvelun tarjoaa ja tietojenne käsittelystä<br/>
                    rekisterinpitäjänä vastaa Terveyspalvelu Reseptori Oy.<br/>
                    <br/>
                    Henkilötietojenne käsittelyä koskevaan tietosuojaselosteeseen<br/>
                    voitte tutustua osoitteessa&ensp;<a href="https://reseptori.fi/tietosuojaseloste/" target="_BLANK" rel="noopener noreferrer">https://reseptori.fi/tietosuojaseloste/</a>
                </p>
            </div>
        );
    }
}

export default withRouter(PatientInfoContent);