import React, { Component } from 'react'
import { connect } from 'react-redux';
import { actions, selectors } from '../ducks/session';
import { STRONG_PASS } from '../../common/constants';

class NewUser extends Component {
	constructor(props) {
		super(props)
		this.state = {
			firstName: '',
			lastName: '',
      tunnus: '',
      password1: '',
      password2: '',
      error: null,
      error_different_passwords: false,
      username_error: null,
      usernamelabelClass: '',
      passwordLabelClass: '',
      repeatPasswordLabelClass: '',
      firstname_invalid: null,
      lastname_invalid: null,
      saving: false
		}
  }

    componentWillReceiveProps(nextProps) {
        if(nextProps.userNameExists && nextProps.userNameExists === true) {
            this.setState({ username_error: 'Käyttäjätunnus on varattu', usernameLabelClass: ' error' });
        } else {
            if (this.state.tunnus.trim().length > 1) {
                this.setState({ username_error: null, usernameLabelClass: ' ok' })  
            }
        }
        if(nextProps.userSaved && this.state.saving) {
          this.setState({ saving: false });
          this.props.onClose();
        }
    }

    usernameValid() {
        this.setState({ username_error: null, usernameLabelClass: '' })  

        if (this.state.tunnus.length < 1 ){
            this.setState({ username_error: 'Käyttäjätunnus puuttuu', usernameLabelClass: ' error' }) 
            return false
        }
        return true;
    }

    validateUserName() {
        this.usernameValid() && this.props.checkUsername(this.state.tunnus);
    }

    fieldsHaveData() {
        return this.state.firstName.length > 1
            && this.state.lastName.length > 1
            && this.state.tunnus.length > 1
            && this.state.password1.length > 1 
            && this.state.password2.length > 1;
    }

    isInErrorState() {
        return this.state.error 
        || this.state.username_error 
        || this.state.firstname_invalid 
        || this.state.lastname_invalid 
        || this.state.error_different_passwords;
    }

    passwordValid()  {
        this.setState({ error: null, passwordLabelClass: '' })   

        if (this.state.password1.length < 8) {
          this.setState({ error: 'Salasanan pitää olla vähintään kahdeksan merkkiä pitkä.', passwordLabelClass: ' error' })      
          return false
        }
    
        if (!STRONG_PASS.test(this.state.password1)) {
          this.setState({ error: 'Salasanan pitää sisältää vähintään yksi pieni, yksi iso kirjain ja yksi numero.', passwordLabelClass: ' error' })      
          return false      
        }
        this.setState({ error: null, passwordLabelClass: ' ok' })
        return true
      }

    passwordsMatch() {
        if(this.state.password1.length < 1 || this.state.password2.length < 1) {
            this.setState({ error_different_passwords: false, repeatPasswordLabelClass: '' });   
            return true;
        }
        if (this.state.password1 !== this.state.password2) {
            this.setState({ error_different_passwords: true, repeatPasswordLabelClass: ' error' });   
            return false
        } else {
            this.setState({ error_different_passwords: false, repeatPasswordLabelClass: ' ok' });   
            return true;
        }
    }

    validatePasswords() {
        this.passwordValid() && this.passwordsMatch();
    }

    firstNameValid() {
        const validFirstName = this.state.firstName.length > 0;
        this.setState({ firstname_invalid : !validFirstName });
        return validFirstName;
    }

    lastNameValid() {
        const lastNameValid = this.state.lastName.length > 0;
        this.setState({ lastname_invalid: !lastNameValid });
        return lastNameValid;
    }

    saveUser = () => {
        const validUser = this.usernameValid()
        const validPass = this.passwordValid()
        const passowordsMatch = this.passwordsMatch();
        const validFirstName = this.firstNameValid();
        const validLastName = this.lastNameValid();

        if ( validUser && validPass && validFirstName && validLastName && passowordsMatch ) {
          const { firstName, lastName, tunnus, password1 } = this.state;
          this.props.saveUser(
              firstName,
              lastName,
              tunnus,
              password1)
          }
          this.setState({ saving: true });
    }

  render() { 
    const saveDisabled = !this.fieldsHaveData() || this.isInErrorState();

    return (
    
        <div className="register-new-user-container">
            <h2 className="title">Luo uusi käyttäjä</h2>

            <div className="form-container">
                <div className="full-name-container">
                    <div className="firstname-container">
                        <label key='1' className="card__input-label">Etunimi</label>
                        <input
                            key='2'
                            className="card__input"
                            type="text"
                            name="etunimi"
                            placeholder=""
                            value={this.state.firstName}
                            onKeyPress={this.onKeyPress}
                            onChange={(e) => this.setState({ firstName: e.target.value })}
                        />
                        { this.state.firstname_invalid  && <p className="message message--error">Etunimi on pakollinen</p> }
                    </div>

                    <div className="lastname-container">
                        <label key='3' className="card__input-label">Sukunimi</label>
                        <input
                            key='4'
                            className="card__input"
                            type="text"
                            name="sukunimi"
                            placeholder=""
                            value={this.state.lastName}
                            onKeyPress={this.onKeyPress}
                            onChange={(e) => this.setState({ lastName: e.target.value })}
                        />
                        { this.state.lastname_invalid  && <p className="message message--error">Sukunimi on pakollinen</p> }
                    </div>
                </div>
                
                <div style={{position:'relative'}}>
                <label key='5' className={"card__input-label"+ this.state.usernameLabelClass}>Käyttäjätunnus</label>
                <input
                    key='6'
                    className="card__input"
                    type="text"
                    name="tunnus"
                    placeholder=""
                    value={this.state.tunnus}
                    onKeyPress={this.onKeyPress}
                    onChange={(e) => this.setState({ tunnus: e.target.value })}
                    onBlur={e => this.validateUserName()}
                />
                { this.state.username_error && !this.props.checkingUserName 
                    ? <p className="message message--error">{this.state.username_error}</p>
                    : null 
                }
                { this.props.checkingUserName ? <div style={{position: 'absolute', display: 'inline-block', top: '50%', right: '10px'}}><i className="fas fa-spinner fa-spin spinner"></i></div> : null }
                </div>

                <label key='7' className={"card__input-label" + this.state.passwordLabelClass}>Salasana</label>
                <input
                    key='8'
                    className="card__input"
                    type="password"
                    placeholder=""
                    value={this.state.password1}
                    onKeyPress={this.onKeyPress}
                    onChange={(e) => this.setState({ password1: e.target.value })}
                    onBlur={e => this.validatePasswords()}
                />

                { this.state.error ? <p className="message message--error">{this.state.error}</p> : null }

                <label key='9' className={"card__input-label" + this.state.repeatPasswordLabelClass}>Vahvista salasana</label>
                <input
                    key='10'
                    className="card__input"
                    type="password"
                    placeholder=""
                    value={this.state.password2}
                    onKeyPress={this.onKeyPress}
                    onChange={(e) => this.setState({ password2: e.target.value })}
                    onBlur={e => this.passwordsMatch()}
                />
                
                { this.state.error_different_passwords ? <p className="message message--error">Salasanat eivät täsmää.</p> : null }
                { 
                    this.props.userSaveFailed 
                        ? <p className="message message--error">Käyttäjän lisääminen ei onnistunut! Ole hyvä ja ota yhteyttä ylläpitoon.</p> 
                        : null 
                }

                <div className="buttons">
                    <button onClick={this.props.onClose} className="cancel" type="button">Peruuta</button>
                    <button onClick={this.saveUser} disabled={ saveDisabled } className="" type="button">Tallenna</button>
                </div>
          
            </div>

        </div>
    )
  }
}

export default connect(state => ({
    users: selectors.getCurrentPharmacyUsers(state),
    userSaveFailed: state.pharmacist.session.pharmacy_user_meta.save_failed,
    checkingUserName: state.pharmacist.session.pharmacy_user_meta.checking_user_name,
    userNameExists: state.pharmacist.session.existingUserName,
    userSaved: state.pharmacist.session.pharmacy_user_meta.saved,
}), { checkUsername: actions.checkUsername})(NewUser);
