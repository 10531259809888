import React, { Component } from 'react'
import ButtonExpandText from '../common/ButtonExpandText'

import { apiConstants } from '../common/constants'
import AnimateHeight from 'react-animate-height';

const globalStatus = (p) => {
// attributes for all cards
// attributes by product type are defined in their components

  const inCart = !p.decision
  const waiting = p.decision === apiConstants.PRESCRIPTION_DECISION_UNDECIDED
  const id = inCart ? p.rowId : p.id

  let processStatus = {}

  if (inCart) {
    processStatus = { cardClass: ' renewable'}
  } else if (p.recent) {

    if (p.decision === apiConstants.PRESCRIPTION_DECISION_ACCEPTED) {
      processStatus = { cardClass: ' accepted' }
    } else if (p.decision.startsWith(apiConstants.PRESCRIPTION_DECISION_REJECTED)) {
      processStatus = { cardClass: ' declined' }
    }
  } else if (waiting) {
      processStatus = { cardClass: ' waiting' }
  } else {

    if (p.decision.startsWith(apiConstants.PRESCRIPTION_DECISION_REJECTED) || p.type === 'prescription') {
        processStatus = { cardClass: ' disabled' }
    } else {
    }
  }

  return {
    ...processStatus,
    id: id,
    decided: p.decision === apiConstants.PRESCRIPTION_DECISION_ACCEPTED || (p.decision && p.decision.startsWith(apiConstants.PRESCRIPTION_DECISION_REJECTED)),
    showPrice: inCart,
    // price: getPriceForType(p.type) + '€',
    price: p.price,
    checkboxDisabled: !inCart && (waiting || p.type === apiConstants.CART_PURCHASE_TYPE_NEW),
    checked: inCart || waiting
  }
}


class Reason4Decision extends Component {

  renderDetails = () => {

    if (!this.props.reason) return null

    const title = this.props.decision === apiConstants.PRESCRIPTION_DECISION_ACCEPTED ? 'Lisätieto' : 'Hylkäyksen peruste'

    return (
      [ <h3 className="card__subheader" key="0">{title}</h3>, <p key="1">{this.props.reason}</p> ]
    )
  }

  render() {
    return this.renderDetails()
  }
}

class Topbar extends Component {

  render() {

    const { text, showPrice, price } = this.props

    return (
      <div className="topbar">
        <span className="status">{text}</span>
        <span className="price">{showPrice ? (price / 100).toLocaleString("fi-FI", {style:"currency", currency: "EUR"}) : ''}</span>
      </div>
    )
  }
}

class PrescriptionCardBody extends Component {

  constructor(props) {
    super(props)
    this.state = { hidden: true }
  }

  toggleHidden = () => {
    this.setState({
      hidden: !this.state.hidden
    })
  }

  render() {

    const { decision, reasonForDecision, decided, medicalProblem } = this.props

    return (
          <div className= { !this.state.hidden ? "content extended" : "content"}>          
            
            <h2 onClick={this.toggleHidden}>Reseptipyyntö{decided && <ButtonExpandText />}</h2>
            <p>{medicalProblem}</p>
            
            { !this.state.hidden && decided && (
              <Reason4Decision decision={decision} reason={reasonForDecision} />
            )}

          </div>
      )
  }  
}

class RenewableCardBody extends Component {

  constructor(props) {
    super(props)
    this.state = { 
      hidden: true,
      height: 0
    }
  }

  toggleHidden = () => {
    const { height } = this.state;
    this.setState({
      hidden: !this.state.hidden,
      height: height === 0 ? 'auto' : 0,
    })
  }

  renderExpiration = () => {

    const { decision, renewableTill } = this.props

    if (decision === apiConstants.PRESCRIPTION_DECISION_ACCEPTED) {
      return (<p className="renewable__till">Uusittavissa {renewableTill} asti</p>)
    } else {
      return null
    }
  }

  render() {

    const { displayName, reason, format, reasonForDecision, decision } = this.props
    const { height } = this.state;

    return (
         
          
         <div className={ height === 0 ? "content extended" : "content extended rotate-arrow"}>
          { /*  <div className= { !this.state.hidden ? "content extended" : "content"}>     */ }

            <h2 onClick={this.toggleHidden}>{displayName}<ButtonExpandText /></h2>
            
            {/*   !this.state.hidden && (  */}
              <AnimateHeight duration={ 350 } height={ height }  >
                <div>
                  <p className="desc__extended">{reason}</p>
                  <h3 className="card__subheader">Lääkemuoto</h3>
                  <p>{format}</p>
                  <Reason4Decision decision={decision} reason={reasonForDecision} />
                  { this.renderExpiration() }
                </div>
              </AnimateHeight>
           {/*  )}  */}

          </div>
      )
  }
}

class Card extends Component {

  render() {

    const { cardClass, statusText, showPrice, price, ...other } = this.props

    return (
        <div className={"prescription__card " + cardClass}>
          
          <Topbar text={statusText} showPrice={showPrice} price={price} />
          { this.props.type === 'prescription' ? <PrescriptionCardBody { ...other } /> : <RenewableCardBody { ...other } /> }

        </div>
      )
  }
}

class PrescriptionCheckbox extends Component {

  /**
   * Safari doesn't support scroll behavior, so we must do the scrolling ourself in that browser
   */
  scrollToTop() {
    if('scrollBehavior' in document.documentElement.style) {
      this.scrollNative();
    } else {
      this.scrollRecursively();
    }
  }

  scrollNative() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  scrollRecursively() {
    const doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
    const smoothScroll = (fromTop) => {
      if (fromTop > 0) {
        setTimeout(() => {
          window.scrollTo({ top: fromTop });
          smoothScroll(fromTop - 30);
        }, 10);
      }
    }
    smoothScroll(top);
  }

  onChange(e) {
    this.props.onChange(e);
    this.scrollToTop();
  }

  render() {
    // Return an array because React requires a container for adjacent elements.
    // Actual html container breaks the layout
    return (
      [
        <input
          key="0"
          className="prescription__checkbox"
          name="prescription"
          id={this.props.id}
          type="checkbox"
          checked={this.props.checked}
          onChange={ e => this.onChange(e)}
          disabled={this.props.disabled}
        />
      ,
        <label key="1" htmlFor={this.props.id} className="prescription__label--checkbox"></label>
      ]
    )
  }
}

class CardContainer extends Component {

  render() {

    const { checkboxDisabled, checked, id, onChange, ...other } = this.props

    return (

      <div className="prescription__container">

        <PrescriptionCheckbox
          id={id}
          checked={checked}
          onChange={onChange}
          disabled={checkboxDisabled}
        />
        
        <Card { ...other } />

      </div>
      )
  }
}

class Prescription extends Component {

  constructor(props) {
    super(props)
    this.state = { hidden: true }
  }

  toggleHidden = () => {
    this.setState({
      hidden: !this.state.hidden
    })
  }

  status = (p) => {

    const gStatus = globalStatus(p)
    
    let statusText = ''

    if (!p.decision)
      statusText = 'Reseptipyyntö'
    else if (p.decision === apiConstants.PRESCRIPTION_DECISION_ACCEPTED)
      statusText = 'Resepti myönnetty'
    else if (p.decision.startsWith(apiConstants.PRESCRIPTION_DECISION_REJECTED))
      statusText = 'Reseptipyyntö hylätty'
    else
      statusText = 'Odottaa käsittelyä'

    return {
      ...gStatus,
      statusText: statusText
    }
  }


  render() {

    const props = { ...this.status(this.props.prescription), ...this.props.prescription, onChange: this.props.onChange }
    return (
      <CardContainer {...props} />
    )
  }
}

class Renewal extends Component {

  status = (p) => {

    const gStatus = globalStatus(p)
    
    let statusText = ''

    if (!p.decision) {
      statusText = 'Valittu uusittavaksi'
    } else if (p.recent) {

      if (p.decision === apiConstants.PRESCRIPTION_DECISION_ACCEPTED)
        statusText = 'Resepti uusittu'
      else if (p.decision.startsWith(apiConstants.PRESCRIPTION_DECISION_REJECTED))
        statusText = 'Resepti hylätty'
    } else {
      if (p.decision === apiConstants.PRESCRIPTION_DECISION_ACCEPTED) {
        statusText = 'Uusittavissa'
      } else if (p.decision.startsWith(apiConstants.PRESCRIPTION_DECISION_REJECTED)) {
        statusText = 'Hylätty - ei uusittavissa'
      } else {
        statusText = 'Odottaa käsittelyä'        
      }
    }

    return {
      ...gStatus,
      statusText: statusText
    }
  }

  render() {

    const props = { ...this.status(this.props.renewal), ...this.props.renewal, onChange: this.props.onChange }

    return (
      <CardContainer { ...props } />
    )
  }
}

class PrescriptionCard extends Component {

	render() {

    const { onChange, prescription } = this.props

    return this.props.prescription.type === 'prescription' ?
      <Prescription onChange={onChange} prescription={prescription} />
      : <Renewal onChange={onChange} renewal={prescription} />
	}
}

export default PrescriptionCard