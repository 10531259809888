import React, { Component } from 'react'

export class BubbleDoctor extends Component {
  render() {
    return (
      <div className="bubble--doctor__holder">
      	{/* <div className="bubble bubble--doctor">{this.props.content}</div> */}
        <div className="bubble bubble--doctor" dangerouslySetInnerHTML={{__html: this.props.content}}></div>
      </div>
    )
  }
}

export default BubbleDoctor
