import React, { Component } from 'react'
import { connect } from 'react-redux';
import { STRIPE_KEY } from '../common/constants'


import { actions } from '../ducks/stripe'

const stripe = window.Stripe(STRIPE_KEY);

class Checkout extends Component {
	constructor(props) {
    super(props)

    this.state = {
      isLoading: false
    }
  }

  paymentCallback = (token) => {
    console.log("paymentCallback")
    //this.props.payCart(token)
  }

  componentDidMount() {
    console.log(this.props.cart)
  }

  componentDidUpdate(prevProps) {
    console.log("test key",STRIPE_KEY)
    if (this.props.sessionId !== prevProps.sessionId) {
      this.checkout(this.props.sessionId);
    }
  }
  checkout = (sid) => {
    console.log("checkoutW", sid)
    stripe.redirectToCheckout({
      // Make the id field from the Checkout Session creation API response
      // available to this file, so you can provide it as parameter here
      // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
      sessionId: sid
    }).then((result) => {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
      this.setState({isLoading: false})
      console.log("redirectToCheckout error", result.error.message)
    });
  }

  clickToPay = () => {
    this.setState({isLoading: true})
    this.props.fetchStripeSession()
  }

  render() {
    let amountEur = this.props.cartPriceEuro

    return (
      <div>
        <button onClick={this.clickToPay} disabled={this.state.isLoading} id="stripe-checkout-siirry-kassalle" className={"checkout " + this.props.active}>
            <div>{this.state.isLoading ? 'Siirrytään kassalle...' : 'Siirry kassalle'}</div>
            <div className="price">{amountEur}</div>
        </button>
      </div>
    )
  }
}

export default connect(state => {
  return {
    sessionId: state.stripe.sessionId,
  }
}, { ...actions })(Checkout)
