import React from 'react'
import GoogleTagManager from './GoogleTagManager'

export const backendUrl = process.env.REACT_APP_BACKEND_URL
const samlCallbackUrl = backendUrl + "/ui-api/v1/registration/authenticate";
let signicatUrl = process.env.REACT_APP_SIGNICATURL
let signicatProtocol = process.env.REACT_APP_SIGNICAT_PROTOCOL

export const tupasUrl = () => {
	if (signicatProtocol === "SAML") {
		return signicatUrl + encodeURIComponent(samlCallbackUrl);
	} else {
		return signicatUrl;
	}
}

export const apiConstants = {

	CART_PURCHASE_TYPE_NEW: 'prescription',
	CART_PURCHASE_TYPE_RENEWAL: 'renewal',

	PRESCRIPTION_DECISION_ACCEPTED: 'approved',
	PRESCRIPTION_DECISION_REJECTED: 'rejected',
	PRESCRIPTION_DECISION_REJECTED_WITH_COUPON: 'rejected-with-coupon',
	PRESCRIPTION_DECISION_UNDECIDED: 'undecided',
  
  DELIVERY_OPTIONS: {
    OPTION_12H: 'delivery-12-h',
    OPTION_2H: 'delivery-2-h',
    OPTION_20M: 'delivery-20-min'
  },
  ORDER_STATUS: {
    WAITING: 'waiting-for-doctor',
    IN_PROGRESS: 'doctor-is-working',
    DONE: 'doctor-is-done'
  }
}

const SECOND = 1000
const MINUTE = SECOND * 60
const HOUR = MINUTE * 60

// in milliseconds
export const CUSTOMER_POLL_DECISION_INTERVAL = 30 * SECOND
export const DOCTOR_POLL_NEW_ORDERS_INTERVAL = 30 * SECOND

// How long does the order show in "last decided" list
export const LAST_DECIDED_TIME_FRAME = 24 * HOUR
export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY

// https://www.thepolyglotdeveloper.com/2015/05/use-regex-to-test-password-strength-in-javascript/
// const STRONG_PASS = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})")
export const STRONG_PASS = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})")

export const tagManager = process.env.NODE_ENV === 'development' ? null : <GoogleTagManager gtmId={process.env.REACT_APP_GTM} />

export const FINNISH_PHONE_NUMBER_FORMAT = /^(\+358|0|00358)\d{4,11}$/;

export const FINNISH_PHONE_NUMBER_AFTER_COUNTRY_CODE_FORMAT = /^\d{4,11}$/;

