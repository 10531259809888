import React, { Component } from 'react'
import SelectButton from './SelectButton'

export default class MultiChoice extends Component {

	constructor(props) {
		super(props)
		this.state = {
			selected: []
		}
	}

	toggleSelected = (value) => {
		
		let state = this.state
		
		if (state.selected.includes(value))
			state.selected = state.selected.filter(s => s !== value)
		else
			state.selected.push(value)
		
		this.setState(state)
	}

	selected = (value) => {
		return this.state.selected.includes(value)
	}

	ready = () => {
		this.props.getSelected(this.state.selected)
	}


	render() {
		return (
			<div className="select__options-container">
				<div className="select__options">
					{
						this.props.answers.map((a, ind) => {
							return <SelectButton key={ind} onClick={() => this.toggleSelected(a.value)} selected={this.selected(a.value)} content={a.text} />
						})
					}
				</div>
				<button disabled={this.state.selected.length === 0} className="button-ready" onClick={this.ready}>JATKA <i className="fas fa-angle-right" data-fa-transform="grow-6"></i></button>
			</div>
			)
	}
}