import React, { Component } from 'react'

export class SelectButton extends Component {

  render() {

  	const selected = this.props.selected ? ' selected' : ''

    return (
      <button id={this.props.id} onClick={this.props.onClick} className={'select__button' + selected}>{this.props.content}</button>
    )
  }
}

export default SelectButton
