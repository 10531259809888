import React, { Component } from 'react'
import { Provider, connect } from 'react-redux'
import { ConnectedRouter } from 'react-router-redux'
import { Route } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'

import { LandingPage, FaqPage } from './app'
import { LoginContainer } from './login'
import { LoginPharmacy, PharmacySelect, PharmacyPrescriptions } from './pharmacy/app'
import { PharmacyAdmin } from './pharmacy/admin'
import HandlingAndCampaign from './common/HandlingAndCampaign'
import { RegistrationContainer } from './registration'
import { ListHello } from './prescriptionlist'
import { ProfileBase } from './profile'
import { AppContainer, LoginDoctor, OrderHistory, DoctorAdmin } from './doctor/app'
import PatientInfoContent from './pharmacy/app/PatientInfoContent';

import { selectors, actions } from './ducks/ui'
import { actions as sessionActions, userLevels } from './ducks/session'

import 'react-responsive-modal/lib/react-responsive-modal.css'
import Modal from 'react-responsive-modal/lib/css'
import FeedbackForm from './common/FeedbackForm'

class RootContainer extends Component {

	render() {

		if (this.props.fetching)
			document.body.style.cursor='wait';
		else
			document.body.style.cursor='default';

		return (
		  <Provider store={this.props.store}>
		  	<ConnectedRouter history={this.props.history}>
		      <div>

				<Modal little
					classNames={{modal:'feedback-modal'}}
					open={this.props.showFeedbackForm}
					onClose={() => this.props.hideFeedback()}
				>
					<FeedbackForm />
				</Modal>

		        <Route exact path="/" component={LandingPage}/>
		        <Route path="/registerHello" component={RegistrationContainer} />
		        <Route path="/login" component={LoginContainer}/>
				<Route path="/logindoctor" component={LoginDoctor} />

				<Route path="/loginapteekki" component={LoginPharmacy} />

{/*				<Route path="/error" component={ErrorView} />
*/}
				<Route path="/tietoa-asiakkaalle" component={FaqPage} />

		        <PrivateRoute>
		        	{/* Logged In */}
			        <Route path="/reseptit" component={ListHello}/>
			        <Route path="/handling" component={HandlingAndCampaign}/>
			        
			        {/* Profile */}
			        <Route path="/profile" component={ProfileBase}/>
			        
			        {/* Doctor */}
			        <Route exact path="/doctor" component={AppContainer} doctor={true} userLevel={userLevels.DOCTOR} />
					<Route exact path="/doctor/history" component={OrderHistory} doctor={true} userLevel={userLevels.DOCTOR} />
					<Route exact path="/doctor/admin" component={DoctorAdmin} doctor={true} userLevel={userLevels.DOCTOR} />

					{/* Pharmacy */}
					<Route path="/reseptitapteekki" component={PharmacyPrescriptions} userLevel={userLevels.PHARMACY_USER} />
					<Route path="/toimipaikka" component={PharmacySelect} userLevel={userLevels.PHARMACY_USER} />
					<Route path="/adminapteekki" component={PharmacyAdmin} userLevel={userLevels.PHARMACY_USER} />
					<Route path="/print" component={() => <PatientInfoContent printing="true" />} userLevel={userLevels.PHARMACY_USER} />
				</PrivateRoute>

		      </div>
		  	</ConnectedRouter>
		  </Provider>
		)
	}
}

export default connect(
	state => ({
		fetching: selectors.isFetching(state),
		showFeedbackForm: selectors.showFeedbackForm(state) || false
	}),
	{ ...actions, fetchSessionData: sessionActions.fetchSessionData }
)(RootContainer)




