import React, { Component } from 'react'
import Prescription from './Prescription'

export default class PrescriptionList extends Component {

  render() {

    const { prescriptions, chosenPrescription, choosePrescription, cancelDecision, readOnly } = this.props

    return (
      <aside className="content__aside">
        <ul className="prescriptions">
        {
          prescriptions.map((p, i) => (
            <Prescription
              key={i}
              active={chosenPrescription === p}
              prescriptionId={p.id}
              type={p.type}
              name={p.type === 'prescription' ? p.medicalProblem : p.displayName}
              onClick={choosePrescription}
              state={p.state}
              decision={p.decision}
              cancelDecision={cancelDecision}
              readOnly={readOnly}
            />
          ))
        }
        </ul>
      </aside>
    )
  }
}
