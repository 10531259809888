import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { actions, userLevels } from './ducks/session'

class PrivateRoute extends Component {

	currentPathIsPrivate = () => this.props.children.find(c => c.props.path === this.props.location.pathname)

	componentDidMount() {
		const current = this.currentPathIsPrivate()
		if (current && !this.props.user) {
			// If current path needs authentication check for valid session.
			// Async call need to do the redirect if no valid session is present (check sagas/session.js)
			console.log('No valid session on app. Check server session.')
			const currentUserLevel = current.props.userLevel || userLevels.CUSTOMER_USER;
			this.props.fetchSessionData(currentUserLevel);
		}
	}

	isAuthenticated = () => {
		//return this.props.doctorUser ? this.props.doctorUser : this.props.user
		if (this.props.doctorUser) {
			return this.props.doctorUser
		} else if (this.props.pharmacistUser) {
			return this.props.pharmacistUser
		} else {
			return this.props.user
		}
	}

	render() {
		return this.isAuthenticated() ? this.props.children : null
	}
}

export default withRouter(connect(
	state => ({
		user: state.session.user,
		doctorUser: state.doctor.session.doctor,
		pharmacistUser: state.pharmacist.session.pharmacist
	}),
	{
		fetchSessionData: actions.fetchSessionData
	}
)(PrivateRoute))