import React, { Component } from 'react'

class NotificationBar extends Component {

	constructor(props) {
		super(props)
		this.state = {
            //showNotification: true,
            //notification_published: false,
            //message_published: false
		}
    }

    componentDidMount() {
        //message_published: this.props.currentMessage.status == "published" ? true : false
        //console.log("TEDÖDPIO=F ",this.props.message.status)
    }

    dismissNotification = () => {
        //this.setState({showNotification: false})
        this.props.dismissNotification()
    }


    render() {
        return (
            <div>
            {this.props.message.status === "published" && !this.props.messageStatus &&
                <div className="doctor-notification-bar">
                    <span className="notification-title">{this.props.message.header}</span>
                    <span className="notification-text">{this.props.message.additionalDetails}</span>
                    <div className="dismiss-notification" onClick={this.dismissNotification}>&times;</div>
                </div>
            }
            </div>
        )
    }
} 
export default NotificationBar