import { actionUtils } from './common'

export const types = {
	RECEIVE_PRODUCT_DATA: Symbol('product/RECEIVE_PRODUCT_DATA'),
	FETCH_PRODUCTS: Symbol('product/FETCH_PRODUCTS'),
	FETCH_ORDER_HISTORY: Symbol('product/FETCH_ORDER_HISTORY'),
	RECEIVE_ORDER_HISTORY: Symbol('product/RECEIVE_ORDER_HISTORY'),
}

let initialState = {
	orderHistory: [],
	prescriptions: [],
	products: []
}

export default (state = initialState, action) => {
	
	switch (action.type) {

		case types.RECEIVE_PRODUCT_DATA:
			return {
				...state,
				products: action.products
			}

		case types.RECEIVE_ORDER_HISTORY:
			return {
				...state,
				orderHistory: action.products
			}

		default:
			return state
	}

}

export const actions = {
	fetchProductData: () => actionUtils.action(types.FETCH_PRODUCTS),
	fetchOutstandingOrders: () => actionUtils.action(types.FETCH_ORDER_HISTORY),
}

export const selectors = {
}
