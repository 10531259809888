import React, { Component } from 'react'
import { connect } from 'react-redux';
import { actions } from '../ducks/session';
import { STRONG_PASS } from '../../common/constants';


class UserInfo extends Component {

    constructor(props) {
        super(props)
        this.initialState = {
          etunimi: '',
          sukunimi: '',
          tunnus:'',
          salasana: '',
          userIsAdmin: false,
          chosenPharmacyUser: null,
          editing_firstname: false,
          editing_lastname: false,
          editing_username: false,
          editing_password: false,
          firstname_edit_status: '',
          lastname_edit_status: '',
          username_edit_status: '',
          password_edit_status: '',
          firstname_invalid: null,
          lastname_invalid: null,
          password_error_message: '',
          username_error_message: '',
          password_invalid: false,
          form_disabled: false,
        }
        this.state = this.initialState;
    } 

    selectedUserChanged(nextProps) {
        if(!this.props.chosenUser && nextProps.chosenUser) {
            return true;
        }
        if(this.props.chosenUser && !nextProps.chosenUser) {
            return true;
        }
        return this.props.chosenUser.username !== nextProps.chosenUser.username;
    }

  componentWillReceiveProps(nextProps) {
      console.log("Chosen (componentWillReceiveProps)",nextProps.chosenUser.id)
    if (this.selectedUserChanged(nextProps)) {
        this.setState(!nextProps.chosenUser 
            ? this.initialState 
            : { 
            etunimi: nextProps.chosenUser.firstName,
            sukunimi: nextProps.chosenUser.lastName,
            tunnus: nextProps.chosenUser.username,
            salasana: nextProps.chosenUser.password,
            userIsAdmin: nextProps.chosenUser.userLevel === 'admin',
        });
    }
    this.checkUserNameStatus(nextProps);
  }

  checkUserNameStatus(nextProps) {
    if(this.state.editing_username === true && !nextProps.checkingUserName) {
        if(nextProps.userNameExists === false) {
            this.onValidBlur();
            this.updateUser();
        } else {
            this.setState({  username_edit_status: 'edit-error', editing_user_name: false, username_error_message: 'Käyttäjätunnus on jo varattu!' });
        }
    }
  }

  onValidBlur() {
    this.setState({ editing_username: false, username_edit_status: 'edit-ok', form_disabled: false, username_error_message: '' });
    setTimeout(() => this.inputUserName.blur(), 100);
    setTimeout(() => this.setState({ username_edit_status: ''}), 2000);
  }

  componentWillUnmount() {
    //kill lingering timeouts if any 
    var i = window.setTimeout(null,0);
    while (i--) {
        window.clearTimeout(i);
    }
  }
  valid(str) {
    let value = str.target.value;
    if (value.length < 2) {
        return false
    }
    return true
  } 
    firstNameValid() {
        const validFirstName = this.state.etunimi.length > 0;
        this.setState({ firstname_invalid : !validFirstName });
        return validFirstName;
    }

    lastNameValid() {
        const lastNameValid = this.state.sukunimi.length > 0;
        this.setState({ lastname_invalid: !lastNameValid });
        return lastNameValid;
    }
    disableFields() {
        this.setState({ form_disabled: true })
    }
    enableFields() {
        this.setState({ form_disabled: false })
    }

    handleFirstNameEdit = () => {
        setTimeout(() => this.inputFirstName.focus(), 100)
    }
    handleLastNameEdit = () => {
        setTimeout(() => this.inputLastName.focus(), 100)
    }
    handleUserNameEdit = () => {
        setTimeout(() => this.inputUserName.focus(), 100)
    }
    handlePasswordEdit = () => {
        setTimeout(() => this.inputPassword.focus(), 100)
    }
    onFocusFirstName = e => {
        if(this.state.firstname_invalid) {
            this.setState({ editing_firstname: true,  form_disabled: true });
        } else {
            this.setState({ editing_firstname: true,  form_disabled: true, etunimi: '' });
        }
    }

    onFocusLastName = e => {
        if(this.state.lastname_invalid) {
            this.setState({ editing_lastname: true, form_disabled: true });
        } else {
            this.setState({ editing_lastname: true, form_disabled: true, sukunimi: '' });
        }
    }    
    onFocusUserName = e => {
        if(this.state.username_edit_status === 'edit-error') {
            this.setState({ editing_username: true, form_disabled: true })
        } else {
            this.setState({ editing_username: true, form_disabled: true, tunnus: '' })
        }
    }

    onFocusPassword = e => {
        if(this.state.password_invalid) {
            this.setState({ editing_password: true, form_disabled: true })
        } else {
            this.setState({ editing_password: true, form_disabled: true, salasana: '' })
        }
    }

    onBlurFirstName = e => {
        if (this.firstNameValid() ) {
            setTimeout(() => this.inputFirstName.blur(), 100)
            this.setState({ firstname_edit_status: 'edit-ok', etunimi: e.target.value, editing_firstname: false })
            setTimeout(() => this.setState({ firstname_edit_status: ''}), 2000)
            this.updateUser()
            this.enableFields()
        } else {
            this.setState({  firstname_edit_status: 'edit-error' });
            this.handleFirstNameEdit();
        }
    }

    onBlurLastName = e => {
        if (this.lastNameValid() ) {
            setTimeout(() => this.inputLastName.blur(), 100)
            this.setState({ lastname_edit_status: 'edit-ok', sukunimi: e.target.value, editing_lastname: false })
            setTimeout(() => this.setState({ lastname_edit_status: ''}), 2000)
            this.updateUser()
            this.enableFields()
        } else {
            this.setState({  lastname_edit_status: 'edit-error' })
            this.handleLastNameEdit();
        }
    }
    onBlurUserName = e => {
        if(this.state.tunnus === this.props.chosenUser.username) {
            this.onValidBlur();
        } else if (this.valid(e) ) {
            this.props.checkUsername(this.state.tunnus);
        } else {
            this.setState({  username_edit_status: 'edit-error', username_error_message: 'Käyttäjätunnus on oltava vähintään 2 merkkiä pitkä' })
        }
    }

    onBlurPassword = e => {
        if (this.validPassWord(e) ) {
            setTimeout(() => this.inputPassword.blur(), 100)
            this.setState({ password_edit_status: 'edit-ok', salasana: e.target.value, editing_password: false })
            setTimeout(() => this.setState({ password_edit_status: ''}), 2000)
            this.updateUser()
            this.enableFields()
        } else {
            this.setState({  password_edit_status: 'edit-error' })
            this.handlePasswordEdit();
        }
    }

    validPassWord = () => {

        if (!STRONG_PASS.test(this.state.salasana)) {
            this.setState({ password_invalid: true, password_error_message: 'Salasanan pitää sisältää vähintään yksi pieni, yksi iso kirjain ja yksi numero.'})
            return false      
        } else {
            this.setState({ password_invalid: false, password_error_message: ''})
            return true
        }
    }

    updateFirstName = e => {
        if (e.key === 'Enter') this.onBlurFirstName(e)
    }

    updateLastName = e => {
        if (e.key === 'Enter') this.onBlurLastName(e)
    }

    updateUserName = e => {
        if (e.key === 'Enter') this.onBlurUserName(e)
    }    
    updatePassword = e => {
        if (e.key === 'Enter') this.onBlurPassword(e)
    }

    updateUser = () => {
        this.props.updateUser(
        this.state.etunimi,
        this.state.sukunimi,
        this.state.tunnus,
        this.state.salasana) 

        this.enableFields()
  }

  confirmDeleteUser = () => {
    this.props.confirmDeleteUser(this.state.tunnus)
  }

  renderUser() {
      const { form_disabled, userIsAdmin, editing_firstname, editing_lastname, editing_password, editing_username } = this.state;
    return (
         
        <div className="pharmacy-user-info">
        <fieldset>
            <div className={"input-wrapper " + this.state.firstname_edit_status}>
                <label>Etunimi</label>
                <div className="input-group">
                    <input
                        id="etunimi"
                        className="input-control"
                        value={this.state.etunimi}
                        onChange={e => this.setState({ etunimi: e.target.value })}
                        onKeyPress={this.updateFirstName}
                        type="text" 
                        ref={(input) => { this.inputFirstName = input; }}
                        onFocus={this.onFocusFirstName}
                        onBlur={this.onBlurFirstName}
                        disabled={form_disabled && !editing_firstname}
                    />
                    { this.state.firstname_invalid  && <span className="message message--error">Etunimi on pakollinen</span> }
                </div>
                    <span className={this.state.editing_firstname ? "edit-value editing" : "edit-value"} 
                        onClick={this.handleFirstNameEdit}><span className="edit"></span>
                    </span>
            </div>
            

            <div className={"input-wrapper " + this.state.lastname_edit_status}>
            <label>Sukunimi</label>
                <div className="input-group">
                    <input
                        id="sukunimi"
                        className="input-control"
                        value={this.state.sukunimi}
                        onChange={e => this.setState({ sukunimi: e.target.value })}
                        onKeyPress={this.updateLastName}
                        type="text" 
                        ref={(input) => { this.inputLastName = input; }}
                        onFocus={this.onFocusLastName}
                        onBlur={this.onBlurLastName}
                        disabled={form_disabled && !editing_lastname}
                    />
                    { this.state.lastname_invalid  && <span className="message message--error">Sukunimi on pakollinen</span> }
                </div>
                    <span className={this.state.editing_lastname ? "edit-value editing" : "edit-value"} 
                        onClick={this.handleLastNameEdit}><span className="edit"></span>
                    </span>
            </div>

            <div className={"input-wrapper " + this.state.username_edit_status}>
            <label>Käyttäjätunnus</label>
                <div className="input-group">
                    <input
                        id="tunnus"
                        className="input-control"
                        value={this.state.tunnus}
                        onChange={e => this.setState({ tunnus: e.target.value })}
                        onKeyPress={this.updateUserName}
                        type="text" 
                        ref={(input) => { this.inputUserName = input; }}
                        onFocus={this.onFocusUserName}
                        onBlur={e => this.onBlurUserName(e)}
                        disabled={form_disabled && !editing_username}
                    />
                    { this.state.username_error_message  && <span className="message message--error">{ this.state.username_error_message }</span> }
                </div>
                    <span className={this.state.editing_username ? "edit-value editing" : "edit-value"} 
                        onClick={this.handleUserNameEdit}><span className="edit"></span>
                    </span>
                { this.props.checkingUserName ? <div><i className="fas fa-spinner fa-spin spinner"></i></div> : null }
            </div>
            
            <div className={"input-wrapper " + this.state.password_edit_status}>
            <label>Salasana</label>
                <div className="input-group">
                    <input
                        id="salasana"
                        className="input-control"
                        value={this.state.salasana}
                        onChange={e => this.setState({ salasana: e.target.value })}
                        onKeyPress={this.updatePassword}
                        type="password" 
                        ref={(input) => { this.inputPassword = input; }}
                        onFocus={this.onFocusPassword}
                        onBlur={this.onBlurPassword}
                        disabled={form_disabled && !editing_password}
                    />
                    { this.state.password_invalid  && <span className="message message--error">{this.state.password_error_message}</span> }
                </div>
                    <span className={this.state.editing_password ? "edit-value editing" : "edit-value"} 
                        onClick={this.handlePasswordEdit}><span className="edit"></span>
                    </span>
            </div>
            { !userIsAdmin &&
                <button onClick={this.confirmDeleteUser} className="delete-user" type="button"><i className="far fa-trash-alt"></i> Poista käyttäjä</button>
            }
            </fieldset>
        </div>
    )
  }
  render() {
    return this.props.chosenUser ? this.renderUser() : null
  }
}

export default connect(state => ({
    checkingUserName: state.pharmacist.session.pharmacy_user_meta.checking_user_name,
    userNameExists: state.pharmacist.session.existingUserName,
}), { 
    checkUsername: actions.checkUsername
})(UserInfo);
