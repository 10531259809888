import { apiConstants } from '../../common/constants'

export const chatTypes = {
	TYPE_ANSWER: 'ANSWER',
	TYPE_QUESTION: 'QUESTION',
	TYPE_SELECT: 'SELECT',
	TYPE_INPUT: 'INPUT',
	TYPE_INPUT_AUTO_COMPLETE: 'INPUT_AUTO_COMPLETE',
  TYPE_MULTICHOICE: 'TYPE_MULTICHOICE',
  TYPE_RADIO: 'TYPE_RADIO'
}

class ObservedHistory {
	constructor(observer) {
		this.arr = [];
		this.observer = observer;
	}

	push(item) {
		this.arr.push(item);
		this.observer(this.arr);
		return true;
	}

	pop() {
		this.arr.pop();
		this.observer(this.arr);
		return true;
  }
}

function getChat(history, patient, chooseProduct, sendToDoctor, setDeliveryOption) {

	const newRoundOptions = () => {
		history.push({
			options: [
				{
					text: 'Hae toista reseptiä',
					callback: () => {
						history.pop()
						history.push({
							text: 'Haen toista reseptiä',
							type: chatTypes.TYPE_ANSWER
						})
						history.push(script[0])
					}
				},
				{
					text: 'Tilaus valmis',
					callback: () => {
            const expressOrderCallback = (orderType) => {
              setDeliveryOption(orderType);
              const lastItemOnHistory = history.arr[history.arr.length -1];
              // Add complete-button only once. If not this check then new button
              // would be added each time user changes radio button value
              if (lastItemOnHistory.id !== 'complete-order') {
                history.push({
                  id: 'complete-order',
                  type: chatTypes.TYPE_SELECT,
                  options: [{
                    text: 'Lähetä tilaus',
                    callback: () => sendToDoctor()
                  }]
                });
              }
            }
            history.pop();
            history.push({
              text: 'Tilaus on valmis',
              type: chatTypes.TYPE_ANSWER
            })
            history.push({
              text: 'Valitse tilaukselle käsittelyaika ja lähetä tilaus',
              type: chatTypes.TYPE_QUESTION
            });
            history.push({
              callback: (type) => expressOrderCallback(type),
              type: chatTypes.TYPE_RADIO,
              options: [{
                text: "Uusinta seuraavaksi\n arkiaamuksi\n 2€/lääke",
                value: apiConstants.DELIVERY_OPTIONS.OPTION_12H
              }, {
                text: "Pikauusinta 15min \n 14,90€/lääke \n 4,90€/seuraavat lääkkeet",
                value: apiConstants.DELIVERY_OPTIONS.OPTION_20M
              }
            ]
            });
          }
				}
			],
			type: chatTypes.TYPE_SELECT
		})
	}

	const formatPatient = () => {
		if(!patient) return '';
		return patient.firstName.split(" ", 1) + ' ' + patient.lastName;
	}


	let script = [ 
				// UUSINTAHELVETTI



				{
					    	text: 'että jä',
					    	type: chatTypes.TYPE_INPUT_AUTO_COMPLETE,
					    	select: (p) => {

					    		const product = p
					    		let reason = ''

								  history.pop();
					    		history.push({
					    			text: product.displayName,
					    			type: chatTypes.TYPE_ANSWER
					    		})

                  history.push({
                    text: 'Tarvittaessa kerro lisätietoja tai peruste reseptin uusinnalle.',
                    type: chatTypes.TYPE_QUESTION
                  })

                  history.push({
                    text: 'Lisätietoja',
                    type: chatTypes.TYPE_INPUT,
                    callback: (r) => {

                      reason = r

                      history.pop()
                      history.push({
                        text: reason,
                        type: chatTypes.TYPE_ANSWER
                      })

                      history.push({
                        text: 'Reseptipyyntö luotu:<br/>' +
                          '<b>' + product.displayName + '</b><br/><br/>' +
                          'Lisätieto:<br/><b>' + reason + '</b>',
                        type: chatTypes.TYPE_QUESTION
                      });
                      history.push({
                        options: [{
                          text: 'Peruuta',
                          callback: () => {
                            history.pop()
                            history.push({
                              text: 'Peru reseptin uusinta',
                              type: chatTypes.TYPE_ANSWER
                            })        											
                            history.push({
                              text: 'Reseptin uusinta on peruttu. Haluatko uusia muita reseptejä vai oletko valmis?',
                              type: chatTypes.TYPE_QUESTION
                            })
                            newRoundOptions()
                          }
                        },{
                        text: 'Kyllä, uusitaan resepti',
                        id: 'btn-uusitaan-resepti',
                            callback: () => {
                              
                              chooseProduct(
                                product.productId,
                                apiConstants.CART_PURCHASE_TYPE_RENEWAL,
                                reason
                            )

                              history.pop()
                              history.push({
                                text: 'Kyllä, uusitaan resepti',
                                type: chatTypes.TYPE_ANSWER
                              })

                          history.push({
                            text: `Lähetä uusinta, tai hae lisää uusittavia lääkkettä asiakkaalle <b>${formatPatient()}</b>`,
                            type: chatTypes.TYPE_QUESTION
                          })
                          
                          newRoundOptions()
                            }
                          }],
                          type: chatTypes.TYPE_SELECT
                        })
                    }
        						})
					    		
					    	}

						}

				// /UUSINTAHELVETTI

	]

	return script
}

export function chatEngine(onChange, patient, chooseProduct, sendToDoctor, setDeliveryOption) {
	let history = new ObservedHistory(onChange);

	history = getChat(history, patient, chooseProduct, sendToDoctor, setDeliveryOption)

	return history
}

