import './scss/main.css'
import './doctor/scss/main.css'

import 'babel-polyfill'

import React from 'react'
import ReactDOM from 'react-dom'

import { createStore, applyMiddleware, compose } from 'redux'

import createHistory from 'history/createBrowserHistory'
import { routerMiddleware } from 'react-router-redux'

import createSagaMiddleware from 'redux-saga'
import sagas from './sagas'
import thunk from 'redux-thunk'

import RootContainer from './RootContainer'



import combined from './ducks'

const sagaMiddleware = createSagaMiddleware()
const history = createHistory()

const middleware = applyMiddleware(
  sagaMiddleware,
  thunk,
  routerMiddleware(history)
)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  combined,
  composeEnhancers(middleware)
)

const rootSagaTask = sagaMiddleware.run(sagas)
rootSagaTask.done.catch(error => {
  console.log ("Unhandled Redux-Saga exception!!!", error)
});

// sagaMiddleware.run(drugSaga)

ReactDOM.render(
  <RootContainer store={store} history={history} />  
	, document.getElementById('root')
)

        // <Route path="/registerEmail" component={RegisterEmail}/>
        // <Route path="/registerPhone" component={RegisterPhone}/>
        // <Route path="/registerPin" component={RegisterPin}/>
        // <Route path="/registerTOS" component={RegisterTOS}/>
