import React, { Component } from 'react'
import Card from '../common/Card'

import { connect } from 'react-redux'
import { actions } from '../ducks/session'

import { tupasUrl } from '../common/constants'

class LoginContainer extends Component {

	constructor(props) {
		super(props)
		this.state = {
			email: '',
			password: ''
		}
	}

	getEmailInput() {
		return [
			<p key='1' className="card__input-label">Sähköposti</p>,
			<input
				key='2'
				className="card__input card__input--email"
				type="text"
				name="username"
				placeholder=""
				value={this.state.email}
				onKeyPress={this.onKeyPress}
				onChange={(e) => this.setState({ email: e.target.value })}
			/>
		]
	}

	getPasswordInput() {
		return [
			<p key='3' className="card__input-label">Salasana</p>,
			<input
				key='4'
				className="card__input card__input--password"
				type="password"
				value={this.state.password}
				placeholder=""
				onKeyPress={this.onKeyPress}
				onChange={ e => this.setState({ password: e.target.value })}
			/>
		]
	}

	onKeyPress = (e) => {
		if (e.key === 'Enter') this.login()
	}

	getError = () => {
		return this.props.loginFailed ? <div key='5' className="message--error">Kirjautumistiedot virheelliset. Yritä uudelleen</div> : null
	}

	getInputs() {
		return [ this.getEmailInput(), this.getPasswordInput(), this.getError() ]
	}

	login = () => {
		this.props.login(this.state.email, this.state.password)
	}

	render() {
	    return (
			<Card
				heading="Kirjaudu tilillesi"
				cardPurposeStyle="card--login"
				input={this.getInputs()}
				previousPath="/"
				forgot="Unohditko tilitietosi?"
				forgotUrl={tupasUrl()}
				loggedIn={false}
				disabled={!(this.state.email && this.state.password)}
				onSubmit={() => this.login()}
			/>
	    )
	}
}

export default connect(
	state => ({
		loginFailed: state.ui.loginFailed,

	}),
	{
	  ...actions
	}
)(LoginContainer)