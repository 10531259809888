import { call, fork, put, take } from 'redux-saga/effects'
import { types, userLevels } from '../ducks/session'
import { types as productTypes } from '../ducks/product'

import { actions as uiActions } from '../ducks/ui'

import { push } from 'react-router-redux'

import api from '../api'
import { makeRequest, fetchProductData, fetchCurrentCart, putCurrentCart, fetchDoctorSession, fetchPharmacistSession } from './common'

const login = makeRequest.bind(null, api.login)
const logout = makeRequest.bind(null, api.logout)
const fetchUser = makeRequest.bind(null, api.fetchUser)
const fetchFeedback = makeRequest.bind(null, api.fetchFeedbackText)
const giveFeedback = makeRequest.bind(null, api.giveFeedback)
const payCart = makeRequest.bind(null, api.payCart)
const removeItem = makeRequest.bind(null, api.removeItemFromCart)
const applyCoupon = makeRequest.bind(null, api.applyCoupon)
const unApplyCoupon = makeRequest.bind(null, api.unApplyCoupon)
const fetchPharmacyOffices = makeRequest.bind(null, api.pharmacyOffices)

function* fetchOrderHistory() {

	const response = yield call(makeRequest, api.fetchOrderHistory)

	if (!response.ok) {
		throw new Error('API call failed with ', response)			
	}

	const data = yield response.json()

	yield put({
		type: productTypes.RECEIVE_ORDER_HISTORY,
		products: data.data
	})
}

function* removeItemFromCart(id) {

	const response = yield call(removeItem, id)

	if (!response.ok) {
		throw new Error('API call failed with ', response)
	}

	const data = yield response.json()
	yield call(putCurrentCart, data)
}

function* addItem2Cart(type, productId, reason) {
	const response = yield call(makeRequest, api.addItem2Cart, type, productId, reason)

	if (!response.ok) {
		throw new Error('API call failed with ', response)
	}

	const data = yield response.json()
	yield call(putCurrentCart, data)

}

function* addPrescription2Cart(problem, chat) {

	const response = yield call(makeRequest, api.addPrescription2Cart, problem, chat)

	if (!response.ok) {
		throw new Error('API call failed with ', response)
	}

	const data = yield response.json()
	yield call(putCurrentCart, data)

}

function* payCartSaga(card, health) {

	const response = yield call(payCart, card, health)
	const data = yield response.json()
	
	return data
}

function* fetchFeedbackText() {

	const response = yield call(fetchFeedback)
	const data = yield response.json()

	yield put({
		type: types.RECEIVE_FEEDBACK_TEXT,
		feedbackText: data.question
	})	
} 

export function* giveFeedbackListener() {
	while (true) {

		const action = yield take(types.GIVE_FEEDBACK)

		try {
			yield put(uiActions.startRequest())
			yield call(giveFeedback, action.rating, action.feedback)
		} finally {
			yield put(uiActions.endRequest())		
		}			

	}
}

export function* unApplyCouponListener() {
	
	while (true) {
	
		yield take(types.UN_APPLY_COUPON)

		try {
			yield put(uiActions.startRequest())

			const response = yield call(unApplyCoupon)
			const data = yield response.json()
			yield call(putCurrentCart, data)

			yield put(uiActions.couponSubmitted(false))

		} finally {
			yield put(uiActions.endRequest())		
		}			

	}
}

export function* applyCouponListener() {
	
	const couponNotFound = 400

	while (true) {
	
		const action = yield take(types.APPLY_COUPON)

		try {
			
			yield put(uiActions.startRequest())

			const response = yield call(applyCoupon, action.code)
			const data = yield response.json()
			yield call(putCurrentCart, data)
			yield put(uiActions.couponSubmitted(true))

		} catch (response) {
			if (response.status === couponNotFound) {
				yield put(uiActions.couponSubmitted(true))
			} else {
				throw response
			}
		} finally {
			yield put(uiActions.endRequest())		
		}			

	}
}

export function* removeItemFromCartListener() {
	
	while (true) {
	
		const action = yield take(types.REMOVE_ITEM_FROM_CART)

		try {
			yield put(uiActions.startRequest())
			yield call(removeItemFromCart, action.rowId)
		} finally {
			yield put(uiActions.endRequest())		
		}			

	}
}

export function* addItem2CartListener() {
	while (true) {

		const action = yield take(types.CHOOSE_PRODUCT)
		
		try {
			yield put(uiActions.startRequest())
			yield call(addItem2Cart, action.orderType, action.productId, action.reason)
		} finally {
			yield put(uiActions.endRequest())		
		}			
	}
}

export function* addPrescription2CartListener() {

	while (true) {

		const action = yield take(types.CHOOSE_PRESCRIPTION)

		try {
			yield put(uiActions.startRequest())
			yield call(addPrescription2Cart, action.problem, action.chat)
		} finally {
			yield put(uiActions.endRequest())		
		}
	}
}

export function* payCartListener() {

	while (true) {

		const action = yield take(types.PAY_CART)

		try {
	
			yield put(uiActions.startRequest())
	
			const history = yield call(payCartSaga, action.token)
			yield call(fetchCurrentCart)

			yield put({
				type: productTypes.RECEIVE_ORDER_HISTORY,
				products: history.data
			})

			yield put(push('/reseptit'))

		} catch (e) {
			console.log('Everything is ruined! What can we do!!')
		} finally {
			yield put(uiActions.endRequest())		
		}
	}
}

function* fetchSession() {
	console.log("fetchSession")
	const fetchResponse = yield call(fetchUser)
	const data = yield fetchResponse.json()

	yield put({ type: types.RECEIVE_USER_DATA, data: data })

	yield call(fetchProductData)
	yield call(fetchCurrentCart)
	yield call(fetchOrderHistory)
	yield call(fetchFeedbackText)
}


export function* PharmacyOffices() {
	const fetchResponse = yield(fetchPharmacyOffices)
	const data =  yield fetchResponse.json()
	console.log('pharmacyOffices', data)

}

export function* fetchSessionData() {
	console.log("fetchSessionData")
	while (true) {

		const action = yield take(types.FETCH_SESSION_DATA)
		
		console.log("action", action)
		try {
			
			yield put(uiActions.startRequest())

			switch (action.userLevel) {
				case userLevels.CUSTOMER_USER:
					yield call(fetchSession);
					break;
				case userLevels.DOCTOR:
					yield call(fetchDoctorSession)
					break;
				case userLevels.PHARMACY_USER:
					yield call(fetchPharmacistSession)
					break;
				default:
					throw new Error("Unknown userLevel for action: " + action.userLevel);
			}
			
		} catch (e) {
			console.log("Session expired")
			//action.doctor ? yield put(push('/loginDoctor')) : yield put(push('/login'))
			if(action.doctor) {
				yield put(push('/logindoctor'))
			} else if(action.pharmacist) {
				yield put(push('/loginapteekki'))
			} else {
				yield put(push('/login'))
			}
		} finally {
			yield put(uiActions.endRequest())		
		}
	}
}

export function* loginUser(email, password) {

	try {
		yield put(uiActions.startRequest())
		
		yield call(login, email, password)
		yield call(fetchSession)

		yield put(uiActions.login(true))
		yield put(push('/reseptit'))

	} catch (e) {
		console.log('Login failed ', e)
		yield put(uiActions.login(false))
	} finally {
		yield put(uiActions.endRequest())		
	}

}

export function* fetchOrderHistoryListener() {
	while (true) {
		yield take(productTypes.FETCH_ORDER_HISTORY)
		yield fork(fetchOrderHistory)
	}
}

export function* loginListener() {
	while (true) {
		const action = yield take(types.LOGIN, login)		
		yield call(loginUser, action.email, action.password)
	}
}

export function* logoutListener() {
	
	while (true) {

		yield take(types.LOGOUT)

		try {

			yield put(uiActions.startRequest())
			yield call(logout)
			yield put({ type: types.RECEIVE_USER_DATA, data: null })
			yield call(() => window.location = 'https://reseptori.fi/')
			
			// document.cookie = 'PLAY_SESSION=; Max-Age=-99999999;'
		} finally {
			yield put(uiActions.endRequest())		
		}
	}
}

export function* fetchProductDataListener() {
	while (true) {
		yield take(productTypes.FETCH_PRODUCTS);
		yield call(fetchProductData);
	}
}

