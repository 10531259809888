import React, { Component } from 'react'

export class Input extends Component {

	constructor(props) {
		super(props)
		this.state = {
			value: ''
		}
	}

	update = ({target}) => {
		this.setState({ value: target.value })
	}

	onKeyPress = (e) => {
    if (e.key === 'Enter')
		  this.props.valueChanged(this.state.value)
	}
	onBlur = (e) => {
		  this.props.valueChanged(this.state.value)
	}

  render() {
    return (
      <input
      	onKeyPress={this.onKeyPress}
        onChange={this.update}
      	value={this.state.value}
      	className="chat__input"
      	type="text"
				placeholder={this.props.placeholder || ''}
				onBlur={this.onBlur}
      />
    )
  }
}

export default Input
