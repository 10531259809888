import React, { Component } from 'react'


export default class DeleteUser extends Component {

    deleteUser = () => {
      this.props.deleteUser()
    }

  render() { 

    return (
    
        <div className="delete-user-container">
            <h2 className="title black">Käyttäjätunnuksen poistaminen</h2>
            <p>Oletko varma, että haluat poistaa käyttäjätunnuksen?<br />Toimintaa ei voi perua.</p>

            <div className="form-container">
                
                <div className="buttons">
                    <button onClick={this.props.onClose} className="black" type="button">Peruuta</button>
                    <button onClick={this.deleteUser} className="black" type="button">Poista</button>
                </div>
          
            </div>

        </div>
    )
  }
}