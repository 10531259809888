import { call, fork, put, take } from 'redux-saga/effects'
import { types } from '../ducks/session'
import { push } from 'react-router-redux'
import api from '../../api'

import {
	makeRequest,
	fetchDoctorSession,
	fetchSystemStatusSaga,
	fetchCompletedOrders
} from '../../sagas/common'

import { actions as uiActions } from '../../ducks/ui'

const login = makeRequest.bind(null, api.loginDoctor)
const logout = makeRequest.bind(null, api.logoutDoctor)
const fetchNextOrder = makeRequest.bind(null, api.pickNextOrder)
const releaseOrder = makeRequest.bind(null, api.releaseOrder)
const releaseOneOrder = makeRequest.bind(null, api.releaseOneOrder)
const submitOrder = makeRequest.bind(null, api.submitOrder)
const submitDecision = makeRequest.bind(null, api.submitDecision)
const saveMessage = makeRequest.bind(null, api.setMessage)

function* loginSaga(email, password) {

	yield call(login, email, password)
	yield call(fetchDoctorSession)
	//yield call(fetchDoctorMessage)
}

function* pickNextOrder() {

	const response = yield call(fetchNextOrder)

	if (response.status === 204) {
	// No content => there is no next order
		yield put({ type: types.RECEIVE_NEXT_ORDER, order: null })
	} else {
		const order = yield response.json()
		yield put({ type: types.RECEIVE_NEXT_ORDER, order: order })
	}

}

export function* pickOrderListener() {
	while (true) {

		yield take(types.PICK_NEXT_ORDER)
	
		try {
			yield put(uiActions.startRequest())		
			yield call(pickNextOrder)
		} finally {
			yield put(uiActions.endRequest())		
		}	
	}
}

export function* releaseOrderListener() {

	while (true) {
	
		yield take(types.RELEASE_ORDER)
	
		try {
			
			yield put(uiActions.startRequest())

			yield call(releaseOrder)
			yield call(fetchSystemStatusSaga)
		} finally {
			yield put(uiActions.endRequest())		
		}	
	}
}

export function* releaseOneOrderListener() {

	while (true) {
	
		const action = yield take(types.RELEASE_ONE_ORDER)
	
		try {
			
			yield put(uiActions.startRequest())
			yield call(releaseOneOrder, action.orderId)
		} finally {
			yield put(uiActions.endRequest())		
		}	
	}
}

export function* submitOrderListener() {

	while (true) {

		yield take(types.SUBMIT_ORDER)

		try {
			
			yield put(uiActions.startRequest())
		
			yield call(submitOrder)
			yield call(fetchSystemStatusSaga)
			yield call(pickNextOrder)
		} finally {
			yield put(uiActions.endRequest())		
		}	
	}
}

export function* submitDecisionListener() {

	while (true) {
	
		const action = yield take(types.SUBMIT_DECISION)

		try {

			yield put(uiActions.startRequest())
		
			const response = yield call(submitDecision, action.id, action.decision, action.reason)
			const prescription = yield response.json()
			yield put({ type: types.UPDATE_PRESCRIPTION, prescription: prescription })
		} finally {
			yield put(uiActions.endRequest())		
		}
	}
}

export function* fetchOrderListener() {

	while (true) {
		yield take(types.FETCH_ORDERS)
		yield fork(fetchSystemStatusSaga)
	}
}

export function* saveDoctorMessageListener() {

	while (true) {

		const action = yield take(types.SAVE_MESSAGE)
		try {

			yield put(uiActions.startRequest())
			const data = {
				id: 1,
				header: action.title,
				additionalDetails: action.text,
				status: action.status
			}

			const response = yield call(saveMessage, data)

			console.log("saveDoctorMessageListener",response)

			yield call(fetchDoctorMessage)

		} finally {
			yield put(uiActions.endRequest())		
		}
	}
}

export function* getDoctorMessageListener() {
	while (true) {
		yield take(types.GET_MESSAGE)
		try {
			yield put(uiActions.startRequest())
			yield call(fetchDoctorMessage)
			/*const response = yield call(getMessage)
			console.log("GETMESS response:",response)
			const data = yield response.json()
			console.log("GETMESS data:",data)
			yield put({ type: types.RECEIVE_MESSAGE, data: data })
			*/
		} finally {
			yield put(uiActions.endRequest())		
		}
	}
}

function* fetchDoctorMessage() {
	console.log("fetchDoctorMessage")

	const response = yield call(makeRequest, api.getMessage)

	if (!response.ok) {
		throw new Error('API call failed with ', response)			
	}

	const data = yield response.json()
	console.log('fetchDoctorMessage' ,data)

	yield put({
		type: types.RECEIVE_MESSAGE,
		data: data
	})	
}

export function* logoutListener() {
	while (true) {
		yield take(types.LOGOUT)
		yield call(logout)
		yield put(push('/loginDoctor'))
	}
}

export function* loginListener() {

	while (true) {

		const action = yield take(types.LOGIN)

		try {

			yield put(uiActions.startRequest())

			yield call(loginSaga, action.email, action.password)

			yield put(push('/doctor'))

		} catch (e) {
			console.log('Login failed', e);
			yield put(uiActions.login(false));
		} finally {
			yield put(uiActions.endRequest())		
		}

	}
}

export function* fetchCompletedOrdersListener() {
	while (true) {
		const action = yield take(types.FETCH_COMPLETED_ORDERS);
		yield fork(fetchCompletedOrders, action.filter, action.sortOrder, action.offset);
	}
}


