import React, { Component } from 'react';
import { DoctorNavigationBar } from '../../common/NavigationBar';
import Card from '../../common/Card';

import { connect } from 'react-redux';
import { actions } from '../ducks/session';

export class LoginDoctor extends Component {

  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: ''
    }
  }

  onSubmit = () => {
    this.props.login(this.state.email, this.state.password)
  }

  onKeyPress = (e) => {
    if (e.key === 'Enter') this.onSubmit()
  }

  credentialsMissing() {
    const { email, password } = this.state;
    return !(email && password);
  }
  
  getUsernameInput() {
		return [
            <p key='1' className="card__input-label">Käyttäjätunnus</p>,
            <input
                id="license"
                key='2'
                className="card__input"
                type="text"
                placeholder=""
                value={this.state.email}
                onKeyPress={this.onKeyPress}
                onChange={({target}) => this.setState({ ...this.state, email: target.value })}
            />
		]
	}

	getPasswordInput() {
		return [
			<p key='3' className="card__input-label">Salasana</p>,
            <input
                id="password"
                key='4'
                className="card__input card__input--password"
                type="password"
                value={this.state.password}
                placeholder=""
                autoComplete="off"
                onKeyPress={this.onKeyPress}
                onChange={({target}) => this.setState({ ...this.state, password: target.value })}
            />
		]
  }
    
  getErrorLabel = () => {
		return this.props.loginFailed ? <div key='5' className="message--error">Kirjautumistiedot virheelliset. Yritä uudelleen</div> : null
	}

  render() {
    return (
        <Card
          heading="Kirjaudu sisään"
          cardPurposeStyle="card--login"
          input={[ this.getUsernameInput(), this.getPasswordInput(), this.getErrorLabel() ]}
          previousPath="/"
          loggedIn={false}
          disabled={this.credentialsMissing()}
          onSubmit={() => this.onSubmit()}
          buttontext="Kirjaudu"
          navigationBar={ <DoctorNavigationBar /> }
        />
    );
  }
}

export default connect(state => ({
  loginFailed: state.ui.loginFailed,
}), { ...actions })(LoginDoctor)
