import * as React from 'react';
import { connect } from 'react-redux';

import { actions } from '../ducks/session';
import { actions as uiActions } from '../ducks/ui';
import { availablePrescriptionLists } from '../ducks/session'

class PrescriptionListSelect extends React.Component {

    componentWillUnmount() {
        if(this.closeSelectorOnClick) {
            document.removeEventListener('click', this.closeSelectorOnClick);
        }
    }
    
    openSelectBox(e) {
        e.preventDefault();
        this.props.togglePrescriptionListSelector();
        const closeSelectorOnClick = (e) => {
            this.props.togglePrescriptionListSelector();
            document.removeEventListener('click', closeSelectorOnClick);
        }

        this.closeSelectorOnClick = closeSelectorOnClick;
        document.addEventListener('click', closeSelectorOnClick);
    }

    fetchOrders(selectMode) {
        const { selectedListMode, setSelectedListMode, fetchPharmacyOrders } = this.props;
        setSelectedListMode(selectMode);
        if(selectMode.name !== selectedListMode.name) {
            fetchPharmacyOrders();
        }
    }

    render() {
        const { selectedListMode, selectorOpen } = this.props;
        return (
            <div className="PrescriptionListSelect">
                
                { !selectorOpen 
                    ? <div className="PrescriptionListSelect--header">
                        <div className="header-wrapper" onClick={e => this.openSelectBox(e)}>
                            <h1>{selectedListMode.name}</h1>
                            <span><i className="fas fa-chevron-down header-icon"></i></span>
                        </div>
                    </div> :
                    <div className="PrescriptionListSelect__Popup">
                        <ul>
                            { 
                                availablePrescriptionLists.map((option, index) => 
                                    <ListItem key={`list-item-${index}`} option={option} 
                                        selectedListMode={selectedListMode} onSelect={mode => this.fetchOrders(mode)}/>)
                             }
                        </ul>
                    </div>
                }
            </div>
        );
    } 
}

const ListItem = (props) => {
    const { selectedListMode = {}, option = {}, onSelect } = props;
    return (
        <div className="PrescriptionListSelect--item" onClick={ e=> onSelect(option)}>
            <li><span className={`${selectedListMode.name === option.name && 'selected'}`}>{option.name}</span></li>
        </div>
    );
}

export default connect(state => ({
    selectedListMode: state.pharmacist.session.selectedListMode,
    selectorOpen: state.pharmacist.ui.prescriptionListSelectorOpen,
}), { 
    togglePrescriptionListSelector: uiActions.togglePrescriptionListSelector,
    fetchPharmacyOrders: actions.fetchPharmacyOrders,
    setSelectedListMode: actions.setSelectedListMode,
})(PrescriptionListSelect);
