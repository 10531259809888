import { actionUtils } from '../../ducks/common'
import { apiConstants } from '../../common/constants'

export const types = {
	LOGIN: Symbol('doctor/session/LOGIN'),
	LOGOUT: Symbol('doctor/session/LOGOUT'),
	RECEIVE_LOGIN: Symbol('doctor/session/RECEIVE_LOGIN'),
	PICK_NEXT_ORDER: Symbol('doctor/session/PICK_NEXT_ORDER'),
	FETCH_ORDERS: Symbol('doctor/session/FETCH_ORDERS'),
	RECEIVE_NEXT_ORDER: Symbol('doctor/session/RECEIVE_NEXT_ORDER'),
	RECEIVE_SYSTEM_STATUS: Symbol('doctor/session/RECEIVE_SYSTEM_STATUS'),
	SUBMIT_DECISION: Symbol('doctor/session/SUBMIT_DECISION'),
	SUBMIT_ORDER: Symbol('doctor/session/SUBMIT_ORDER'),
	RELEASE_ORDER: Symbol('doctor/session/RELEASE_ORDER'),
	RELEASE_ONE_ORDER: Symbol('doctor/session/RELEASE_ONE_ORDER'),

	UPDATE_PRESCRIPTION: Symbol('doctor/session/UPDATE_PRESCRIPTION'),
	FETCH_COMPLETED_ORDERS: Symbol('doctor/session/FETCH_COMPLETED_ORDERS'),
	RECEIVE_COMPLETED_ORDERS: Symbol('doctor/session/RECEIVE_COMPLETED_ORDERS'),
	VIEW_COMPLETED_ORDER: Symbol('doctor/session/VIEW_COMPLETED_ORDER'),

	SAVE_MESSAGE: Symbol('doctor/session/SAVE_MESSAGE'),
	GET_MESSAGE: Symbol('doctor/session/GET_MESSAGE'),
	RECEIVE_MESSAGE: Symbol('doctor/session/RECEIVE_MESSAGE'),
	RESET_ORDER_STATE: Symbol('doctor/session/RESET_ORDER_STATE')
}

export const ORDER_FETCH_SIZE = 30;
const DEFAULT_SORT_ORDER = 'Uusimmat ensin';


let initialState = {
	doctor: null,
	currentOrder: null,
	system: {},
	completedOrders: [],
	orderCount: 0,
	filter: '',
	sortOrder: DEFAULT_SORT_ORDER,
	offset: ORDER_FETCH_SIZE,
	delta: -1,
	selectedCompletedOrder: null,
	meta: {
		lazy_loading: false,
	},
	currentMessage: {
		status: "",
		header: "",
		text: ""
	}
}

export default (state = initialState, action) => {

	switch (action.type) {

		case types.UPDATE_PRESCRIPTION:

			const currentOrder = state.currentOrder
			const prescription = action.prescription

			const prescriptions = currentOrder.prescriptions.map(p => {
				if (p.id === prescription.id) return prescription
				else return p
			})

			return {
				...state,
				currentOrder: { ...currentOrder, prescriptions: prescriptions }
			}

		case types.RECEIVE_SYSTEM_STATUS:
			return {
				...state,
        system: action.system,
        orderQueue: action.orderQueue
			}			

		case types.RECEIVE_LOGIN:
			return {
				...state,
				doctor: action.user
			}

		case types.RECEIVE_NEXT_ORDER:
			return {
				...state,
				currentOrder: action.order
			}

		case types.RELEASE_ORDER:
			return {
				...state,
				currentOrder: null
			}

		case types.FETCH_COMPLETED_ORDERS: {
			const delta = (action.filter !== state.filter) ? -1 : state.delta;
			const orderCount = (action.sortOrder !== state.sortOrder) ? 0 : state.orderCount;
			const newOffset = (action.offset < ORDER_FETCH_SIZE || action.filter !== state.filter) ? ORDER_FETCH_SIZE : action.offset;
			return {
				...state,
				filter: action.filter,
				sortOrder: action.sortOrder,
				offset: newOffset,
				delta,
				orderCount,
				meta: {
					lazy_loading: (action.meta && action.meta.lazy_loading)
				}
			}
		}

		case types.RECEIVE_COMPLETED_ORDERS:
			const orders = action.payload.data;
			const orderCount = action.payload.count;
			const delta = state.orderCount - orderCount;
			return {
				...state,
				completedOrders: orders,
				orderCount,
				delta,
				meta: {
					lazy_loading: false,
				},
				selectedCompletedOrder: orderCount > 0 ? orders[0] : null,
			}

		case types.VIEW_COMPLETED_ORDER:
			return {
				...state,
				selectedCompletedOrder: action.selectedOrder,
			}

		case types.RELEASE_ONE_ORDER: {
			const remainingOrders = state.completedOrders.filter(order => order.orderId !== action.orderId);
			const orderCount = remainingOrders.length;
			const currentOrder = state.currentOrder !== null && state.currentOrder.orderID === action.orderId ? null : state.currentOrder;
			return {
				...state,
				completedOrders: remainingOrders,
				selectedCompletedOrder: orderCount > 0 ? remainingOrders[0] : null,
				orderCount,
				currentOrder
			}
		}

		case types.RECEIVE_MESSAGE:
			return {
				...state,
				currentMessage: action.data
			}

		case types.RESET_ORDER_STATE:
			return {
				...state,
				currentOrder: null
			}
		default:
			return state
	}
}

export const actions = {
	login: (email, password) => actionUtils.action(types.LOGIN, { email: email, password: password }),
	logout: () => actionUtils.action(types.LOGOUT),
	receiveLogin: (data) => actionUtils.action(types.RECEIVE_LOGIN, data),
	pickNextOrder: () => actionUtils.action(types.PICK_NEXT_ORDER),
	fetchOrders: () => actionUtils.action(types.FETCH_ORDERS),
	submitDecision: (id, decision, reason) => actionUtils.action(types.SUBMIT_DECISION, { id: id, decision: decision, reason: reason }),
	cancelDecision: (id) => actionUtils.action(types.SUBMIT_DECISION, { id: id, decision: apiConstants.PRESCRIPTION_DECISION_UNDECIDED, reason: '' }),
	submitOrder: (orderId) => actionUtils.action(types.SUBMIT_ORDER, { orderId: orderId }),
	releaseOrder: () => actionUtils.action(types.RELEASE_ORDER),
	releaseOneOrder: (orderId) => actionUtils.action(types.RELEASE_ONE_ORDER, { orderId: orderId }),
	fetchCompletedOrders: (filter = '', sortOrder = DEFAULT_SORT_ORDER, offset = ORDER_FETCH_SIZE) => 
		actionUtils.action(types.FETCH_COMPLETED_ORDERS, { filter, sortOrder, offset }),
	loadAdditionalOrders: (filter = '', sortOrder = DEFAULT_SORT_ORDER, offset = ORDER_FETCH_SIZE) => 
		actionUtils.action(types.FETCH_COMPLETED_ORDERS, { filter, sortOrder, offset, meta:{ lazy_loading: true } }),
	viewCompletedOrder: (order) => actionUtils.action(types.VIEW_COMPLETED_ORDER, { selectedOrder: order }),
	saveDoctorMessage: (title,text,status) => actionUtils.action(types.SAVE_MESSAGE, {title:title, text: text, status: status}),
	getMessage: () => actionUtils.action(types.GET_MESSAGE),
	resetOrderState: () => actionUtils.action(types.RESET_ORDER_STATE)
}

const processOrderQueue = (orderQueue) => {

	const expTimes = orderQueue.filter(o =>
		o.deliveryStatus === apiConstants.ORDER_STATUS.WAITING).map(o =>
			new Date(o.deliveryBeforeTime).valueOf()
		)

	const next = Math.min(...expTimes)
	return next === Infinity ? null : next
}

const countOption = (option, orders) => {
	if (!orders) return 0
	return orders.filter(o =>
			o.deliveryStatus === apiConstants.ORDER_STATUS.WAITING
		).reduce((count, order) => {
			return order.deliveryOptionId === option ? count + 1 : count
    }, 0)
  }

export const selectors = {

	getCurrentOrder: (state) => populateOrder(state.doctor.session.currentOrder, state),

	getCurrentDoctor: (state) => (state.doctor.session.doctor),
	
	getSystemStatus: (state) => {

    const queue = state.doctor.session.system.queue;
    const nextExpiring = processOrderQueue(state.doctor.session.orderQueue || [])
		if (!queue) return queue

		return {
      nextOrderExpiring: nextExpiring,
			waitingForDoctor: queue[apiConstants.ORDER_STATUS.WAITING] || 0,
			processing: queue.processing || 0
		}
	},
	getSelectedCompletedOrder: (state) => populateOrder(state.doctor.session.selectedCompletedOrder, state),

  getCurrentMessage: (state) => (state.doctor.session.currentMessage),
  
  getQueuePerDeliveryOption: (state) => {
		const queue = state.doctor.session.orderQueue
		return {
			count20min: countOption(apiConstants.DELIVERY_OPTIONS.OPTION_20M, queue),
			count12h: countOption(apiConstants.DELIVERY_OPTIONS.OPTION_12H, queue)
    }
  }
}

const populateOrder = (order, state) => {
	if (!order || state.product.products.length < 1) return order
	const prescriptions = order.prescriptions.map(p => populatePrescription(p, state.product.products));
	return { ...order, prescriptions: prescriptions }
}

const populatePrescription = (prescription, products) => {

	if (prescription.type === 'prescription') {
		return { ...prescription, chat: JSON.parse(prescription.patientChat) }
	}

	const pr = products.find(pr => pr.productId === prescription.productId)
	return { ...prescription, displayName: pr.displayName, format: pr.format }
};