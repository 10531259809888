import React, { Component } from 'react'
import { connect } from 'react-redux';
import ProfileHeader from './ProfileHeader'

import { STRONG_PASS } from '../common/constants'

class ChangePassword extends Component {

  initialState = {
    password1: {
      password: '',
      error: null,
      valid: false
    },
    password2: {
      password: '',
      error: null,
      valid: false
    },
    submitted: false,
  }

  constructor(props) {
    super(props)
    this.state = this.initialState;
    this.passwordChangedTimer = null;
    this.resetStateOnSuccess = this.resetStateOnSuccess.bind(this);
    this.resetStateOnFailure = this.resetStateOnFailure.bind(this);
  }

  componentWillUnmount() {
    if(this.passwordChangedTimer) {
      clearTimeout(this.passwordChangedTimer);
    }
  }

  password1Valid = () => {

    if (!STRONG_PASS.test(this.state.password1.password)) {
      
      this.setState({
        password1: {
          ...this.state.password1,
          error: 'Salasanan muoto ei kelpaa',
          valid: false
        }
      })

      return false      
    }

    if (this.state.password1.password.length < 8) {

      this.setState({
        password1: {
          ...this.state.password1,
          error: 'Salasanan pitää olla vähintään kahdeksan merkkiä pitkä.',
          valid: false
        }
      })

      return false
    }

    this.setState({
      password1: {
        ...this.state.password1,
        error: null,
        valid: true
      }
    })

    return true
  }

  password2Valid = () => {

    if (this.state.password1.password !== this.state.password2.password) {
      
      this.setState({
        password2: {
          ...this.state.password2,
          error: 'Salasanat eivät vastaa toisiaan',
          valid: false
        }
      })

      return false            
    }

    this.setState({
      password2: {
        ...this.state.password2,
        error: null,
        valid: true
      }
    })

    return true    
  }

  updatePass1 = (password) => {
    this.setState({
      password1: {
        ...this.state.password1,
        password: password
      }
    })
  }

  updatePass2 = (password) => {

    const valid = this.state.password1.password === password

    this.setState({
      password2: {
        ...this.state.password2,
        password: password,
        valid: valid,
        error: valid ? null : this.state.password2.error
      }
    })
  }

  ready2Submit = () => {
    return this.state.password1.valid && this.state.password2.valid
  }

  onKeyPress = (e) => {
    if (e.key === 'Enter' && this.ready2Submit()) this.submit()
  }

  submit = () => {
      this.props.setPassword(this.state.password1.password);
      this.setState({ submitted: true });
  }

  fieldClasses = (field, initial) => {

    const state = this.state[field]

    if (state.valid) {
      return initial + ' change_ok'
    }
    if (state.error) {
      return initial + ' error_in_password'
    }

    return initial
  }

  resetStateOnSuccess() {
    this.setState(Object.assign({}, this.initialState, { submitted: false }));
  }

  resetStateOnFailure() {
    this.setState({ submitted: false });
  }

  render() {
    const { password_change_success, password_change_failed } = this.props;

    let buttonText = 'Tallenna';
    if(password_change_success && this.state.submitted) {
      buttonText = 'Tallennettu!';
      this.passwordChangedTimer = setTimeout(this.resetStateOnSuccess, 2000);
    }
    if(password_change_failed && this.state.submitted) {
      buttonText = 'Tallennus epäonnistui!';
      this.passwordChangedTimer = setTimeout(this.resetStateOnFailure, 2000);
    }

    return (
      <div className="password__change-container">

        <ProfileHeader 
          title="Vaihda salasana"
        />

        <div className="form-container">
          <p>Luo vähintään 8 merkkiä pitkä salasana, joka sisältää vähintään yhden ison kirjaimen, yhden pienen kirjaimen ja yhden numeron.</p>
          <div className="password__change">
            <div className={this.fieldClasses('password1', 'password__change-new')}>
              <label>Uusi salasana</label>
              <input
                  className="input__password input__password--new"
                  type="password"
                  placeholder=""
                  onBlur={this.password1Valid}
                  onKeyPress={this.onKeyPress}
                  value = {this.state.password1.password}
                  onChange={ e => this.updatePass1(e.target.value) }
                />
              { this.state.password1.error ? <div className="password_error">{this.state.password1.error}</div> : null }
            </div>

            <div className={this.fieldClasses('password2', 'password__change-again')}>
              <label>Vahvista uusi salasana</label>
              <input
                className="input__password input__password--again"
                type="password"
                placeholder=""
                onBlur={this.password2Valid}
                onKeyPress={this.onKeyPress}
                value = {this.state.password2.password}
                onChange={ e => this.updatePass2(e.target.value) }
              />
              { this.state.password2.error ? <div className="password_error">{this.state.password2.error}</div> : null }
            </div>
            <button
              ref={ (ele) => this.submitButton = ele}
              onClick={this.submit}
              className="btn btn--green"
              type="button"
              disabled={!this.ready2Submit()}>
                { !this.state.submitted ? 'Tallenna' : buttonText }
              </button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  password_change_success: state.profile.password_change_success,
  password_change_failed: state.profile.password_change_failed,
})

export default connect(mapStateToProps, {})(ChangePassword);
