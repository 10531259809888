import * as React from 'react';

import { connect } from 'react-redux';

import { actions } from '../ducks/session';
import { selectors } from '../ducks/session'
import { DoctorNavigationBar } from '../../common/NavigationBar';
import { SessionOverlay } from '../common/SessionOverlay';
import OrderContainer from '../order/OrderContainer'
import CompletedOrdersList from '../history/CompletedOrdersList';
import SearchBox from '../history/SearchBox';
import SortBox from '../history/SortBox';

class OrderHistory extends React.Component {

    componentDidMount() {
       this.props.fetchCompletedOrders();
    }

    render() {
        return (
            <div id="historyContainer" className="app-container doctor">

                {
                this.props.sessionExpires ?
                <SessionOverlay continueSession={this.props.continueSession} /> :
                ''
                }

                <DoctorNavigationBar
                    loggedIn={true}
                    logout={this.props.logout}
                    firstName={this.props.currentDoctor.firstName}
                    lastName={this.props.currentDoctor.lastName}
                    navMessage={this.props.currentMessage}
                />

                <div className="content">
                    <CompletedOrdersContainer orders={this.props.orders}/>
                     { this.props.selectedOrder && 
                        <OrderContainer
                            currentOrder={this.props.selectedOrder}
                            releaseOneOrder={this.props.releaseOneOrder}
                            resetOrderState={this.props.resetOrderState}
                            readOnly={true}
                            fetching={this.props.fetching}
                            scope="history"
                        />
                    }
                </div>    
            </div>
        );
    }
}

const CompletedOrdersContainer = (props) => {
    return (
        <div className="order-list-container">
            <SearchBox />
            <div className="order-list-header">
                <h1>Historia</h1>
                <SortBox />
            </div>
            <CompletedOrdersList /> 
        </div>
    );
}

const mapStateToProps = (state) => ({
    currentDoctor: selectors.getCurrentDoctor(state),
    orders: state.doctor.session.completedOrders,
    selectedOrder: selectors.getSelectedCompletedOrder(state),
    sessionExpires: state.ui.sessionExpires,
    fetching: state.ui.fetching,
    currentMessage: selectors.getCurrentMessage(state)
})

export default connect(mapStateToProps, { ...actions })(OrderHistory);