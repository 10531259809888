import React, { Component } from 'react'

import { connect } from 'react-redux'
import { selectors } from '../ducks/session'
import { actions } from '../ducks/session'

import { SelectOfficeButton } from '../common/SelectOfficeButton'
import { PharmacyNavigation } from '../../common/NavigationBar';

class PharmacySelect extends Component {

	constructor(props) {
		super(props)
		this.state = {
			
		}
    }
    
	selectPharma = (id) => {
        console.log("select", id )
		this.props.setOffice(id)
    }
    
    render() {

        const { currentOffices } = this.props
        console.log(currentOffices.pharmacyOffices)
        
        return (
            <div className="pharmacy">

            <PharmacyNavigation />

            <div className="pharmacy_login">
            
            
                <h1>Valitse toimipaikkasi</h1>
                
                <div className="form-container">
                
                { 
                    
                     currentOffices.pharmacyOffices.map((office, index) => (
                        <SelectOfficeButton 
                            key={index}
                            id={office.id}
                            label={office.name}
                            level={office.level}
                            onSubmit={ () => this.selectPharma(office.id) }
                        />
                    )) 
                }

          
                </div>
           
            </div>
            </div>

	    )
    }
    
}


export default connect(
    state => ({
        currentOffices: selectors.getCurrentOffices(state)
       // currentPharmacist: selectors.getCurrentPharmacist(state) || { firstName: 'Apteekki', lastName: 'Ei Sessio'},
    }), 
    { 
        ...actions,
    }
)(PharmacySelect)
/*
export default connect(
    state => {
      const { email, phone } = selectors.getUser(state)
      return {
        email: email,
        phone: phone
      }
    },
    {
      ...actions
    }
  )
  */