import React, { Component } from 'react'


class LandingPage extends Component {
  
  render() {
    
    return (
      <div className="app">
      
        
        
        
      

      
    </div>

    )
  }
}

export default LandingPage
