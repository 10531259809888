import React, { Component } from 'react'
import { connect } from 'react-redux'

import { RegisterHello, RegisterEmail, RegisterPhone, RegisterPassword, RegisterTOS } from './'
import { actions } from '../ducks/registration'

import ProgressBar from './ProgressBar'


class RegistrationContainer extends Component {

	constructor(props) {

		super(props)

		this.state = {
			views: [
			this.renderHello,
			this.renderEmail,
			this.renderPhone,
			this.renderPassword,
			this.renderTOS
			],
			progress: [ 'one', 'two', 'three', 'four', 'five' ],
			currentView: 0
		}

	}

	componentWillMount() {
		this.props.fetchRegistrationData()
	}

	renderHello = () => {
		return (
			<RegisterHello
			progress={this.getProgress()}
			email={this.props.email}
			startRegistration={this.submit.bind(this, () => {})}
			/>
			)
	}

	renderEmail = () => {
		return (
			<RegisterEmail
			progress={this.getProgress()}
			email={this.props.email}
			setEmail={this.submit.bind(this, this.props.setEmail)}
			/>
			)
	}

	renderPhone = () => {
		return (
			<RegisterPhone
			progress={this.getProgress()}
			phone={this.props.phone}
			setPhone={this.submit.bind(this, this.props.setPhone)}
			/>
			)
	}

	renderPassword = () => {
		return (
			<RegisterPassword
			progress={this.getProgress()}
			password={this.props.password}
			setPassword={this.submit.bind(this, this.props.setPassword)}
			/>
			)
	}

	renderTOS = () => {
		return (
			<RegisterTOS
			progress={this.getProgress()}
			marketingAllowed={this.props.marketingAllowed}
			eulaAccepted={this.props.eulaAccepted}
			createUser={this.submit.bind(this, this.props.createUser)}
			/>
			)
	}

	submit = (func, ...args) => {
		func.apply(this, args)
		if (this.state.currentView < this.state.views.length - 1)
			this.setState({ currentView: this.state.currentView + 1 })
	}

	currentView() {
		return (this.state.views[this.state.currentView]())
	}

	getProgress() {
		return (<ProgressBar fill={this.state.progress[this.state.currentView]} />)
	}

	render() {
		return this.currentView()
	}
}

export default connect(
	state => {
		return {
			firstName: state.registration.firstName,
			email: state.registration.email,
			phone: state.registration.phone,
			password: state.registration.password,
			marketingAllowed: state.registration.marketingAllowed,
			eulaAccepted: state.registration.eulaAccepted

		}
	},
	{
		...actions
	}
	)(RegistrationContainer)