import React, { Component } from 'react'

export class SelectOfficeButton extends Component {


	render() {
		return (
          
            <button onClick={this.props.onSubmit} className="card__btn card__btn--next" type="button">{this.props.label}</button>
        
			)
	}
}

export default SelectOfficeButton