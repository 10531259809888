export const chat = {
	1: {
		question: 'Oletko ollut aikaisemmin tutkimuksissa tai hoidoissa erektiohairiön vuoksi?',
		type: 'oneOf',
		answers: [{
			text: 'Kyllä',
			next: 2
		}, {
			text: 'Ei',
			next: 6
		}]
	},
	2: {
		question: 'Onko terveydentilassasi ollut muutoksia sen jälkeen? Onko sinulla ollut esimerkiksi sydämeen tai verenkiertoon liittyviä oireita?',
		type: 'oneOf',
		answers: [{
			text: 'Kyllä',
			next: 4
		}, {
			text: 'Ei',
			next: 3
		}]
	},
	3: {
		question: 'Käytätkö nitraattilääkitystä eli niin sanottuja “nitroja”? (Esimerkkejä lääkenimistä: Deponit, Denit, Imdur, Isangina, Ismox, Ismoxin, Isosorbide, Nitro, Nitroglyserin, Nitrolingial Nitrosid, Ormox, Perlinganit tai Transderm nitro)',
		type: 'oneOf',
		answers: [{
			text: 'Kyllä',
			next: 4
		}, {
			text: 'Ei',
			next: 5
		}]
	},
	4: {
		question: 'Kiitos vastauksistasi. Ilmoittamasi tietojen perusteella emme voi määrätä sinulle tätä lääkitystä Reseptorin kautta. Otathan yhteyttä omaan lääkäriisi terveydentilasi jatkotutkimuksia varten.',
		answers: null,
		canOrder: false
	},
	5: {
		question: 'Kiitos vastauksistasi. Ilmoittamasi tietojen perusteella reseptipyyntösi voidaan välittää eteenpäin Reseptorin lääkärille, joka tekee lopullisen päätöksen reseptin myöntämisestä.',
		answers: null,
		canOrder: true,
		discardOnSave: true,
	},
	6: {
		question: 'Ovatko erektiohäiriön oireet alkaneet vähitellen?',
		type: 'oneOf',
		answers: [{
			text: 'Kyllä',
			next: 7
		}, {
			text: 'Ei',
			next: 10
		}]
	},
	7: {
		question: 'Esiintyykö oireita jatkuvasti?',
		type: 'oneOf',
		answers: [{
			text: 'Kyllä',
			next: 8
		}, {
			text: 'Ei',
			next: 8
		}]
	},
	8: {
		question: 'Onko sinulla aamuerektioita?',
		type: 'oneOf',
		answers: [{
			text: 'Kyllä',
			next: 10
		}, {
			text: 'Ei',
			next: 9
		}]
	},
	9: {
		question: 'Esiintyykö oiretta masturboidessa?',
		type: 'oneOf',
		answers: [{
			text: 'Kyllä',
			next: 11
		}, {
			text: 'Ei',
			next: 11
		}]
	},
	10: {
		question: 'Tuntuuko elämäntilanteesi stressaavammalta kuin yleensä?',
		type: 'oneOf',
		answers: [{
			text: 'Kyllä',
			next: 11
		}, {
			text: 'Ei',
			next: 11
		}]
	},
	11: {
		question: 'Onko sinulla todettu tai tiedossa jokin tai useampi seuraavista yleissairauksista: diabetes, verenpainetauti, lihavuus, dyslipidemiat, sydän- ja verisuonitaudit?',
		type: 'oneOf',
		answers: [{
			text: 'Kyllä',
			next: 13
		}, {
			text: 'Ei',
			next: 12
		}]
	},
	12: {
		question: 'Onko terveydentilassasi tapahtunut muutoksia viime aikoina? Onko sinulla esimerkiksi sydänoireita, rasituskipua tai uusi lääkitys?',
		type: 'oneOf',
		answers: [{
			text: 'Kyllä',
			next: 4
		}, {
			text: 'Ei',
			next: 3
		}]
	},
	13: {
		question: 'Mitä yleissairauksia sinulla on todettu tai tiedossa?',
		type: 'multiChoice',
		answers: [{
			text: 'Diabetes',
			value: 'diabetes'
		},{
			text: 'Verenpainetauti',
			value: 'verenpainetauti'
		},{
			text: 'Lihavuus',
			value: 'lihavuus'
		},{
			text: 'Korkea kolesteroli',
			value: 'korkea kolesteroli'
		},{
			text: 'Sydän- ja verisuonitauti',
			value: 'sydän- ja verisuonitauti'
		}],
		next: 14
	},
	14: {
		question: 'Ovatko sairautesi hyvässä hoidossa ja tasapainossa?',
		type: 'oneOf',
		answers: [{
			text: 'Kyllä',
			next: 3
		}, {
			text: 'Ei',
			next: 4
		}]
	}
}
	