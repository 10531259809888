import React, { Component } from 'react'
import Card from '../common/Card'

class RegisterEmail extends Component {

  constructor(props) {
    super(props)
    this.state = {
      email: props.email
    }
  }

  update(email) {
    this.setState({
      email: email
    })
  }

  valid() {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)
  }  

  getInput() {
    return (
      <input
        className="card__input card__input--email"
        type="email"
        placeholder="esim. &quot;nimi.sukunimi@email.com&quot;"
        value={this.state.email}
        onChange={e => this.update(e.target.value)}
      />)
  }
  

  render() {
    return (
      <Card
        progress={this.props.progress}
        heading="Sähköposti"
        subheading="Kertoisitko meille sähköpostisi?"
        description="Sen avulla voit myöhemmin kirjautua käyttäjätilillesi"
        inputLabel="Sähköposti"
        input={this.getInput()}
        previousPath="/"
        onSubmit={() => this.props.setEmail(this.state.email)}
        disabled={!this.valid()}
      />
    )
  }
}

export default RegisterEmail
