import React, { Component } from 'react'
import Card from '../common/Card'

import { FINNISH_PHONE_NUMBER_AFTER_COUNTRY_CODE_FORMAT } from '../common/constants';

export default class RegisterPhone extends Component {

  constructor(props) {
    super(props)
    this.state = {
      phone: ''
    }
  }

  update(phone) {
    this.setState({
      phone: phone
    })
  }

  valid() {
    return FINNISH_PHONE_NUMBER_AFTER_COUNTRY_CODE_FORMAT.test(this.state.phone);
  } 

  getInput() {
    return (
      <label className="card__label--phone">+358 
        <input
          className="card__input card__input--phone"
          type="mobile"
          placeholder='esim. 40XXXXXX'
          value={this.state.phone}
          onChange={e => this.update(e.target.value)}
          />
      </label>)
  }

  render() {
    return (
      <Card
        progress={this.props.progress}
        heading="Puhelinnumero"
        subheading="Lisätään vielä puhelinnumerosi"
        description="Sen avulla saamme tavoitettua sinut tarvittaessa nopeasti"
        inputLabel="Puhelinnumero"
        input={this.getInput()}
        onSubmit={() => this.props.setPhone(this.state.phone)}
        disabled={!this.valid()}
      />
    )
  }
}
