import React, { Component } from 'react'
import PrescriptionCard from './PrescriptionCard.js'

export class ListCart extends Component {
  
  render() {
    return (

      <div className="list-cart">
      
        <div className="list-header-container">
          <h1>Valitut reseptit</h1>
          <p className="list-desc">Siirry kassalle viimeistelläksesi tilauksesi</p>
        </div>

        {
          this.props.cart.items.map((p, i) => {
            return (<PrescriptionCard
              key={i}
              prescription={p}
              onChange={({target}) => this.props.removeItem(target.id)}
            />)
          })
        }

        <div className="list-background"></div>

      </div>
      )    
  }
}

export class ListWaiting extends Component {

  render() {
    return (
      <div className="list-waiting">
        
        <div className="list-header-container">
          <h1>Odottaa käsittelyä</h1>
          <p className="list-desc">Lähetämme ilmoituksen tekstiviestillä, kun tilauksesi on käsitelty.</p>
        </div>
        
        <ListPrescriptions prescriptions={this.props.prescriptions} />
        
        <div className="list-background"></div>
      </div>
      )    

  }
  
}

export class ListRecentlyDecided extends Component {
  render() {
    return (
      <div className="list-waiting">
        <div className="list-header-container">
          <h1>Tilaus käsitelty</h1>
          <p className="list-desc">
            Hyväksyttyjen reseptien lääkkeet ovat noudettavissa sähköisellä reseptillä apteekista. 
            <a href="http://www.kanta.fi/resepti-esittely">Lue lisää</a> sähköisistä resepteistä.
            <br/><br/>
            Muistathan, että useisiin sairauksiin ja niiden lääkityksiin kuuluu olennaisena osana kontrollit ja seuranta. 
            Noudata aina oman lääkärisi määräämiä hoito-, kontrolli- ja seurantaohjeita.
          </p>
        </div>
        <ListPrescriptions
          prescriptions={this.props.prescriptions}
          select={this.props.select}
          isRecent={true}
        />
         <div className="list-background"></div>
      </div>
      )        
  }
}

export class ListDecided extends Component {
  render() {
    return (
      <div className="list-history">
        <div className="list-header-container">
          <h1>Vanhat reseptit</h1>
        </div>
        <ListPrescriptions
          prescriptions={this.props.prescriptions}
          select={this.props.select}
        />
      </div>
    )    
  }
}

class ListPrescriptions extends Component {

  select = ({target}) => {
    this.props.select(parseInt(target.id, 10), target.checked)
  }

  render() {

    return (
      <div>
            {
              this.props.prescriptions.map((p, ind) => {

                return (
                  <PrescriptionCard
                    key={ind}
                    id={p.productId}
                    prescription={p}
                    onChange={this.select}
                    isRecent={this.props.isRecent}
                  />
                )
              })
            }
      </div>
    )
  }
}

// export default ListPrescriptions
