import React, { Component } from 'react'
import Switch from 'react-switch'
import TextareaAutosize from 'react-autosize-textarea'

export class AdminMessaging extends Component {
    constructor() {
        super();
        this.state = { 
            title: '',
            text: '',
            status: '',
            checked: false,
        };

        this.handleChange = this.handleChange.bind(this);

    }
    componentDidMount() {
        this.setState({
            title: this.props.message.header,
            text: this.props.message.additionalDetails,
            status: this.props.message.status,
            checked: this.props.message.status === 'published' ? true : false
        })
    }

    handleChange(checked) {
        this.setState({ checked });

        if(checked) {
            this.props.saveDoctorMessage(this.state.title,this.state.text,"published")
        } else {
            this.props.saveDoctorMessage(this.state.title,this.state.text,"unpublished")
        }
    }

  render() {

    return (

        <div className="messaging">
            <div className="message-wrapper">
                <h2>Viesti kaikille apteekeille</h2>
                
                    {this.state.checked ?
                        <div className="published-message-container">
                            <h6>{this.state.title}</h6>
                            <p>{this.state.text}</p>
                        </div>
                    :
                        <div className="form-container">
                            <label>Otsikko</label>
                            <input 
                                value={this.state.title}
                                type="text"
                                onChange={e => this.setState({ title: e.target.value })}
                            />
                            <label>Lisätiedot</label>
                            <TextareaAutosize
                                style={{ width: "100%" }}
                                value={this.state.text}
                                onChange={e => this.setState({ text: e.target.value })}
                             />
                        </div>
                    }
            </div>
            <div className="switch-wrapper">
                <span>Julkaise</span>
                <label htmlFor="switch">
                <Switch
                    onChange={this.handleChange}
                    checked={this.state.checked}
                    onColor='#05B4AF'
                    offColor='#DDDDDD'
                    uncheckedIcon={false}
                    checkedIcon={false}
                    id="switch"
                />
                </label>
            </div>
        </div>
        
    )
  }
}
export default AdminMessaging;
