import React, { Component } from 'react'
import PropTypes from 'prop-types';
import RadioButton from '../common/RadioButton';

class SelectOne extends Component {

	constructor(props) {
		super(props)
		this.state = {
			selected: null
		}
	}

	toggleSelected = (value) => {
    const { onChange } = this.props;
		this.setState({ selected: value }, () => onChange(value));
	}

	render() {
    const { selected } = this.state;
    const { options } = this.props;
		return (
			<div className="chat_radio_container">
        {
          options.map((a, ind) => {
            return (
              <div
                className="chat_radio_container_option"
                key={`${a.value} ${ind}`}
              >
                <RadioButton
                  checked={selected === a.value}
                  onChange={(e) => this.toggleSelected(a.value)}
                  label={a.text}
                />
              </div>
            );
          })
        }
			</div>
			)
	}
}

SelectOne.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    }))
}

export default SelectOne;
