import React, { Component } from 'react'
import { connect } from 'react-redux'
import Countdown from 'react-countdown-now'

import { selectors } from '../ducks/session'

class QueueSwitchPanel extends Component {
  constructor() {
      super();
      this.state = { 
          overtime: false
      };
  }

  asTwoDigitValue(value) {
    return value < 10 ? `0${value}` : value;
  }
  
  countDownRenderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <p className='time-remaining overtime'>
          {this.asTwoDigitValue(hours)}:{this.asTwoDigitValue(minutes)}:{this.asTwoDigitValue(seconds)}
        </p>
      );
    } else {
      return (
        <p className='time-remaining'>
          {this.asTwoDigitValue(hours)}:{this.asTwoDigitValue(minutes)}:{this.asTwoDigitValue(seconds)}
        </p>
      )
    }
  }

  render() {
    const orderExpires = this.props.nextOrderExpiring
    const { count20min, count12h } = this.props.countPerDeliveryOption
    return (
        <div className="queue-switch-panel">
        {
          orderExpires ? [
            <h1 key="orderExpires-header" className="queue-switch-panel-title">Seuraava tilaus vanhenee:</h1>,
            <Countdown key="orderExpires-countdown" date={orderExpires} daysInHours={true} renderer={this.countDownRenderer} />
          ]
          :
          <h1 className="queue-switch-panel-title">Ei tilauksia jonossa</h1>
        }
        <table className="switch-table"> 
          <tbody>
            <tr className="switch-20min-on"> 
              <td className="switch-table-cell time">20 min</td>
              <td className="switch-table-cell amount"><span>{count20min}</span> kpl</td>
            </tr>

            <tr className="switch-12h-on"> 
              <td className="switch-table-cell time">12 h</td>
              <td className="switch-table-cell amount"><span>{count12h}</span> kpl</td>
            </tr>
          </tbody>
        </table>

      </div>
        
    )
  }
}

export default connect(
  state => {
    return {
      countPerDeliveryOption: selectors.getQueuePerDeliveryOption(state)
    }
  },
  null
  )(QueueSwitchPanel)
