import { all, fork } from 'redux-saga/effects'

import { tupasListener, createUserListener } from './registration'

import {
    loginListener,
    logoutListener,
    addItem2CartListener,
    removeItemFromCartListener,
    addPrescription2CartListener,
    payCartListener,
    fetchOrderHistoryListener,
    giveFeedbackListener,
    fetchSessionData,
    applyCouponListener,
    unApplyCouponListener,
    fetchProductDataListener,
} from './session'

import {
    loginListener as doctorLoginListener,
    logoutListener as doctorLogoutListener,
    fetchOrderListener,
    submitDecisionListener,
    submitOrderListener,
    releaseOrderListener,
    releaseOneOrderListener,
    pickOrderListener,
    fetchCompletedOrdersListener,
    saveDoctorMessageListener,
    getDoctorMessageListener
} from '../doctor/sagas/session'

import {
  loginListener as pharmacistLoginListener,
  logoutListener as pharmacistLogoutListener,
  createPharmacyUserListener,
  updatePharmacyUserListener,
  deletePharmacyUserListener,
  fetchPharmacistOfficesListener,
  setOfficeListener,
  fetchPharmacyUsersListener,
  savePatientInfoListener,
  addItem2PharmacyCartListener,
  setDeliveryOptionListener,
  sendCartListener,
  fetchPharmacyOrderHistoryListener,
  deletePharmacyCartListener,
  removeItemFromListListener,
  checkUserNameListener,
  getMessageListener,
  setMessageCheckedListener,
  setMessageUncheckedListener,
  currentMessageStatusListener

} from '../pharmacy/sagas/session'

import { updatePersonalDataListener, setPasswordListener } from './profile'

import { stripeSessionListener } from './stripe'

export default function* root() {
  yield all([
    fork(tupasListener),
    fork(createUserListener),
    fork(loginListener),
    fork(logoutListener),
    fork(updatePersonalDataListener),
    fork(setPasswordListener),
    fork(removeItemFromCartListener),
    fork(addItem2CartListener),
    fork(addPrescription2CartListener),
    fork(fetchOrderHistoryListener),
    fork(payCartListener),
    fork(giveFeedbackListener),
    fork(fetchSessionData),
    fork(applyCouponListener),
    fork(unApplyCouponListener),
    
    fork(doctorLoginListener),
    fork(doctorLogoutListener),
    fork(fetchOrderListener),
    fork(submitDecisionListener),
    fork(submitOrderListener),
    fork(releaseOrderListener),
    fork(releaseOneOrderListener),
    fork(pickOrderListener),
    fork(saveDoctorMessageListener),
    fork(getDoctorMessageListener),

    fork(pharmacistLoginListener),
    fork(pharmacistLogoutListener),
    fork(fetchPharmacistOfficesListener),
    fork(setOfficeListener),
    fork(createPharmacyUserListener),
    fork(deletePharmacyUserListener),
    fork(updatePharmacyUserListener),
    fork(fetchPharmacyUsersListener),
    fork(savePatientInfoListener),
    fork(addItem2PharmacyCartListener),
    fork(setDeliveryOptionListener),
    fork(sendCartListener),
    fork(deletePharmacyCartListener),
    fork(fetchPharmacyOrderHistoryListener),
    fork(fetchCompletedOrdersListener),
    fork(removeItemFromListListener),
    fork(fetchProductDataListener),
    fork(checkUserNameListener),
    fork(getMessageListener),
    fork(setMessageCheckedListener),
    fork(setMessageUncheckedListener),
    fork(currentMessageStatusListener),

    fork(stripeSessionListener),
  ])
}