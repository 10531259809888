import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Logo from './Logo'
import Footer from './Footer'

import { tupasUrl, tagManager } from '../common/constants'
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor'
import CookieConsent from "react-cookie-consent";

import 'react-responsive-modal/lib/react-responsive-modal.css'
import Modal from 'react-responsive-modal/lib/css'
import StickyHandling from './StickyHandling'

import TosText from "../common/TosText"
import PrivacyPolicyText from '../common/TietosuojaSelosteText';
import CookiesText from '../common/CookiesText';

import CreateAccountSection from './CreateAccountSection';

class FaqPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpenTos: false,
      modalOpenPrivacy: false,
      modalOpenCookies: false
    }
  }
  componentWillMount() {
    configureAnchors({offset: -60, scrollDuration: 500})
  }
  
  componentDidMount() {
      if(!window.location.hash) {
        window.scrollTo(0,0);
      }
  }
  onOpenTosModal = () => {
    this.setState({ modalOpenTos: true });
  };

  onCloseTosModal = () => {
    this.setState({ modalOpenTos: false });
  };

  onOpenPrivacyModal = () => {
    this.setState({ modalOpenPrivacy: true });
  };

  onClosePrivacyModal = () => {
    this.setState({ modalOpenPrivacy: false });
  };
  
  onOpenCookiesModal = () => {
    this.setState({ modalOpenCookies: true });
  };

  onCloseCookiesModal = () => {
    this.setState({ modalOpenCookies: false });
  };
  render() {
    const { modalOpenTos, modalOpenPrivacy, modalOpenCookies } = this.state;
    return (
      <div className="landing faq-view">
        
       
        <StickyHandling
          loginPath="/login" 
        />
          
        
        <ScrollableAnchor id={'top'}>
        <section className="grandient-bg full-width">
          <div className="faq-navigation">

            <h1>Mistä haluaisit tietää lisää?</h1>

            <ul>
                <li><a href="#vanhan-reseptien-uusinta">Vanhan reseptin uusinta</a></li>
                <li><a href="#kokonaan-uusi-resepti">Kokonaan uusi resepti</a></li>
                <li><a href="#tilauksen-kasittely">Tilauksen käsittely</a></li>
                <li><a href="#hinnasto">Hinnasto</a></li>
                <li><a href="#tilin-luonti-ja-kirjautuminen">Tilin luonti ja kirjautuminen</a></li>
                <li><a href="#maksutavat">Maksutavat</a></li>
                <li><a href="#tietoa-meista">Tietoa meistä</a></li>
            </ul>

          </div>
            
        </section>
        </ScrollableAnchor>


        <div className="faq-content-container">

          <ScrollableAnchor id={'vanhan-reseptien-uusinta'}>
            <section>
                <h2>Vanhan reseptin uusinta</h2>
                <h3>Mitä reseptejä voin uusia Reseptorin kautta?</h3>
                <p>
                  Reseptorin kautta voit uusia lääkereseptisi riippumatta siitä kuka alkuperäisen reseptin on kirjoittanut. 
                  Voit siis uusia sekä yksityisellä että julkisessa terveydenhuollossa määrätyt reseptit samassa palvelussa.</p>
                
                
                { /*
                  <p>Tarkista kuuluuko lääkkeesi uusittavien lääkkeiden listalle:</p>

                <InputAutoComplete
                  //products={this.props.products}
                  //addProduct={(product) => c.select(product)}
                  placeholder='Etsi lääkettä'
                />
                <p>Löysitkö etsimäsi lääkkeen? Ota palvelu käyttöön uusiaksesi reseptisi nyt.</p>
                */ }

                <p>Mikäli et ole aiemmin käyttänyt Reseptoria, voit <button onClick={() => window.location.replace(tupasUrl())}>luoda tilin</button> helpon ja nopean rekisteröitymisen kautta.</p>
                <p><a href="/login">Kirjaudu sisään</a>, mikäli sinulla on jo tili Reseptorissa.</p>

                <h3>Mitä reseptejä en voin uusia?</h3>
                <p>Emme uusi huumaavia tai pääasiassa keskushermostoon vaikuttavia (PKV) lääkereseptejä.</p>
                <p>Lääkärimme eivät myöskään voi uusi reseptejä, jotka ovat kausiluonteisia (esim. antibiootit), tai joiden määrääminen edellyttää potilaan tutkimista tai mikäli meillä ei ole saatavissa riittävästi tietoa potilaan hoidosta.</p>
                <p>Emme myöskään voi uusia reseptiä, mikäli reseptille on tehty uusimiskielto, mitätöinti, aiempi hylkäys, tai sillä on vireillä toinen uusimispyyntö, se on annosjakelussa, kyseessä on erityislupavalmiste tai alkuperäinen resepti on apteekin puhelinresepti.</p>
                
                <h3>Kuinka vanhoja reseptejä voin uusia?</h3>
                <p>
                  Reseptorin kautta voit uusia myös jo vanhentuneita lääkereseptejä, kunhan alkuperäinen resepti on ollut 
                  sähköinen resepti eli e-resepti.
                </p>



            </section>
          </ScrollableAnchor>

      

          <CreateAccountSection 
            heading = "Uusi resepti helposti nyt"
          />
          


          <ScrollableAnchor id={'kokonaan-uusi-resepti'}>
            <section>
                <h2>Kokonaan uusi resepti</h2>
                <h3>Mitä tarkoitetaan uudella reseptillä?</h3>
                <p>Reseptorin kautta voit hakea reseptiä oireeseen, johon et ole aiemmin saanut lääkereseptiä. Voit siis saada täysin uuden reseptin samalla tavalla kun saisit sen lääkärin vastaanotolla. </p>
            
                <h3>Mihin oireisiin voin hakea uutta reseptiä?</h3>
                <p>Tällä hetkellä lääkärimme voivat myöntää uusia reseptejä seuraaviin oireisiin:</p>
                <ul>
                  <li>Virtsatieinfektio</li>
                  <li>Erektiohäiriö</li>
                  <li>Allergia (mm. siitepölyallergia)</li>
                </ul>
                
                <h3>Miten reseptin haku tapahtuu?</h3>
                <p>Uusien reseptien haku on tehty hyvin helpoksi: vastaat vain muutamiin taustatietoja ja oireitasi kartoittaviin kysymyksiin. Lääkärimme määräävät vastauksiisi perustuen oireisiin parhaiten sopivat lääkkeet.</p>


            </section>
          </ScrollableAnchor>

          <div className="testimonial-wrapper">
            <div className="testimonial">

              <div className="testimonial-content-container text-only">

              <div className="testimonial-content-text">
                <p>”Reseptin uusiminen oli erittäin helppoa ja käyttöystävällistä! Vastausvaihtoehdot selkeyttivät prosessia.”</p>
                <span>Eveliina, Helsinki</span>
              </div>

              </div>
            </div>
          </div>
{ /*  
          <div className="testimonial-wrapper">
            <div className="testimonial">

              <div className="testimonial-content-container">
                <div className="testimonial-content-image">
                kuva
                </div>
                <div className="testimonial-content-text">
                  <p>”Reseptin uusiminen oli erittäin helppoa ja käyttöystävällistä! Vastausvaihtoehdot selkeyttivät prosessia.”</p>
                  <span>Eveliina, Helsinki</span>
                </div>

              </div>

            </div>
          </div>
*/ }        

          <ScrollableAnchor id={'tilauksen-kasittely'}>
            <section>
                <h2>Tilauksen käsittely</h2>
                <h3>Kauanko reseptien käsittely kestää?</h3>
                <p>Reseptitilauksenne käsittely kestää arkisin enintään 12 h.
{/*
                , mutta voit halutessasi valita tilauksellesi nopean 2 tunnin, tai vielä nopeamman 20 minuutin käsittelyajan.
*/}
                </p>
                <h3>Milloin palvelu on saatavilla?</h3>
                <p>Lääkärimme käsittelevät tilauksia arkipäivisin. Perjantaina 18:00 jälkeen jätetyt reseptitilaukset käsitellään viimeistään seuraavana arkipäivänä. </p>
{/*
                <p>Pikatilaukset saatavilla pääsääntöisesti arkisin 9:00 - 18:00. Palvelussa saattaa ajoittain olla ruuhkaa, jolloin pikatilaukset joudutaan ottamaan väliaikaisesti pois päältä.</p>
*/}                
                <h3>Miten saan tiedon reseptin käsittelystä?</h3>
                <p>Lähetämme sinulle tekstiviestin, kun lääkärimme on käsitellyt tilauksesi. Emme kuitenkaan jaa arkaluonteista tietoa sähköpostitse tai tekstiviestitse. Voit tarkistaa tilauksesi tarkemmat käsittelytiedot kirjautumalla Reseptoriin omilla tunnuksillasi.</p>
                
            </section>
          </ScrollableAnchor>


          <CreateAccountSection 
            heading = "Ota käyttöön nyt"
          />

          <ScrollableAnchor id={'hinnasto'}>
            <section>
                <h2>Hinnasto</h2>
                <h3>Perusmaksut</h3>
                <p>Reseptien uusiminen: <strong>9,90 € / lääke.</strong><br />Kokonaan uusi resepti: <strong>19,90 € / lääke.</strong></p>
                <p>Reseptit uusitaan perusmaksulla 12 tunnin sisällä. <br />Lue lisää <a href="#tilauksen-kasittely">käsittelyajoistamme</a>.</p>
{/*
                <h3>Pikatilausmaksut</h3>

                <p>Voit valita tilauksellesi nopeamman 2 tunnin ja 20 minuutin pikatilauksen. </p>
                <p>Tällöin tilaukseen lisätään pikatilaus maksu:</p>
                <p>2 tunnin tilaus: <strong>8,90 € lisämaksu / tilaus</strong><br />20 minuutin tilaus: <strong>18,90 € lisämaksu / tilaus</strong></p>

                <p>Pikatilausmaksu on tilauskohtainen, ja se lisätään jokaiseen tilaukseen vain kerran riippumatta kuinka monta reseptiä olet uusimassa tai tilaamassa samalla kerralla. </p>
*/}
                <h3>Tuleeko hintoihin muita kuluja?</h3>
                <p>
                  Reseptorin hinnoittelu on selkeää ja läpinäkyvää. Lääkärikulut sisältyvät tilauksen hintaan, eikä niihin lisätä muita kuluja.
{/*
                  Reseptien tilauksen hinta koostuu perusmaksusta ja mahdollisesta pikatilausmaksusta. 
                  Tilaukseen ei lisätä muita kuluja.
*/}
                </p>

            </section>
          </ScrollableAnchor>


          <ScrollableAnchor id={'tilin-luonti-ja-kirjautuminen'}>
            <section>

                <h2>Tilin luonti ja kirjautuminen</h2>

                <h3>Miksi minun pitää luoda tili Reseptoriin?</h3>
                <p>
                  Reseptien uusinnat ja muut terveyteen liittyvät asioinnit sisältävät sensitiivistä, salassa pidettävää 
                  tietoa. Jokainen asiointi Reseptorin digilääkäripalvelussa on lääkärikäynti. 
                  Siksi Reseptorin käyttö vaatii vahvan tunnistautumisen tilin luonnin yhteydessä. 
                  Tilin luominen helpottaa myöhempää asiointia ja e-reseptin uusiminen on entistä vaivattomampaa.
                </p>

                <h3>Miten luon tilin?</h3>
                <p>
                  Tili luodaan vahvalla tunnistautumisella verkkopankkitunnuksilla{/* tai mobiilivarmenteella */}. 
                  Voit luoda itsellesi tilin <button onClick={() => window.location.replace(tupasUrl())}>täällä</button>.
                </p>
                <p>
                  Palatessasi Reseptoriin pääset kirjautumaan tilillesi helposti sähköpostiosoitteellasi ja salasanallasi, 
                  jonka loit rekisteröitymisen yhteydessä.
                </p>
                
                <h3>Olen unohtanut tilitietoni. Miten toimin?</h3>
                <p>
                  Mikäli olet unohtanut sähköpostisi tai salasanasi, voit kirjautua tilillesi myös 
                  verkkopankkitunnuksillasi{/*tai mobiilivarmenteella*/}. Vahvan tunnistautumisen jälkeen voit tarkistaa 
                  palveluun tallennetun sähköpostisi palvelun Oma tili -sivulta, sekä luoda uuden salasanan
                  unohtuneen tilalle.
                </p>
                
            </section>
          </ScrollableAnchor>


          <CreateAccountSection 
            heading = "Luo tili vaivattomasti"
          />

          <ScrollableAnchor id={'maksutavat'}>
            <section>
                <h2>Maksutavat</h2>
                <h3>Missä vaiheessa maksan tilaukseni?</h3>
                <p>Reseptien tilaukset maksetaan tilauksen tekemisen yhteydessä. Tilaus lähtee lääkärin käsiteltäväksi vasta kun se on maksettu.</p>
                
                <h3>Miten maksan tilaukseni?</h3>
                <p>Tilauksen voi maksaa kaikilla kotimaisilla pankkikorteilla, sekä Visa, Visa Electron, Master Card ja American Express -luottokorteilla. Maksupalveluntuottajana toimii Stripe.</p>
            </section>
          </ScrollableAnchor>


          <ScrollableAnchor id={'tietoa-meista'}>
          <section>
              <h2>Tietoa meistä</h2>
              <h3>Onko Reseptorin käyttö turvallista?</h3>
              <p>Reseptorin käyttö on turvallista ja palvelu on rakennettu huomioiden potilastiedon ja henkilötiedon lainsäädännön ja asetusten vaatimukset. Reseptori palvelun tuottaa suomalainen yritys, jonka perustajat ovat toimialan ammattilaisia. Palvelu on lainsäädännön, tietosuoja-asetusten ja hyvän käytännön mukaista. Palveluun luovuttamasi tiedot säilytetään potilastietoa säätelevän lain ja Euroopan Unionin tietosuoja-asetuksen GDPR:n mukaan. Reseptin uusiminen on mahdollista vain Suomessa laillistetun lääkärin toimesta.</p>
          </section>
        </ScrollableAnchor>
        
        </div>

      <section className="section--cta">
          <h2 className="section__heading">Ota palvelu käyttöön nyt</h2>
          <button className="cta cta--register" onClick={() => window.location.replace(tupasUrl())}>Luo tili</button>
      </section>
      <section className="footer--links">
       <Logo />
          <ul>
            <li><a href="#top">Tietoa asiakkaalle</a></li>
            <li><Link to="/" onClick={this.onOpenTosModal}>Käyttöehdot</Link></li>
            <li><Link to="/" onClick={this.onOpenPrivacyModal}>Rekisteriseloste</Link></li>
          </ul>
          <div className="social">
          <Link to="https://www.facebook.com/reseptorisuomi/" target="_blank"><i className="fab fa-facebook-square"></i></Link>
          </div>
      </section>
      <Footer 
        name="Terveyspalvelu Reseptori Oy"
        adress="Teknobulevardi 3-5"
        zip="01530 Vantaa"
        y_tunnus="Y-tunnus: 2887702-3"
          mail="info@reseptori.fi"
          phone="+358 40 149 4488"
        aboutUs=""
      />
    
    <Modal open={modalOpenTos} onClose={this.onCloseTosModal} classNames={{modal:'legal-modal'}}>
        <TosText />
    </Modal>
    <Modal open={modalOpenPrivacy} onClose={this.onClosePrivacyModal} classNames={{modal:'legal-modal'}}>
      <PrivacyPolicyText />
    </Modal>

    <CookieConsent 
      buttonText="Selvä!"
      buttonClasses="accept-cookies"
      contentClasses="cookie-consent"
      disableStyles={true}>
      Käytämme evästeitä paremman käyttökokemuksen takaamiseksi. Jatkamalla sivuston käyttöä hyväksyt evästeiden tallentamisen koneellesi. <button type="button" className="link-button" onClick={this.onOpenCookiesModal}>Lisätietoja</button>.
    </CookieConsent>
    

    <Modal open={modalOpenCookies} onClose={this.onCloseCookiesModal} classNames={{modal:'legal-modal'}}>
        <CookiesText />
    </Modal>

      { tagManager }
    </div>

    )
  }
}

export default FaqPage
