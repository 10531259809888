import React, { Component } from 'react'

export class SessionOverlay extends Component {
  render() {
    return (
      <div className="overlay">
        <div className="overlay__box">
          <div className="overlay__box-title">Istunto sulkeutumassa</div>
          <div className="overlay__box-desc">Istuntosi on sulkeutumassa. Valitse jatka, mikäli haluat jatkaa istuntoasi. Muussa tapauksessa käyttäjätilisi kirjataan ulos automaattisesti 2 min kuluttua.</div>
          <button className="overlay__box-button btn--green" onClick={() => this.props.continueSession()}>Jatka</button>
        </div>
      </div>
    )
  }
}

export default SessionOverlay
