import { call, put, takeLatest } from 'redux-saga/effects'
import { types } from '../ducks/stripe'

import { actions as uiActions } from '../ducks/ui'
import api from '../api'
import { makeRequest } from './common'

const startPaymentSession = makeRequest.bind(null, api.startPaymentSession)

function* fetchStripe() {
	try {
        yield put(uiActions.startRequest())
		const response = yield call(startPaymentSession)
        console.log("response",response)
		if (!response.ok) {
			throw new Error('API call failed with ', response)
		}

        const data = yield response.json()

		yield put({
			type: types.RECEIVE_STRIPE_DATA,
			data: data
		})

	} catch (e) {
		console.log('Returning from STRIPE failed ', e)
	} finally {
        yield put(uiActions.endRequest())
    }	
}

export function* stripeSessionListener() {
	yield takeLatest('FETCH_STRIPE_SESSION', fetchStripe)
}