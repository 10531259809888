import React, { Component } from 'react'
import { chatTypes } from '../../chat/Chat'
import { BubbleDoctor } from '../../chat/BubbleDoctor'
import { BubbleSelf } from '../../chat/BubbleSelf'
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default class Buttons extends Component {

	state = {
		chathistory: '',
		copied: false,
	};

	escapeHTML(text) {
		const withLineBreaks = text.replace(/(?:<br[^>]*>){2,}/ig, '\n');
		const withoutHyphens = withLineBreaks.replace(/(&([^>]+);)/ig, '');
		return withoutHyphens.replace(/(<([^>]+)>)/ig, '');
	}

	render() {
		return (
			<div className="prescription__diagnose">
				<CopyToClipboard text={
					this.props.chat.map((c, i) => this.escapeHTML(c.text))
				} onCopy={() => this.setState({ copied: true })}>
				<h2 className="show__diagnose">Potilaan antamat tiedot 
					
						
						<button className="chat__clipboard"><i className="far fa-copy" data-fa-transform="grow-2"></i> Kopioi</button>
					
				</h2>
				</CopyToClipboard>
				{this.state.copied ? <div className="chat__clipboard_notification">Keskustelu kopioitu leikepöydälle <i className="far fa-copy" data-fa-transform="grow-2"></i></div> : null}

				
				<div className="chat__container">
					{
						this.props.chat.map((c, i) => {
							switch (c.type) {
								case chatTypes.TYPE_QUESTION:
									return <BubbleDoctor key={i} content={c.text} />
								default:
									return <BubbleSelf key={i} content={c.text} />
							}
						})
					}
				</div>

			</div>
		)
	}
}