import { actionUtils } from './common'

export const types = {
	RECEIVE_TUPAS_DATA: Symbol('registration/RECEIVE_TUPAS_DATA'),
	REGISTER_EMAIL: Symbol('registration/REGISTER_EMAIL'),
	REGISTER_PHONE: Symbol('registration/REGISTER_PHONE'),
	REGISTER_PASSWORD: Symbol('registration/REGISTER_PASSWORD'),
	ACCEPT_EULA: Symbol('registration/ACCEPT_EULA')
}

let initialState = {
	hetu: '',
	firstName: 'Myrsky2',
	lastName: 'Markkanen',
	email: '',
	phone: '1234',
	password: 'TarpeeksiHyvaSalasana1!',
	marketingAllowed: false,
	eulaAccepted: false
}

export default (state = initialState, action) => {
	
	switch (action.type) {

		case types.RECEIVE_TUPAS_DATA:
			return {
				...state,
				hetu: action.data.hetu,
				firstName: action.data.firstName,
				lastName: action.data.lastName,
			}

		case types.REGISTER_EMAIL:
			return {
				...state,
				email: action.email
			}

		case types.REGISTER_PHONE:
			return {
				...state,
				phone: action.phone
			}

		case types.REGISTER_PASSWORD:
			return {
				...state,
				password: action.password
			}

		case types.ACCEPT_EULA:
			return {
				...state,
				eulaAccepted: action.eulaAccepted,
				marketingAllowed: action.marketingAllowed
			}

		default:
			return state
			
	}

}

const acceptEula = (eulaAccepted, marketingAllowed) =>
		actionUtils.action(types.ACCEPT_EULA, {
			eulaAccepted: eulaAccepted,
			marketingAllowed: marketingAllowed,
			nextPath: '/hello'
		})


export const actions = {

	fetchRegistrationData: () => ({ type: 'FETCH_REGISTRATION' }),
	// startRegistration: () => push('/registerEmail'),
	setEmail: (email) => actionUtils.action(types.REGISTER_EMAIL, { email: email }),
	setPhone: (phone) => actionUtils.action(types.REGISTER_PHONE, { phone: phone }),
	setPassword: (password) => actionUtils.action(types.REGISTER_PASSWORD, { password: password }),

	createUser: (eulaAccepted, marketingAllowed) => {
		return dispatch => {
			dispatch(acceptEula(eulaAccepted, marketingAllowed))
			dispatch({ type: 'CREATE_USER' })
		}
	}

}