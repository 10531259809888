import * as React from 'react';

import { connect } from 'react-redux';

import { actions } from '../ducks/session';
import { actions as uiActions} from '../ducks/ui';

class SortBox extends React.Component {

    constructor() {
        super();
        this.openOrderSelector = this.openOrderSelector.bind(this);
        this.renderOrderSelector = this.renderOrderSelector.bind(this);

        this.availableOrders = ['Uusimmat ensin', 'Vanhimmat ensin', 'Nimi: A-Ö', 'Nimi: Ö-A'];
    }

    componentWillUnmount() {
        if(this.closeSelectorOnClick) {
            document.removeEventListener('click', this.closeSelectorOnClick);
        }
    }

    openOrderSelector(e) {
        e.preventDefault();
        this.props.showCompletedOrdersSortSelector();
        const closeSelectorOnClick = (e) => {
            if(this.sortContainer !== e.target) {
                this.props.closeCompletedOrdersSortSelector();
                document.removeEventListener('click', closeSelectorOnClick);
            }
        }
        this.closeSelectorOnClick = closeSelectorOnClick;
        document.addEventListener('click', closeSelectorOnClick);
    }

    sortItems(newSortOrder) {
        this.props.fetchCompletedOrders(this.props.filter, newSortOrder, this.props.offset);
        this.props.closeCompletedOrdersSortSelector();
    }

    render() {
        return(
            <div className="sort-container" ref={element => this.sortContainer = element}>
                { this.props.orderSelectorVisible
                    ? this.renderOrderSelector()
                    : <div onClick={this.openOrderSelector}>
                        <span> {this.props.sortOrder} </span>
                        <span><i className="fas fa-caret-down"></i></span>
                    </div>
                }
            </div>
        );
    }

    renderOrderSelector() {
        return (
            <div className="sort-popup">
                <ul>
                    { this.availableOrders.map((item, i) =>
                        <li className="sort-popup__item" key={`sort-item${i}`} onClick={(e) => this.sortItems(item)}> 
                            {item === this.props.sortOrder && <i className="fas fa-check"></i>}<span>{item}</span>
                        </li>
                    )}
                </ul>
            </div>
        );
    }
}

export default connect(state => ({
    sortOrder: state.doctor.session.sortOrder,
    filter: state.doctor.session.filter,
    offset: state.doctor.session.offset,
    orderSelectorVisible: state.doctor.ui.orderSelectorVisible,
}), ({ ...actions, ...uiActions }))(SortBox);