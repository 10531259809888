import { actionUtils } from './common'

export const types = {
	UPDATE_PERSONAL_DATA: Symbol('profile/UPDATE_PERSONAL_DATA'),
	SET_PASSWORD: Symbol('profile/SET_PASSWORD'),
	PASSWORD_CHANGED: Symbol('profile/PASSWORD_CHANGED'),
}

let initialState = {
	password_change_failed: false,
	password_change_success: false,
}

export default (state = initialState, action) => {

	switch (action.type) {
		case types.SET_PASSWORD:
			return {
				...state,
				password_change_failed: false,
				password_change_success: false,
			}
		case types.PASSWORD_CHANGED:
			const changeSucceeded = action.success;
			return {
				...state,
				password_change_failed: !changeSucceeded,
				password_change_success: changeSucceeded,
			}
		default:
			return state
	}
}

export const actions = {
	updatePersonalData: (email, phone) => actionUtils.action(types.UPDATE_PERSONAL_DATA, { email: email, phone: phone }),
	setPassword: (password) => actionUtils.action(types.SET_PASSWORD, { password: password})
}

export const selectors = {
	getUser: state => state.session.user
}

