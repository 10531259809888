import * as React from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';

import { actions, ORDER_FETCH_SIZE } from '../ducks/session';

class SearchBox extends React.Component {

    constructor() {
        super();
        this.state = {
            value: ''
        }
        this.onChange = this.onChange.bind(this);
        this.search = debounce(this.search, 500)
    }

    onChange(searchValue) {
        this.setState({ value: searchValue });
        this.search();
    }

    search() {
        this.props.fetchCompletedOrders(this.state.value, this.props.sortOrder, ORDER_FETCH_SIZE);
    }

    render() {
        return (
            <div className="search-box">
                <input id="search-box" className="search-input" type="text" placeholder="Hae nimellä tai hetulla" onChange={(e) => 
                    this.onChange(e.target.value)} value = { this.state.value }/> 
                    <div className="icon-wrapper">
                        <i className="fas fa-search"></i>
                    </div>
            </div>
        );
    }
}

export default connect(state => ({
    filter: state.doctor.session.filter,
    sortOrder: state.doctor.session.sortOrder,
    offset: state.doctor.session.offset,
}), { ...actions })(SearchBox);