export const chat = {
	1: {
		question: 'Kertoisitko onko sinulla aikaisemmin ollut virtsatietulehdus?',
		type: 'oneOf',
		answers: [{
			text: 'Kyllä',
			next: 2
		}, {
			text: 'Ei',
			next: 2
		}]
	},
	2: {
        question: 'Onko sinulla tihentynyttä virtsaamisen tarvetta, virtsaamispakkoa tai kirvelyä virtsatessa?',
		type: 'oneOf',
		answers: [{
			text: 'Kyllä',
			next: 3
		}, {
			text: 'Ei',
			next: 8
		}]
    },
    3: {
        question: 'Onko mahdollista tai todennäköistä, että olisit voinut saada sukupuolitautitartunnan?',
		type: 'oneOf',
		answers: [{
			text: 'Kyllä',
			next: 8
		}, {
			text: 'Ei',
			next: 4
		}]
    },
    4: {
        question: 'Onko sinulla ollut kuumetta, selkäkipua tai kylkikipua?',
		type: 'oneOf',
		answers: [{
			text: 'Kyllä',
			next: 8
		}, {
			text: 'Ei',
			next: 5
		}]
    },
    5: {
        question: 'Oletko raskaana?',
		type: 'oneOf',
		answers: [{
			text: 'Kyllä',
			next: 6
		}, {
			text: 'En',
			next: 6
		}]
    },
    6: {
        question: 'Onko sinulla lääkeaineallergioita?',
        type: 'oneOf',
		answers: [{
			text: 'Kyllä',
			next: 7
		}, {
			text: 'Ei',
			next: 9
		}]
    },
    7: {
        question: 'Kertoisitko mitä lääkeaineallergioita sinulla on?',
        type: 'inputField',
        next: 9

    },
    8: {
        question: 'Kiitos vastauksistasi. Ilmoittamasi tietojen perusteella emme voi määrätä sinulle tätä lääkitystä Reseptorin kautta. Otathan yhteyttä omaan lääkäriisi terveydentilasi jatkotutkimuksia varten.',
		answers: null,
		canOrder: false
    },
    9: {
		question: 'Kiitos vastauksistasi. Ilmoittamasi tietojen perusteella reseptipyyntösi voidaan välittää eteenpäin Reseptorin lääkärille, joka tekee lopullisen päätöksen reseptin myöntämisestä.',
		answers: null,
		canOrder: true,
		discardOnSave: true,
	}


}  