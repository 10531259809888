import { call, put, take } from 'redux-saga/effects'
import { types } from '../ducks/profile'
import { types as sessionTypes } from '../ducks/session'
import { types as profileTypes } from '../ducks/profile'
import { actions as uiActions } from '../ducks/ui'

import api from '../api'
import { makeRequest } from './common'

import { push } from 'react-router-redux'

const updatePersonalData = makeRequest.bind(null, api.updatePersonalData)
const setPassword = makeRequest.bind(null, api.setPassword)

export function* setPasswordListener() {

	while (true) {

		const action = yield take(types.SET_PASSWORD)
		
		try {
			
			yield put(uiActions.startRequest())	
			const response = yield call(setPassword, action.password);
			yield put({type: profileTypes.PASSWORD_CHANGED, success: response.status === 200 })
			yield put(push('/profile'))
		} finally {
			yield put(uiActions.endRequest())		
		}
	}

}

export function* updatePersonalDataListener(email, phone) {

	while (true) {

		const action = yield take(types.UPDATE_PERSONAL_DATA)
		
		try {
			
			yield put(uiActions.startRequest())	
			
			const response = yield call(updatePersonalData, action.email, action.phone)
			const data = yield response.json()

			yield put({ type: sessionTypes.RECEIVE_USER_DATA, data: data })

		} finally {
			yield put(uiActions.endRequest())		
		}
	}

}
