import { actionUtils, pharmacyFlatOrder } from '../../ducks/common'
import { apiConstants } from '../../common/constants'

export const availablePrescriptionLists = [{ name: 'Reseptilista', status: 'unchecked' }, { name: 'Historia', status: 'checked' }];


export const types = {
	LOGIN: Symbol('pharmacist/session/LOGIN'),
    LOGOUT: Symbol('pharmacist/session/LOGOUT'),
    
    RECEIVE_PHARMACIST_DATA: Symbol('pharmacist/session/RECEIVE_PHARMACIST_DATA'),
	FETCH_PHARMACIST_OFFICES: Symbol('pharmacist/session/FETCH_PHARMACIST_OFFICES'),
	
	RECEIVE_CURRENT_OFFICES: Symbol('pharmacist/session/RECEIVE_CURRENT_OFFICES'),

	SET_OFFICE: Symbol('pharmacist/session/SET_OFFICE'),
	GET_OFFICE: Symbol('pharmacist/session/GET_OFFICE'),
	RECEIVE_OFFICE: Symbol('pharmacist/session/RECEIVE_OFFICE'),
	CREATE_PHARMACY_USER: Symbol('pharmacist/session/CREATE_PHARMACY_USER'),
	CREATE_PHARMACY_USER_FAILED: Symbol('pharmacist/session/CREATE_PHARMACY_USER_FAILED'),
	CREATE_PHARMACY_USER_SUCCESS: Symbol('pharmacist/session/CREATE_PHARMACY_USER_SUCCESS'),
	UPDATE_PHARMACY_USER: Symbol('pharmacist/session/UPDATE_PHARMACY_USER'),
	DELETE_PHARMACY_USER: Symbol('pharmacist/session/DELETE_PHARMACY_USER'),

	FETCH_PHARMACY_USERS: Symbol('pharmacist/session/FETCH_PHARMACY_USERS'),
	RECEIVE_PHARMACY_USERS: Symbol('pharmacist/session/RECEIVE_PHARMACY_USERS'),

	SAVE_PATIENT_INFO: Symbol('pharmacist/session/SAVE_PATIENT_INFO'),
	PATIENT_INFO: Symbol('pharmacist/session/PATIENT_INFO'),

  CHOOSE_PRODUCT: Symbol('pharmacist/session/CHOOSE_PRODUCT'),
  SET_DELIVERY_OPTION: Symbol('pharmacist/session/SET_DELIVERY_OPTION'),
	SEND_CART: Symbol('pharmacist/session/SEND_CART'),
	CART_SENT_SUCCESS: Symbol('pharmacist/session/CART_SENT_SUCCESS'),

	FETCH_PHARMACY_CART: Symbol('pharmacist/session/FETCH_PHARMACY_CART'),
	RECEIVE_PHARMACY_CART: Symbol('pharmacist/session/RECEIVE_PHARMACY_CART'),
	DELETE_PHARMACY_CART: Symbol('pharmacist/session/DELETE_PHARMACY_CART'),

	REMOVE_ITEM_FROM_LIST: Symbol('pharmacist/session/REMOVE_ITEM_FROM_LIST'),
	ITEM_REMOVED_FROM_LIST: Symbol('pharmacist/session/ITEM_REMOVED_FROM_LIST'),

	FETCH_PHARMACY_ORDER_HISTORY: Symbol('pharmacist/session/FETCH_PHARMACY_ORDER_HISTORY'),
	RECEIVE_PHARMACY_ORDER_HISTORY: Symbol('pharmacist/session/RECEIVE_PHARMACY_ORDER_HISTORY'),

	SET_SELECTED_LIST_MODE: Symbol('pharmacist/session/SET_SELECTED_LIST_MODE'),
	CHECK_USERNAME: Symbol('pharmacist/session/CHECK_USERNAME'),
	CHECK_USERNAME_RESULT: Symbol('pharmacist/session/CHECK_USERNAME_RESULT'),
	GET_MESSAGE: Symbol('pharmacist/session/GET_MESSAGE'),
	SET_MESSAGE: Symbol('pharmacist/session/SET_MESSAGE'),
	RECEIVE_CURRENT_MESSAGE: Symbol('pharmacist/session/RECEIVE_CURRENT_MESSAGE'),
	SET_MESSAGE_CHECKED: Symbol('pharmacist/session/SET_MESSAGE_CHECKED'),
	SET_MESSAGE_UNCHECKED: Symbol('pharmacist/session/SET_MESSAGE_UNCHECKED'),
	GET_MESSAGE_STATUS: Symbol('pharmacist/session/GET_MESSAGE_STATUS'),
	RECEIVE_MESSAGE_STATUS: Symbol('pharmacist/session/RECEIVE_MESSAGE_STATUS'),
}

let initialState = {
    pharmacist: null,
    username:'',
	currentPharmacyUser: null,
	currentOffices: null,
	currentOffice: {pharmacyName: ''},
	newUser: null,
	currentUsers: { "pharmacyUsers":[] },
	patientInfo:  null,
	cart: {
		items: []
	},
	orderHistory: [],
	selectedListMode: availablePrescriptionLists[0],
	pharmacy_user_meta: {
		saving: false,
		save_failed: false,
		saved: false,
		checking_user_name: false,
	},
	existingUserName: false,

	currentMessage: {
		status: "",
		header: "",
		text: ""
	},
	currentMessageStatus: false,

}

export default (state = initialState, action) => {
	console.log("action type = ",action)
	switch (action.type) {

		case types.LOGIN:
			return {
				...state,
				username: action.username
			}

		case types.RECEIVE_PHARMACIST_DATA:
			return {
				...state,
				pharmacist: action.data
			}
		case types.RECEIVE_CURRENT_OFFICES:
			console.log('RECEIVE_CURRENT_OFFICES')
			return {
				...state,
				currentOffices: action.offices
			}
		case types.SET_OFFICE_FAILED:
			console.log('SET_OFFICE_FAILED')
			return {
				...state,
			}
		case types.RECEIVE_OFFICE:
			return {
				...state,
				currentOffice: action.data
			}
		case types.RECEIVE_PHARMACY_USERS:
			return {
				...state,
				currentUsers: action.currentUsers
			}
			
		case types.CREATE_PHARMACY_USER: {
			const previousUserState = state.pharmacy_user_meta;
			return {
				...state, 
				pharmacy_user_meta: {
					...previousUserState,
					saving: true,
					saved: false,
					save_failed: false,
				}
			};
		}
			
		case types.CREATE_PHARMACY_USER_FAILED: {
			const previousUserState = state.pharmacy_user_meta;
			return { 
				...state, 
				pharmacy_user_meta: {
					...previousUserState,
					save_failed: true,
					saving: false,
					saved: false,
				}
			};
		}
			
		case types.CREATE_PHARMACY_USER_SUCCESS: {
			const previousUserState = state.pharmacy_user_meta;
			const updatedUsers = state.currentUsers;
			updatedUsers.pharmacyUsers.push(action.user);
			return { 
				...state,
				currentUsers: updatedUsers,
				pharmacy_user_meta: {
					...previousUserState,
					save_failed: false,
					saving: false,
					saved: true,
				}
			};
		}
		case types.PATIENT_INFO:
			return {
				...state,
				patientInfo: action.patientInfo
			}
		case types.RECEIVE_PHARMACY_ORDER_HISTORY: {
			return {
				...state,
				orderHistory: action.products
			}
		}
		case types.RECEIVE_PHARMACY_CART:
		return {
			...state,
			cart: action.cart
		}

		case types.ITEM_REMOVED_FROM_LIST: {
			const itemList = state.orderHistory.map(item => item.prescriptions).reduce((acc, ele) => acc.concat(ele), []);
			const removedItem = itemList.find(ele => ele.id === action.itemId);
			const orderContainingItem = state.orderHistory.find(item => item.prescriptions.map(ele => ele.id).includes(removedItem.id));
			const updatedPrescriptions = orderContainingItem.prescriptions.filter((item) => item.id !== removedItem.id);
			orderContainingItem.prescriptions = updatedPrescriptions;
			const updatedHistory = state.orderHistory.map(item => item.orderId !== orderContainingItem.orderId ? item : orderContainingItem);
			return {
				...state,
				orderHistory: updatedHistory,
			}
		}

		case types.CART_SENT_SUCCESS: {
			const updatedHistory = state.orderHistory.concat([action.order]);
			return {
				...state,
				orderHistory: updatedHistory,
			}
		}
		case types.SET_SELECTED_LIST_MODE: {
			return {
				...state,
				selectedListMode: action.selectMode,
			}
		}
		case types.CHECK_USERNAME: {
			const previousUserState = state.pharmacy_user_meta;
			return {
				...state,
				pharmacy_user_meta: {
					...previousUserState,
					checking_user_name: true,
					saved: false,
				}
			}
		}
		case types.CHECK_USERNAME_RESULT: {
			const previousUserState = state.pharmacy_user_meta;
			return {
				...state,
				pharmacy_user_meta: {
					...previousUserState,
					checking_user_name: false,
				},
				existingUserName: action.result,
			}
		}

		case types.RECEIVE_CURRENT_MESSAGE:
		console.log("action.data",action.data)
			return {
				...state,
				currentMessage: action.data
			}
		case types.RECEIVE_MESSAGE_STATUS:
			return {
				...state,
				currentMessageStatus: action.status
			}
		default:
			return state
	}
}

export const actions = {
	login: (username, password) => actionUtils.action(
        types.LOGIN, { 
            username: username, 
            password: password 
        }),
	logout: () => actionUtils.action(types.LOGOUT),
	receiveCurrentOffices: () => actionUtils.action(types.RECEIVE_CURRENT_OFFICES),
	setOffice: (officeId) => actionUtils.action(types.SET_OFFICE, { id: officeId }),
	getOffice: (data) => actionUtils.action(types.GET_OFFICE,data),
	setOfficeFailed: () => actionUtils.action(types.SET_OFFICE_FAILED),
	createPharmacyUser: (firstname, lastname, username, password) => actionUtils.action(
		types.CREATE_PHARMACY_USER,{
			firstName: firstname,
			lastName: lastname,
			username: username,
			password: password
		}),
	updatePharmacyUser: (id, firstname, lastname, username, password) => actionUtils.action(types.UPDATE_PHARMACY_USER, {
		id: id,
		firstName: firstname,
		lastName: lastname,
		username: username,
		password: password
	}),
	deletePharmacyUser: (username) => actionUtils.action(types.DELETE_PHARMACY_USER, {username: username}),
	fetchPharmacyUsers: () => actionUtils.action(types.FETCH_PHARMACY_USERS),
	savePatientInfo: (firstname,lastname,hetu) => actionUtils.action(
		types.SAVE_PATIENT_INFO,{
			firstName: firstname,
			lastName: lastname,
			hetu: hetu
  }),
  setDeliveryOption: (option) => actionUtils.action(
    types.SET_DELIVERY_OPTION, { option }
  ),
	chooseProduct: (productId, type, reason, pharmacyData) => actionUtils.action(
		types.CHOOSE_PRODUCT, {
			productId,
			orderType: type,
			reason,
			pharmacyData,
		}),
	sendCart: () => actionUtils.action(types.SEND_CART),
	fetchPharmacyCart: () => actionUtils.action(types.FETCH_PHARMACY_CART),
	deletePharmacyCart: () => actionUtils.action(types.DELETE_PHARMACY_CART),

	fetchPharmacyOrders: () => actionUtils.action(types.FETCH_PHARMACY_ORDER_HISTORY),
	removeItemFromList: (itemId) => actionUtils.action(types.REMOVE_ITEM_FROM_LIST, { itemId: itemId }),
	setSelectedListMode: (selectMode) => actionUtils.action(types.SET_SELECTED_LIST_MODE, { selectMode }),
	checkUsername: (userName) => actionUtils.action(types.CHECK_USERNAME, { userName }),
	getMessage: () => actionUtils.action(types.GET_MESSAGE),
	checkMessage: () => actionUtils.action(types.SET_MESSAGE_CHECKED),
	uncheckMessage: () => actionUtils.action(types.SET_MESSAGE_UNCHECKED),
	getMessageStatus: () => actionUtils.action(types.GET_MESSAGE_STATUS),
}



const getOrderHistory = state => {

	let allOrders = pharmacyFlatOrder(state.pharmacist.session.orderHistory)

	//console.log("allOrders",allOrders)
	if (!allOrders.length) return []

	//return allOrders
	return allOrders.map(o => mapRow2Product(state, o))
}


const mapRow2Product = (state, r) => {

	const p = state.product.products.find(p => p.productId === r.productId)
	
	const renewableTill = new Date(r.deliveryTime)
	const patientHetu = r.patientHetu
	const patientFirstName = r.patientFirstName
	const patientLastName = r.patientLastName
	const created = new Date(r.created)


	renewableTill.setFullYear(renewableTill.getFullYear() + 2)
	
	return r.type === apiConstants.CART_PURCHASE_TYPE_NEW ?
		r :
		{
			...r,
			productId: p.productId,
			displayName: p.displayName,
			format: p.format,
			created: created.getHours() + ':' + (created.getMinutes() < 10 ? '0' : '') + created.getMinutes(),
			patientHetu: patientHetu,
			patientFirstName: patientFirstName,
			patientLastName: patientLastName,
			renewableTill: (renewableTill.getMonth() + 1) + '/' + renewableTill.getFullYear()
		}
}

export const selectors = {

	getCurrentPharmacyUsers: (state) => {
		const pharmacyusers = state.pharmacist.session.currentUsers.pharmacyUsers
		return { pharmacyusers: pharmacyusers}
	},

	checkPharmacyUser: (state) => {
		const pharmacyusers = state.pharmacist.session.currentUsers.pharmacyUsers

		console.log(pharmacyusers)
	},
	getCurrentPharmacist: (state) => (state.pharmacist.session.pharmacist),
	getCurrentOffice: (state) => (state.pharmacist.session.currentOffice),
	getCurrentOffices: (state) => {
		const currentOffices = state.pharmacist.session.currentOffices
		return { ...currentOffices }
	},

	getProducts: state => {
		return  state.product.products
	},

	getList: state => {

		const list = state.pharmacist.session.orderHistory

		return {
			...list
		}
	},

	getOrderHistory: state => {
		const orderHistory = getOrderHistory(state)
		return orderHistory
    },
	getCurrentMessage: (state) => (state.pharmacist.session.currentMessage),
	getMessageStatus: (state) => (state.pharmacist.session.currentMessageStatus),
}