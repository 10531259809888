import React, { Component } from 'react'
import Checkout from '../prescriptionlist/Checkout'
import NavigationBar from '../common/NavigationBar'
import { connect } from 'react-redux'
import queryString from 'query-string'

import { selectors, actions } from '../ducks/session'

class HandlingAndCampaign extends Component {

    constructor(props) {

        super(props);
       
        this.state = {
            code: '',
            handingItems : [
                {time: '12 h', description: 'sis. perushintaan', disabled: false},
                {time: '2 h', description: '+ 8,90 €', disabled: false},
                {time: '20 min', description: '+ 17,90 €', disabled: true}
              ]
        };
    }

    componentDidMount() {
        const values = queryString.parse(this.props.location.search)
        console.log("queryString",values)
        const success = values.success
        
        if (values) {
            if( success === "false" ) {
                console.log("success is false")
            } else {
                console.log("success is something else")
            }
        }
    }

    onChangeRadio(value) {
        console.log(value);
    }

    onChangeCode() {
        console.log('code');
    }

    applyCode = () => {
        if (this.state.code.trim() !== '') {
            this.props.applyCoupon(this.state.code)
        }
    }

    undoCode = () => {
        this.props.unApplyCoupon()
        this.setState({ code: '' })
    }

    isCodeEntered = () => {
        return this.state.code.trim().length
    }

    onKeyPress = (e) => {
        if (e.key === 'Enter' && this.isCodeEntered()) {
            this.applyCode()
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.code.trim() === '' && nextProps.coupon)
            this.setState({ code: nextProps.coupon.code })
    }

	render() {

        const { coupon, couponSubmitted } = this.props
        const submitFailed = couponSubmitted && !coupon

		return (
        <div>
            <NavigationBar loggedIn={true}/>    

            <div className="handling-campaign-wrapper">
            
                <div className="handling-campaign">
                    <h1 className="handling-campaign-title">Kampanjakoodi</h1>

                    <div className="campaign-code">
                        <input
                            className="discount__input"
                            type="text"
                            placeholder="Syötä kampanjakoodi"
                            value={this.state.code}
                            onChange={ (e) => this.setState({ code: e.target.value }) }
                            onKeyPress={ (e) => this.onKeyPress(e) }
                            disabled={coupon}
                        />

                        <div className="input-buttons">
                            {
                                coupon ? (
                                    <button className="code-undo" onClick={this.undoCode}> </button>
                                ) : (
                                    <button className="code" onClick={this.applyCode}> </button>
                                )
                            }
                        </div>
                    </div>

                    { submitFailed ? <div className="error">Kampanjakoodia ei löytynyt</div> : null }
                    
                    {
                        coupon ? (         
                            <div className="discount">
                            Alennus <span>-{this.props.cart.coupon.discountEuro}</span>
                            </div>
                        ) : null
                    }

                
                </div>
                
                {this.props.cart.items.length > 0 && 
                    <div className="button-wrapper">
                        <Checkout 
                        cart={this.props.cart.items}
                            active={this.props.cart.items.length ? 'active' : ''}
                            email={this.props.email}
                            cartPrice={this.props.cart.totalPrice}
                            cartPriceEuro={this.props.cart.priceEuro}
                            payCart={this.props.payCart}
                        />
                    </div>
                }
                
            </div>

        </div>
		)
	}
}

export default connect(

    state => {

        const cart = selectors.getCart(state)

        return {
            cart: cart,
            coupon: cart.coupon,
            email: state.session.user.email,
            couponFound: state.ui.couponFound,
            couponSubmitted: state.ui.couponSubmitted
        }
    },
    {
      ...actions
    }
)(HandlingAndCampaign)


