import React, { Component } from 'react'
import { connect } from 'react-redux'
import { actions } from '../ducks/session'
import { actions as uiActions } from '../ducks/ui'
import { selectors } from '../ducks/session'

import { DoctorNavigationBar } from '../../common/NavigationBar'
import OrderContainer from '../order/OrderContainer'
import { SessionOverlay } from '../common/SessionOverlay'
import QueueSwitchPanel from '../common/QueueSwitchPanel';

import { DOCTOR_POLL_NEW_ORDERS_INTERVAL, apiConstants } from '../../common/constants'

class WelcomeView extends Component {
  
  render() {

    const { waitingForDoctor, processing } = this.props.queue
    const { pickNextOrder } = this.props

    return (
      <div className="doctor-welcome">
        <div className="orderlist-info">
          <QueueSwitchPanel nextOrderExpiring={this.props.queue.nextOrderExpiring} />
          <span className="bold">
            {waitingForDoctor} odottavaa tilausta
          </span> - {processing} käsittelyssä
        </div>
        <button disabled={!waitingForDoctor} onClick={pickNextOrder} className="next-order btn--green">Seuraava työ</button>
      </div>
    )
  }
}

export class AppContainer extends Component {

  componentDidMount() {
    const { fetchOrders } = this.props;
    const interval = setInterval(() => fetchOrders(), DOCTOR_POLL_NEW_ORDERS_INTERVAL)
    this.setState({ interval })

    this.props.getMessage()
  }

  componentWillUnmount = () => {
    clearInterval(this.state.interval)
  } 

  getPrescription = (id) => {
    return this.props.currentOrder.prescriptions.find(pr => pr.id === id)
  }

  getReason = (id) => {
    const p = this.getPrescription(id)
    return !p.reasonForDecision ? '' : p.reasonForDecision
  }

  acceptPrescription = (id) => {
    const { submitDecision } = this.props;
    submitDecision(id, apiConstants.PRESCRIPTION_DECISION_ACCEPTED, this.getReason(id))
  }

  declinePrescription = (id, reason, rejectionType) => {
    // Mandatory reason for rejected prescriptions
    const { submitDecision } = this.props;
    const type = rejectionType || apiConstants.PRESCRIPTION_DECISION_REJECTED_WITH_COUPON

    if (!reason || !reason.trim()) throw new Error('Reason is mandatory when rejecting prescriptions.')

    submitDecision(id, type, reason)
  }

  setDecisionReason = (id, decisionReason) => {
    const { submitDecision } = this.props;
    const p = this.getPrescription(id)
    submitDecision(id, p.decision, decisionReason)
  }

  render() {
    const {
      sessionExpires,
      continueSession,
      logout,
      currentDoctor,
      currentMessage,
      currentOrder,
      queue,
      hideSystemStatus,
      pickNextOrder,
      cancelDecision,
      submitOrder,
      releaseOrder,
      releaseOneOrder
    } = this.props;
    return (
      <div className="app-container doctor">

        {
          sessionExpires ?
          <SessionOverlay continueSession={continueSession} /> :
          ''
        }

        <DoctorNavigationBar
          loggedIn={true}
          logout={logout}
          firstName={currentDoctor.firstName}
          lastName={currentDoctor.lastName}
          navMessage={currentMessage}
        />

        <div className="content">
          {
            !currentOrder ?
            <WelcomeView
              queue={queue}
              currentOrder={currentOrder}
              hideSystemStatus={hideSystemStatus}
              pickNextOrder={pickNextOrder}
            />
            :
            <OrderContainer
              currentOrder={currentOrder}
              decline={this.declinePrescription}
              accept={this.acceptPrescription}
              setReason={this.setDecisionReason}
              cancelDecision={cancelDecision}
              submitOrder={submitOrder}
              releaseOrder={releaseOrder}
              releaseOneOrder={releaseOneOrder}
              scope="orders"
              />
          }
        </div>
       { /* <ButtonNext chooseNext={this.chooseNext} /> */}
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchOrders: () => dispatch(actions.fetchOrders()),
  submitDecision: (id, decision, reason) => dispatch(actions.submitDecision(id, decision, reason)),
  continueSession: () => dispatch(uiActions.continueSession()),
  logout: () => dispatch(actions.logout()),
  hideSystemStatus: () => dispatch(uiActions.hideSystemStatus()),
  pickNextOrder: () => dispatch(actions.pickNextOrder()),
  cancelDecision: (id) => dispatch(actions.cancelDecision(id)),
  submitOrder: (orderId) => dispatch(actions.submitOrder(orderId)),
  releaseOrder: () => dispatch(actions.releaseOrder()),
  releaseOneOrder: (orderId) => dispatch(actions.releaseOneOrder(orderId)),
  getMessage: () => dispatch(actions.getMessage())
});

export default connect(
  state => ({
    currentOrder: selectors.getCurrentOrder(state),
    currentDoctor: selectors.getCurrentDoctor(state) || { firstName: 'Eikka', lastName: 'Ei Sessio'},
    queue: selectors.getSystemStatus(state) || { waitingForDoctor: 0, processing: 0 },
    currentMessage: selectors.getCurrentMessage(state)
  }),
  mapDispatchToProps
)(AppContainer)



