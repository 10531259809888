import { apiConstants } from '../common/constants'

const SEX_FEMALE = 'f'
const SEX_MALE = 'm'
const SEX_BOTH = 'b'

export const CHAT_SCRIPTS = [
	{
		keyword: 'erection_disorder',
		displayName: 'Erektiohäiriö',
		script: 'erection_disorder',
		sex: SEX_MALE 
	}, {
		keyword: 'allergy',
		displayName: 'Allergia',
		script: 'allergy',
		sex: SEX_BOTH 
	}, {
		keyword: 'urethritis',
		displayName: 'Virtsatieinfektio',
		script: 'vti',
		sex: SEX_FEMALE
	}

]

export const chatTypes = {
	TYPE_ANSWER: 'ANSWER',
	TYPE_QUESTION: 'QUESTION',
	TYPE_SELECT: 'SELECT',
	TYPE_INPUT: 'INPUT',
	TYPE_INPUT_AUTO_COMPLETE: 'INPUT_AUTO_COMPLETE',
	TYPE_MULTICHOICE: 'TYPE_MULTICHOICE'
}

function getChat(history, user, choosePrescription, chooseProduct, closeChat) {

	const newRoundOptions = () => {
		history.push({
			options: [
				{
					text: 'Haen toista reseptiä',
					callback: () => {
						history.pop()
						history.push({
							text: 'Haen toista reseptiä',
							type: chatTypes.TYPE_ANSWER
						})
						history.push(script[0])
						history.push(script[1])
					}
				},
				{
					text: 'Olen valmis',
					callback: () => { closeChat() }
				}
			],
			type: chatTypes.TYPE_SELECT
		})
	}


	let script = [
			{
				text: `Reseptorin kautta voit uusia reseptisi riippumatta siitä, onko se määrätty sinulle julkisessa vai yksityisessä terveydenhuollossa. 
				Lisäksi voit hakea täysin uutta reseptiä muutamiin vaivoihin, joihin et ole saanut aiemmin apua. <br /><br />

				Jos halua uusia aiemmin saamasi reseptin, valitse <b>Vanhan reseptin uusinta</b>. Jos olet hakemassa kokonaan uutta reseptiä lääkäriltämme, 
				valitse <b>Uusi resepti</b>`,
				type: chatTypes.TYPE_QUESTION
			},
			{
				options: [{

				// UUSINTAHELVETTI

					text: 'Vanhan reseptin uusinta',
					callback: () => {

					    history.pop()
					    history.push({ text: 'Haluan uusia reseptin', type: chatTypes.TYPE_ANSWER })

					    history.push({
					    	text: 'Selvä, haluat siis hakea uusintaa vanhaan reseptiisi. Mistä lääkkeestä on kysymys?',
					    	type: chatTypes.TYPE_QUESTION,
					    })

					    history.push({
					    	text: '',
					    	type: chatTypes.TYPE_INPUT_AUTO_COMPLETE,
					    	select: (p) => {

					    		const product = p
					    		let reason = ''

					    		history.pop()
					    		history.push({
					    			text: product.displayName,
					    			type: chatTypes.TYPE_ANSWER
					    		})

								history.push({
									text: 'Haluaisit siis uusia<br/><br/><b>' + product.displayName + '</b> -reseptisi.' +
										'<br/><br/>Resepti uusitaan alkuperäisen reseptin pakkauskoolla.<br/><br/>' +
         								'Kerro peruste reseptin uusimiselle tai anna muuta lisätietoa lääkärille.',
									type: chatTypes.TYPE_QUESTION
								})

        						history.push({
        							text: 'Peruste uusinnalle ja mahdolliset lisätiedot',
        							type: chatTypes.TYPE_INPUT,
        							callback: (r) => {

        								reason = r

        								history.pop()
        								history.push({
        									text: reason,
        									type: chatTypes.TYPE_ANSWER
        								})

        								history.push({
        									text: 'Kiitos ' + user.firstName + '. Uusitaanko resepti:<br/><br/>' +
      											'<b>' + product.displayName + '</b><br/><br/>' +
      											'Peruste:<br/><b>' + reason + '</b>',
      										type: chatTypes.TYPE_QUESTION
        								})

        								history.push({
        									options: [{
        										text: 'Peruuta',
        										callback: () => {
        											history.pop()
        											history.push({
        												text: 'Peru reseptin uusinta',
        												type: chatTypes.TYPE_ANSWER
        											})        											
        											history.push({
        												text: 'Reseptin uusinta on peruttu. Haluatko uusia muita reseptejä vai oletko valmis?',
        												type: chatTypes.TYPE_QUESTION
        											})
        											newRoundOptions()
        										}
        									},{
												text: 'Kyllä, uusitaan resepti',
												id: 'btn-uusitaan-resepti',
        										callback: () => {
        											
        											chooseProduct(
        												product.productId,
        												apiConstants.CART_PURCHASE_TYPE_RENEWAL,
        												reason
    												)

        											history.pop()
        											history.push({
        												text: 'Kyllä, uusitaan resepti',
        												type: chatTypes.TYPE_ANSWER
        											})

													history.push({
														text: 'Reseptin uusintapyyntö on luotu. Haluatko hakea tai uusia muita reseptejä vai oletko valmis?',
														type: chatTypes.TYPE_QUESTION
													})
													
													newRoundOptions()
        										}
        									}],
        									type: chatTypes.TYPE_SELECT
        								})

        							}
        						})
					    		
					    	}
					    })

					}

				// /UUSINTAHELVETTI

				}, {

				// UUDEN RESEPTIN TILAUS

					text: 'Uusi resepti',
					callback: () => {

						history.pop()
						history.push({ text: 'Haluan uusia reseptin', type: chatTypes.TYPE_ANSWER })
						
						history.push({
							text: 'Selvä, haluat siis hakea uutta reseptiä. Mihin vaivaan?',
							type: chatTypes.QUESTION
						})

						const options = CHAT_SCRIPTS.filter(s => s.sex === SEX_BOTH || s.sex === user.sex).map(s => ({
								text: s.displayName,
								callback: () => {

									history.pop()
									history.push({ text: s.displayName, type: chatTypes.TYPE_ANSWER })
									
									manuscriptedChat(
										s,
										history,
										(canOrder, recipeChat) => {

											if (canOrder) {

												history.push({
													options: [{
														text: 'Peruuta',
														callback: () => {
		        											history.pop()
		        											history.push({
		        												text: 'Peru reseptin hakeminen',
		        												type: chatTypes.TYPE_ANSWER
		        											})        											
		        											history.push({
		        												text: 'Reseptin haku on peruttu. Haluatko hakea tai uusia muita reseptejä vai oletko valmis?',
		        												type: chatTypes.TYPE_QUESTION
		        											})
		        											newRoundOptions()
														}
													},{
														text: 'Kyllä, haetaan reseptiä',
														id: 'btn-haetaan-reseptia',
														callback: () => {
		
															choosePrescription(s.displayName, recipeChat)
															
															history.pop()
															history.push({ text: 'Kyllä, haetaan reseptiä', type: chatTypes.TYPE_ANSWER })
															
															history.push({
																text: 'Reseptipyyntö on luotu. Haluatko hakea tai uusia muita reseptejä vai oletko valmis?',
																type: chatTypes.TYPE_QUESTION
															})
															
															newRoundOptions()
														}
													}
													],
													type: chatTypes.TYPE_SELECT
												})
											} else {
												newRoundOptions()
											}
										}
									).start()
								}
							}))

						history.push({
							options: options,
							type: chatTypes.TYPE_SELECT
						})

					}

				// /UUDEN RESEPTIN TILAUS
				}
			],
			type: chatTypes.TYPE_SELECT
		}
	]

	return script
}

export function chatEngine(onChange, user, choosePrescription, chooseProduct, closeChat) {

	let history = new Proxy([], {
			set: (obj, prop, value) => {

				obj[prop] = value
				onChange(obj)

				return true
			}
		})

	history = getChat(history, user, choosePrescription, chooseProduct, closeChat)

	return history
}

function manuscriptedChat(chatObj, history, ready) {

	return {

		history: history,
		recipeChat: [],

		script: require('./manuscripts/' + chatObj.script).chat,
		current: 1,

		next() {
			return this.script[this.current]
		},

		answer(a) {
			
			this.history.pop()

			const answer = { text: a.text, type: chatTypes.TYPE_ANSWER }

			this.history.push(answer)
			this.recipeChat.push(answer)

			this.current = a.next
			this.ask()
		},

		ask() {

			let item = this.script[this.current]
			
			const question = Object.assign(
				{ text: item.question, type: chatTypes.TYPE_QUESTION }, 
				item.discardOnSave &&  { discardOnSave: item.discardOnSave });

			this.history.push(question)
			this.recipeChat.push(question)

			if (item.answers) {
				this.history.push(this.chatItem(item))
			} else {
				ready(item.canOrder, this.recipeChat)
			}

		},

		start() {
			this.ask()
			return this
		},

		// ================

		chatItem(item) {
			switch (item.type) {
				case 'oneOf':
					return this.selection(item)
				case 'multiChoice':
					return this.multiChoice(item)
				case 'textInput':
					return this.input(item)
				default:
					throw new Error("Unknown chat item: " + item.type);
			}
		},

		input(item) {
			// return this.chatItem(text, chatTypes.TYPE_INPUT)
			return {
				text: item.placeholder,
				callback: (answer) => {
					this.answer({
						...item,
						text: answer
					})
				},
				type: chatTypes.TYPE_INPUT
			}
		},

		multiChoice(item) {
			return {
				answers: item.answers,
				callback: (answer) => {
	              this.answer({
	              	...item,
	              	text: answer.join(', ')
	              })
				},
				type: chatTypes.TYPE_MULTICHOICE
			}
		},

		selection(item) {

			return {
				options: item.answers.map(a => {

					let cb = a.callback ? a.callback : () => { this.answer(a) }

					return {
						text: a.text,
						callback: cb
					}
				}),
				type: chatTypes.TYPE_SELECT
			}		
		}

	}
}
