import React, { Component } from 'react'
import User from './User'

export default class UserList extends Component {

  render() {

    //const { prescriptions, chosenPrescription, choosePrescription, cancelDecision } = this.props

    const { chosenUser, chooseUser } = this.props
    const users = this.props.pharmacyusers
    //const users = [{firstName: "testi"}];
    //console.log(users)


    return (
      <div>
        
        { 
          //console.log("userlist",users) 
          
          users.pharmacyusers.map((user, i) => (
            <User   
                key={i}
                firstname={user.firstName}
                lastname={user.lastName}
                userId={user.id}
                active={chosenUser === user}
                onClick={chooseUser}
            />
            ))
            
        }

        { /*
          prescriptions.map((p, i) => (
            <User
              key={i}
              active={chosenPrescription === p}
              prescriptionId={p.id}
              type={p.type}
              name={p.type === 'prescription' ? p.medicalProblem : p.displayName}
              onClick={choosePrescription}
            />
          ))
          */
        }
      </div>
    )
  }
}
