import React, { Component } from 'react'

class CookiesText extends Component {

	render() {
		return (
        <div className="modal-text">
          <h2>Evästeet</h2>
          <p>Tämä sivusto käyttää evästeitä. Eväste (”cookie”) on pieni tekstitiedosto, jonka internetselain tallentaa laitteellesi. Evästeiden avulla saadaan tieto, vierailun ajankohdasta päivätasolla, käytetyistä sivuista, selaintyypistä, laitteestasi, mistä verkko-osoitteesta, miltä palvelimelta ja mistä verkkotunnuksesta olet tullut sivustollemme. Edellä mainittuja tietoja ei voida palauttaa sinut yksilöivään ja identifioivaan muotoon eivätkä ne vahingoita laitettasi tai tiedostojasi. Käytämme evästeitä analysoidaksemme verkkosivujemme liikennettä ja teknistä toimivuutta, kehittääksemme verkkosivujamme ja liiketoimintaamme, personoidaksemme sivuja sekä raportointi tarkoituksiin.</p>
          
          <p>Käyttämämme evästeet ovat pääasiallisesti istuntokohtaisia ja olemassa ainoastaan kunnes suljet selaimesi. Käytämme myös pysyviä evästeitä, jotka säilyvät pidemmän ennalta määrätyn ajan. 
          Käyttämämme evästeet voidaan luokitella:</p>
          
          <ul>
          <li>Välttämättömiin evästeisiin, jotka ovat tarpeen sivustomme teknisen toiminnan ja käytön vuoksi. Nämä evästeet eivät kerää käyttäjästä tietoa, jota voitaisiin hyödyntää markkinoinnissa 
          tai muistamaan käyttäjän valitsemia sivustoja.</li>
          <li>Suorituskyvyn mittaamiseen perustuviin evästeisiin, jotka keräävät tietoa siitä, miten käyttäjät käyttävät verkkosivujamme (esim. eniten käytetyt sivut sekä mahdolliset virheviestit). 
          Nämä evästeet eivät kerää käyttäjistä tunnistettavia tietoja, vaan ne ovat anonyymejä ja niitä käytetään ainoastaan parantamaan nettisivujen toimivuutta.</li>
          </ul>

          <p>Käytämme suorituskyvyn mittaamiseen Google Analytics -ohjelmaa, joka on Google, Inc. -yhtiön tarjoama palvelu. Evästeet, jotka liittyvät vierailuusi sivuillamme ovat Google, Inc.:n 
          tai sen lukuun toimivan osapuolen generoimia tietueita ja evästeiden synnyttämät sinuun liittyvät tiedot ovat siten lisäksemme Google, Inc.:n tai sen lukuun toimivan osapuolen käytössä 
          ilman erillisiä tiedon luovutustoimenpiteitä. Google, Inc.:n tai sen lukuun toimivan osapuolen palvelimet ja evästeiden ja jäljitteiden käsittelyyn liittyvät toiminnot sijaitsevat USA:ssa, 
          ja voit tutustua Google Analyticsin tietosuojakäytäntöihin osoitteessa <a href="https://support.google.com/analytics/answer/2838718?hl=fi" 
          target="_blank" rel="noopener noreferrer">täällä</a> ja <a href="https://support.google.com/analytics/answer/181881?hl=fi&ref_topic=2919631" target="_blank" 
          rel="noopener noreferrer">tästä</a> löydät ohjeet Google Analyticsin käytön estämiseen.</p>
          <ul>
          <li>Toiminnallisuuteen perustuviin evästeisiin, joiden avulla verkkosivumme muistavat käyttäjän sivusto koskevat valinnat sekä tarjoavat parempia ja yksilöllisempiä ominaisuuksia. 
          Voimme tallentaa myös verkkokaavakkeisiin täyttämäsi tiedot, jotta ne eivät katoa kun seuraavalla kerralla tulet sivullemme jatkamaan kaavakkeen täyttöä.</li>
          </ul>
          <p>Jatkamalla sivujen käyttöä hyväksyt evästeiden käyttämisen edellä kerrotulla tavalla. Hyväksymällä kävijäseurantatietojesi (evästeet) käsittelemisen edellä kuvatun mukaisesti 
          hyväksyt myös tietojen tallentamisen ja käsittelyn edellä mainittujen toimijoiden tai näiden lukuun toimivan tahon toimesta ja tietojen luovutuksen meidän ja edellä 
          mainittujen tahojen välillä. Voit estää evästeiden käytön ja poistaa olemassa olevat evästeet selaimesi asetuksista, mutta tällöin emme voi taata sivujemme toimivuutta 
          laitteellasi. Evästeiden käytön estäminen saattaa estää tai haitata sivuston käyttämistä.</p>
          
        </div>
		)
	}
}

export default CookiesText