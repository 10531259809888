/* eslint-disable no-unused-expressions */
import React, { Component } from 'react'

import { connect } from 'react-redux'
import { selectors, actions } from '../ducks/session'
import { PharmacyNavigation } from '../../common/NavigationBar';
import { List } from './List'
import { actions as prodActions } from '../../ducks/product';
import OrderPrescriptionFlow from './OrderPrescriptionFlow';
import PrescriptionListSelect from './PrescriptionListSelect';
import NotificationBar from './NotificationBar';

class PharmacyPrescriptions extends Component {

	constructor(props) {
		super(props)
		this.state = {
            showChat : false,
            //showNotification: true,
            //notification_published: false,
            //message_published: false
		}
    }
    
    componentDidMount() {
        this.props.fetchPharmacyUsers();
        this.props.fetchPharmacyOrders();
        this.props.getMessageStatus();
        this.props.getMessage();
        
        const interval = setInterval(() => {
          this.props.fetchPharmacyOrders();
          this.props.getMessageStatus();
          this.props.getMessage();
        }, 10000)

        this.setState({ 
            interval: interval,
            showNotification: ''
         })

    }
    componentWillReceiveProps() {

    }

    componentWillUnmount = () => {
        clearInterval(this.state.interval)
    }

    renderList() {
        const list = this.props.orderlist
        
        if (Object.keys(list).length < 1) return ''
        return <List key="0" list={list} removeItem={this.props.removeItemFromList} />
    }

    handleNotification = () => {
        if( this.props.messageStatus) {
            this.props.uncheckMessage()
        } else {
            this.dismissNotification()
        }
    }
    dismissNotification = () => {
       this.props.checkMessage()
    }

    renderContent = () => {
        return [this.renderList()]
    }

    checkAdmin = () => {
        if(this.props.users.length > 0) {
            let found = this.props.users.find(user => user.username === this.props.pharmacist.username)
            if (found.userLevel === 'admin') {
                return true;
            }
        }
        return false;
    }

    render() {
	    return (
            <div className="pharmacy">
                <PharmacyNavigation
                    loggedIn={true}
                    apteekki={this.props.currentOffice}
                    navMessage={this.props.currentMessage}
                    handleNotification={this.handleNotification}
                    checkStatus={this.props.messageStatus}
                    isAdmin={this.checkAdmin()}
                />

                <NotificationBar
                    message={this.props.currentMessage}
                    dismissNotification={this.dismissNotification}
                    messageStatus={this.props.messageStatus}
                />

                
                <div className="container columns">
                    <div className="chat-column">
                        <h1>Reseptin tilaus</h1>
                        <OrderPrescriptionFlow />
                    </div>
                    <div className="list-column">
                        <PrescriptionListSelect />
                        <div className="list">
                            { this.renderContent() }
                        </div>
                    </div>
                </div>
            </div>
	    );
    }
}


export default connect(
	state => ({
        currentOffice: selectors.getCurrentOffice(state),
        patient: state.pharmacist.session.patientInfo,
        products: selectors.getProducts(state),
        orderlist: selectors.getOrderHistory(state),
        pharmacist: state.pharmacist.session.pharmacist,
        office: state.pharmacist.session.currentOffice,
        currentMessage: selectors.getCurrentMessage(state),
        messageStatus: selectors.getMessageStatus(state),
        users: state.pharmacist.session.currentUsers.pharmacyUsers
	}),
	{
      ...actions,
      ...prodActions
	}
)(PharmacyPrescriptions)