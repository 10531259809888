import React, { Component } from 'react'

class TosText extends Component {

	render() {
		return (
        <div className="tos-text">
          <h2>Sivuston käyttöehdot</h2>
          <p>Näitä käyttöehtoja sovelletaan reseptori.fi verkkosivuun ja sen mahdollisiin alasivuihin (jäljempänä "<strong>Sivut</strong>" tai "<strong>Sivusto</strong>") ja 
          käyttämällä sivuja sinun katsotaan tutustuneen näihin käyttöehtoihin ja sitoutuneen noudattamaan näitä ehtoja. Terveyspalvelu Reseptori Oy (jäljempänä "<strong>Me</strong>") 
          pidättää oikeuden muuttaa näitä ehtoja milloin tahansa ilman ennakkoilmoitusta. Sivuilla voi olla myös erityisiä sivu- tai palvelukohtaisia ehtoja tai kolmannen osapuolen ehtoja, joihin sinun tulee tutustua ja sitoutua noudattamaan käyttäessäsi sivuja ja sanotut erityiset ehdot saavat etusijan suhteessa näihin ehtoihin sanotun vaikuttamatta näiden ehtoja voimassaoloon ja vaikuttavuuteen sinun ja meidän välisessä suhteessa. </p>

          <p>Meillä on oikeus mistä syystä tahansa muuttaa sivujen käyttöehtoja, ulkoasua, sisältöä, saatavuutta sekä sivuilla toimitettavia palveluita tai muita sivujen ominaisuuksia taikka lakkauttaa sivut sekä oikeus keskeyttää sivuston tarjoaminen huolto- ja päivitystoimenpiteiden ajaksi milloin tahansa ilman etukäteistä ilmoitusta.</p>
          
          <p><strong>Vastuunrajoitus</strong></p>
          <p>Sivut sisältävät ainoastaan yleisluonteista ja oikeudellisesti sitomatonta informaatiota. Sivuilla julkaistava informaatio on tuotettu ainoastaan tiedonantotarkoituksessa sitoumuksetta eivätkä toimi tarjouksena, toimintakehotuksena tai asiantuntijan neuvonantona, ellei erikseen ja nimenomaisesti ole toisin mainittu. Kaikki sivujen sisältämät tiedot ja kuvaukset koskien <strong>tuotteita</strong> (<em>palvelut ja tavarat</em>), joita me tai kolmas osapuoli tarjoaa ovat yleisluonteisia, ellei toisin ole nimenomaisesti erikseen todettu. Tarkemmat ja sitovat tiedot tuotteista saat meiltä tai asianomaiselta kolmannelta osapuolelta asiakas- ja sopimussuhteen perustamisen yhteydessä. </p>

          <p>Me emme takaa, että sivut toimivat keskeytyksettömästi tai virheettömästi. Me emme vastaa mistään vahingoista, välillisistä emmekä välittömistä, jotka aiheutuvat näiden sivujen tai sivuilla olevien sähköisten palveluiden, dokumenttien tai linkkien käytöstä tai käytönestymisestä tai näihin liittyvästä vahingosta. </p>
          
          <p>Emme vastaa sivulla olevasta tai sivuilla olevan linkin kautta löytyvästä kolmannen osapuolen tuottamasta tai julkaisemasta materiaalista, sen immateriaalioikeuksista, luotettavuudesta, virheettömyydestä, saasteettomuudesta tai mistään muusta vastaavasta seikasta, joka aiheuttaa tai voi aiheuttaa sinulle tai muulle taholle vahinkoa suoraan tai välillisesti eikä sanotun materiaalin tai linkin esittäminen tarkoita sitä, että suosittelisimme tai kehottaisimme käyttämään kolmatta osapuolta tai tämän tuotteita tai palveluita tai toimisimme tämän puolesta tai lukuun ellei toisin ole nimenomaisesti mainittu.</p>
          
          <p>Tässä mainitut vastuunrajoitukset eivät koske pakottavaan lainsäädäntöön perustuvia vastuitamme, joiden osalta vastaamme mahdollisesta vahingosta voimassaolevan pakottavan lainsäädännön mukaisesti joka osoittaa myös vastuumme enimmäismäärän.  </p>
          
          
          <p><strong>Sivujen käyttö ja sivujen kautta annettavat tiedot</strong></p>
          <p>Sinun tulee kaikin kohtuullisin keinoin varmistua siitä, että sivut eivät voi saastua tai ylikuormittua viruksen, laitteeseesi tai tietojärjestelmäympäristöösi kohdistuvan tietomurron tai muun vastaavan syyn johdosta ja vastaat edellä mainitun mukaisesta vahingosta, mikäli vahingon estäminen tai rajoittaminen olisi ollut kohtuullisten vaikuttamismahdollisuuksiesi rajoissa.</p>
          <p>Vastaat sivujen kautta antamiesi tietojen oikeellisuudesta ja perille saapumisesta ja tiedostat, että avoimen verkon kautta toimitetun sähköposti- tai muun viestin luottamuksellisuutta ei voida taata. Vastaat siitä, ettet lähetä sivujen kautta mitään lain vastaista tai epäasiallista aineistoa ja mikäli toimitat kolmanteen osapuoleen liittyvää tietoa, kuten henkilötietoja tai immateriaalioikeuksien alaista aineistoa, vastaat siitä, että sinulla on asiaa koskeva asianmukainen kirjallinen suostumus ja vastaat siitä vahingosta, joka meille aiheutuu mahdollisen suostumuksen puuttumisesta, virheellisyydestä tai riittämättömyydestä. </p>



          <p><strong>Henkilötiedot ja evästeet</strong></p>
          <p>Henkilötietojen suojaaminen on ensiarvoisen tärkeää ja suojataksemme sinun ja meidän välisen liikenteen, käytämme SSL-yhteysmenettelyä, jolla vierailusi sivustollamme ja sen yhteydessä syntyvät tiedot voidaan suojata joutumiselta ulkopuolisten tietoon.  </p>
          <p>Henkilötietoja ovat kaikki tiedot, joiden avulla sinut voidaan tunnistaa suoraan tai välillisesti. Käsittelemme henkilötietoja voimassaolevan lainsäädännön mukaisesti ja vastaamme henkilötietojen käsittelystä voimassaolevan sääntelyn mukaisesti. Keräämme henkilötietoja sivustolla olevien lomakkeiden kautta. </p>
          <p>Sivusto käyttää evästeitä. Eväste (”cookie”) on pieni tekstitiedosto, jonka internetselain tallentaa laitteellesi. Evästeiden avulla voidaan saada tieto IP-osoitteestasi, vierailun ajankohdasta, käytetyistä sivuista, selaintyypistä, laitteestasi, mistä verkko-osoitteesta, miltä palvelimelta ja mistä verkkotunnuksesta olet tullut sivustollemme. Edellä mainittuja tietoja ei voida palauttaa sinut yksilöivään ja identifioivaan muotoon eivätkä ne vahingoita laitettasi tai tiedostojasi.  Käytämme evästeitä analysoidaksemme verkkosivujemme liikennettä ja teknistä toimivuutta, kehittääksemme verkkosivujamme ja liiketoimintaamme, personoidaksemme sivuja sekä raportointi tarkoituksiin.  Voit estää evästeiden käytön ja poistaa olemassa olevat evästeet selaimesi asetuksista, mutta tällöin emme voi taata sivujemme toimivuutta laitteellasi. Evästeiden käytön estäminen saattaa estää tai haitata sivuston käyttämistä. </p>
          



          <p><strong>Immateriaalioikeudet</strong></p>
          <p>Kaikki tällä sivustolla esitetty aineisto, mukaan lukien mutta rajoittumatta logot ja toiminimet sekä sivuston visuaalinen ilme, on Terveyspalvelu Reseptori Oy:n ja/tai sen yhteistyökumppanina toimivien lisenssinhaltijoiden omaisuutta. Kaikki sivuilla oleva aineisto on suojattu tekijänoikeuksien, tavaramerkkioikeuksien ja toiminimioikeuksien sekä muiden yksinoikeuksien ja näitä koskevan lainsäädännön perusteella.</p>
          <p>Sivun tai sen osien julkaiseminen, kopiointi, välittäminen tai jakaminen on kielletty ilman Terveyspalvelu Reseptori Oy:n etukäteen antamaa kirjallista lupaa. Sitoudut olemaan tallentamatta, julkaisematta, kopioimatta, jakamatta, välittämättä tai muuttamatta tätä sivustoa tai sen sisältämää materiaalia sekä olemaan luomatta sen avulla jatkokehitettyjä teoksia ilman Terveyspalvelu Reseptori Oy:n etukäteistä kirjallista suostumusta. Sinulla on kuitenkin oikeus käyttää, tulostaa, välittää ja tallentaa väliaikaisesti sivustolla olevaa materiaalia omaa henkilökohtaista, ei-kaupallista, käyttötarkoitustasi varten. </p>
          <p>Terveyspalvelu Reseptori Oy tai sen yhteistyökumppanina toimiva lisenssinhaltija säilyttää täydet immateriaali- ja omistusoikeudet kaikkeen sivuilta kopioimaasi, lataamaasi tai tulostamaasi aineistoon. Muunlainen linkitys sivuille kuin linkitys pääsivulle www.reseptori.fi on kiellettyä ilman Terveyspalvelu Reseptori Oy:n etukäteistä kirjallista suostumusta. </p>
          <p>Voit osoittaa kaikki yllä mainittuihin immateriaalioikeuksien käyttöön liittyvät suostumuspyyntösi sähköpostina osoitteeseen info@reseptori.fi</p>
          

          <p><strong>Maksut </strong></p>
          <p>Sivujen käyttö on vastikkeellista. Sivujen kautta tuotettavien palveluiden tai sen osien hinnat ja hinnanmääräytymisperusteet ilmoitetaan kyseisten palveluiden yhteydessä Sivustolla. Terveyspalvelu Reseptori Oy varaa oikeuden muuttaa ja päivittää palveluiden hinnoittelua. Sivuston käyttäjänä sinun on suoritettava kulloinkin voimassaolevan palveluhinnaston mukaiset tai muuten sinulle ilmoitetut maksut ja muut suoritteet saadaksesi palvelua. Hyväksytyt maksuvälineet on ilmoitettu maksullisen palvelun yhteydessä.</p>
          <p>Sinulla on Sivuston käyttäjänä yksinomainen vastuu palvelun käytöstä sinulle aiheutuneista kustannuksista. Suoritettua maksua ei palauteta.</p>


          <p><strong>Sovellettava laki</strong></p>
          <p>Kaikkiin sivujen käytöstä mahdollisesti syntyviin erimielisyyksiin sovelletaan Suomen lakia.</p>



          <p><strong>Suostumukset</strong></p>
          <p>Hyväksymällä käyttöehdot ja rekisteröitymällä palveluun, annan suostumukseni siihen että</p>
          <ul>
          <li>Terveyspalvelu Reseptori Oy tai Terveyspalvelu Reseptori Oy:ön sopimussuhteessa toimiva taho kuten itsenäinen ammatinharjoittaja tai toinen terveyspalveluiden tuottaja käsittelee henkilötietojani tuottaakseen palveluita minulle</li>
          <li>Terveyspalvelu Reseptori Oy tai Terveyspalvelu Reseptori Oy:ön sopimussuhteessa toimiva taho kuten itsenäinen ammatinharjoittaja tai toinen terveyspalveluiden tuottaja voi pyytää ja saada minua koskevia henkilötietoja kolmannelta osapuolelta tuottaakseen palveluita minulle</li>
          </ul>
          <p>Mikäli sinulla tulee kysyttävää henkilötietojesi käsittelyyn liittyen voit toimittaa asiaa koskevan sähköpostin osoitteeseen info@reseptori.fi.</p>
          <p>Voit peruttaa edellä mainitut suostumuksesi milloin tahansa toimittamalla asiaa koskevan sähköpostin osoitteeseen info@reseptori.fi.</p>
        </div>
		)
	}
}

export default TosText