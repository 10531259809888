import React, { Component } from 'react'
import { connect } from 'react-redux'
import { actions } from '../ducks/session'
import { selectors } from '../ducks/session'
import PharmacyChat from './PharmacyChat'
import PatientInfoContent from './PatientInfoContent';
import Modal from 'react-responsive-modal/lib/css';

class OrderPrescriptionFlow extends Component {

    constructor(props) {
        super(props);
        this.backToFirstView = this.backToFirstView.bind(this);
        this.ssnReady = this.ssnReady.bind(this);
        this.additionalDataReady = this.additionalDataReady.bind(this);
        this.addItemToCart = this.addItemToCart.bind(this);
        this.sendToDoctor = this.sendToDoctor.bind(this);

        this.flowViews = [
            <PatientSsnView onNext={this.ssnReady}/>,
            <PatientAdditionalInfoView onNext={this.additionalDataReady} abort={this.backToFirstView}/>,
            <PharmacyChat
                abort={this.backToFirstView}
                closeChat={this.backToFirstView}
                chooseProduct={this.addItemToCart}
                sendToDoctor={this.sendToDoctor}
                setDeliveryOption={props.setDeliveryOption}
            />
        ]
        this.state = {
            activeView: 0,
            hetu: '',
            firstname: '',
            lastname: '',
        }
    }

    sendToDoctor = () => {
      const { sendCart } = this.props;
      sendCart()
      this.setState({ activeView: 0 });
    }

    addItemToCart(productId, type, reason) {
      const { currentOffice, pharmacist, chooseProduct } = this.props;
      const pharmacyData = {
          pharmacy: currentOffice.pharmacyName,
          pharmacyPhone: currentOffice.phone,
          pharmacist: `${pharmacist.firstName} ${pharmacist.lastName}`,
      }
      chooseProduct(productId, type, reason, pharmacyData);
    }

    ssnReady(ssn) {
        this.setState({ hetu: ssn, activeView: this.state.activeView + 1 });
    }

    additionalDataReady(firstname, lastname) {
      const { savePatientInfo } = this.props;
      savePatientInfo(firstname, lastname, this.state.hetu);
      this.setState({ activeView: this.state.activeView + 1, firstname, lastname });
    }

    backToFirstView() {
      const { deletePharmacyCart } = this.props;
      deletePharmacyCart();
      this.setState({ activeView: 0 });
    }

    render() {
        return (
            <div className="chat">
                { 
                     this.flowViews[this.state.activeView]
                }
            </div>
        );
    }
}

class PatientSsnView extends Component {

    constructor(props) {
		super(props)
		this.state = {
            hetu: ''
		}
    }

    componentDidMount() {
        this.hetuField && this.hetuField.focus();
        this.barcodeListener = (e) => {
            if(e.keyCode === 13 && this.isSsnLongEnough() && this.isSsnInFinnishFormat()) {
                this.props.onNext(this.state.hetu)
            }
        }
        this.container.addEventListener('keydown', this.barcodeListener);
    }

    componentWillUnmount() {
        this.container.removeEventListener('keydown', this.barcodeListener);
    }

    isSsnLongEnough() {
        return this.state.hetu.length === 11;
    }

    isSsnInFinnishFormat() {
        return this.state.hetu.match(/^[0-3][\d][0-1][0-9][0-9]{2}[+\-A][\d]{3}[\dA-Z]$/);
    }

    hetuStatusIcon() {
        if(!this.isSsnLongEnough()) {
            return null;
        }
        return (this.isSsnLongEnough() && this.isSsnInFinnishFormat()) 
            ? <div className="hetu-ok"></div> 
            : <div className="hetu-error"></div> 
    }

    onChange(e) {
        this.setState({ hetu: e.target.value.toUpperCase() })
    }

    render() {
        return (
            <div className="register-user-container" ref={ ele => this.container = ele }>
                <p className="small-font">Luo reseptin uusintapyyntö syöttämällä asiakkaan tiedot käsin tai lukemalla viivakoodin.</p>
                <div className="input-container">
                    <div className="hetu-label-container">
                        <label htmlFor="hetu">Hetu</label>
                        { this.hetuStatusIcon() }
                    </div>
                    <input id="hetu" type="text"
                        value={this.state.hetu}
                        maxLength = {11}
                        placeholder=""
                        onChange={ e => this.onChange(e) } 
                        ref = {element => this.hetuField = element }/>
                        { 
                            (this.isSsnLongEnough() && !this.isSsnInFinnishFormat()) && <div className="error-field">Tarkista henkilöturvatunnus</div>
                        }
                </div>
                <button onClick={e => this.props.onNext(this.state.hetu)} className="btn btn--green" type="button" 
                    disabled={ (!this.isSsnLongEnough() || !this.isSsnInFinnishFormat()) }>Seuraava</button>
            </div>
        );
    }
}

class PatientAdditionalInfoView extends Component {

    constructor(props) {
		super(props)
		this.state = {
			firstname: '',
            lastname: '',
            contentAccepted: true,
            patientInfoWindowOpen: false,
		}
    }

    isValid() {
        return this.state.firstname.length === 0 
            || this.state.lastname.length === 0
            || !this.state.contentAccepted;
    }

    togglePatientInfoWindow() {
        this.setState({ patientInfoWindowOpen: !this.state.patientInfoWindowOpen });
    }

    onChange(e) {
        this.setState({ contentAccepted: !this.state.contentAccepted })
    }

    render() {
        return ( 
            <div className="register-user-wrapper">
                <button className="chat-cancel" onClick={this.props.abort}><i className="fas fa-chevron-left"></i> Keskeytä</button>
                <div className="register-user-container">
                    <p>Syötä potilaan etunimi ja sukunimi sekä käy potilasinfo asiakkaan kanssa läpi.</p>

                    <div className="input-container">
                        <label htmlFor="firstname">Etunimi</label>
                        <input id="firstname" type="text"
                            value={this.state.firstname}
                            placeholder=""
                            onChange={ e => this.setState({ firstname: e.target.value })}  
                        />

                        <label htmlFor="lastname">Sukunimi</label>
                        <input id="lastname" type="text" 
                            value={this.state.lastname}
                            placeholder=""
                            onChange={ e => this.setState({ lastname: e.target.value })}   />

                        <div className="checkbox-container">
                            <input
                                id="acceptContentInput"
                                className="checkbox"
                                type="checkbox"
                                checked={this.state.contentAccepted}
                                onChange={ e => this.onChange(e) } 
                            />
                            <span className="checkbox__label" onClick={ e => this.onChange(e) } />
                            <button type="button" className="link-button" onClick={ e => this.togglePatientInfoWindow(e)}>Potilasinfo</button><span>&nbsp;annettu</span>
                        </div>
                    </div>

                    <button onClick={e => this.props.onNext(this.state.firstname, this.state.lastname)} 
                        className="btn btn--green" type="button" disabled={this.isValid()}>Hae reseptiä</button>
                    { this.state.patientInfoWindowOpen && <PatientInfoModal closeModal={ e => this.togglePatientInfoWindow()} /> }
                </div>
            </div>
        );
    }
}

const PatientInfoModal = (props) => (
  <Modal
    little
    classNames={{ modal: "patient-info" }}
    open={true}
    onClose={(e) => props.closeModal()}
    center
>
    <PatientInfoContent />
</Modal>
);

const mapDispatchToProps = dispatch => ({
  sendCart: () => dispatch(actions.sendCart()),
  chooseProduct: (productId, type, reason, pharmacyData) =>
    dispatch(actions.chooseProduct(productId, type, reason, pharmacyData)),
  savePatientInfo: (firstname, lastname, hetu) =>
    dispatch(actions.savePatientInfo(firstname, lastname, hetu)),
  deletePharmacyCart: () => dispatch(actions.deletePharmacyCart()),
  setDeliveryOption: (option) => dispatch(actions.setDeliveryOption(option))
})

export default connect(
	state => ({
        currentOffice: selectors.getCurrentOffice(state),
        pharmacist: state.pharmacist.session.pharmacist,
        fetching: state.ui.fetching,
	}),
	mapDispatchToProps
)(OrderPrescriptionFlow)