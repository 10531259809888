import React, { Component } from 'react'
import Navbar from './Navbar.js'
import NavigationBar from '../common/NavigationBar'
import NewChatComponent from '../chat/NewChatComponent'
import { ListCart, ListWaiting, ListRecentlyDecided, ListDecided } from './ListPrescriptions'

import { selectors, actions } from '../ducks/session'
import { actions as orderActions } from '../ducks/product'
import { CUSTOMER_POLL_DECISION_INTERVAL, tagManager } from '../common/constants'

import { connect } from 'react-redux'

export class ListHello extends Component {

  constructor(props) {
    super(props)
    this.state = { 
      showChat: true,
      width: window.innerWidth, 
      height: window.innerHeight, 
    }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount = () => {

    const interval = setInterval(() => {
      this.props.fetchOutstandingOrders()
    }, CUSTOMER_POLL_DECISION_INTERVAL)

    this.setState({ interval: interval })

    window.addEventListener('resize', this.updateWindowDimensions)
    this.updateWindowDimensions()
    if(this.state.width < 1024) this.setState({showChat : false})
  }

  componentWillUnmount = () => {
    clearInterval(this.state.interval)
    window.removeEventListener('resize', this.updateWindowDimensions)
  } 

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
    //console.log("width:"+this.state.width)
  }

  chatHandler = () => {
    this.setState({ showChat : !this.state.showChat })
  }

  renderEmptyList() {
    return (
        <div className="list-cart">
          <div className="list-header-container">
            <h1>Reseptit</h1>
            <p className="list-empty">
              Et ole vielä asioinut Reseptorissa, joten reseptihistoriasi on tyhjä.
            </p>
            <p className="list-empty">
              Asioituasi Reseptorissa tulevat reseptisi tähän muistiin seuraavia käyttökertoja helpottamaan.
            </p>
          </div>
          <div className="guide">Aloita reseptien haku täältä</div>
        </div>
      )
  }

  renderCart() {
    if (this.props.cart.items.length < 1) return ''
    return <ListCart key="0" cart={this.props.cart} removeItem={this.props.removeItemFromCart} />
  }

  renderWaitingPrescriptions() {

    const prescriptions = this.props.waitingPrescriptions
    if (prescriptions.length < 1) return ''

    return <ListWaiting key="1"  prescriptions={prescriptions} />    
  }

  renderRecentlyDecidedPrescriptions() {

    let prescriptions = this.props.recentlyDecided

    if (prescriptions.length < 1) return ''
    return <ListRecentlyDecided key="2" prescriptions={prescriptions} select={this.props.chooseItemFromHistory} />
  }

  renderOrderHistory() {
    let prescriptions = this.props.decided
    if (prescriptions.length < 1) return ''
    return <ListDecided key="3" prescriptions={prescriptions} select={this.props.chooseItemFromHistory} />
  }

  prescriptionstatus = () => {
    if (this.props.renderPageHeader)
      return (
        <prescriptionstatus 
          title={this.props.pageTitle}
          step="valueAmount"
          desc={this.props.desc}
        />
        )
  }

  noLists = () => {
    return !this.props.cart.items.length && !this.props.decided.length && !this.props.recentlyDecided.length
      && !this.props.waitingPrescriptions.length
  }

  renderContent = () => {
    if (this.noLists()) return this.renderEmptyList()
    else {
      return [
        this.renderCart(),
        this.renderRecentlyDecidedPrescriptions(),
        this.renderWaitingPrescriptions(),
        this.renderOrderHistory()
      ]
    }
  }

  render() {

    return (
      <div className="welcome">

        <NavigationBar
          openChat={this.chatHandler}
          chatButton={true}
          cartDirty={this.props.cart.items.length > 0}
          cartPrice={this.props.cart.totalPrice}
          cartPriceEuro={this.props.cart.priceEuro}
          payCart={this.props.payCart}
          loggedIn={true}
          email={this.props.user.email}
        />
      
        { this.prescriptionstatus() }
  
        <div className="container columns">
  
          <div className="list">
            { this.renderContent() }
          </div>
  
          <div className={this.state.showChat ? "chat-column active" : "chat-column" }>
            {this.state.showChat ? '' :
            (<button className="button__add" onClick={this.chatHandler}>
              <svg xmlns="http://www.w3.org/2000/svg" className="add_recipe" viewBox="0 0 27 27">
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1"><path d="M25.5,15H1.5a1.5,1.5,0,0,1,0-3h24a1.5,1.5,0,0,1,0,3Z"/><path d="M13.5,27A1.5,1.5,0,0,1,12,25.5V1.5a1.5,1.5,0,0,1,3,0v24A1.5,1.5,0,0,1,13.5,27Z"/></g>
                </g>
              </svg>
            </button>)}
          {
            this.state.showChat ?
            <NewChatComponent
              closeChat={this.chatHandler}
              onAbort={this.chatHandler}
            /> :
            null
          }
          </div>
        </div>

        <Navbar
          cartDirty={this.props.cart.items.length > 0}
          cartPrice={this.props.cart.totalPrice}
          cartPriceEuro={this.props.cart.priceEuro}
          openChat={this.chatHandler}
          payCart={this.props.payCart}
        />

        {tagManager}
      </div>
    )
  }
}

export default connect(
  state => {

    const { waitingPrescriptions, recentlyDecided, decided } = selectors.getOrderHistory(state)

    return {
      user: state.session.user,
      products: selectors.getUnchosenProducts(state),
      cart: selectors.getCart(state),
      waitingPrescriptions,
      recentlyDecided,
      decided
    }},
    {
      ...actions,
      ...orderActions
    }
)(ListHello)