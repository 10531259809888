import React, { Component } from 'react';

import { connect } from 'react-redux';

import { Link } from 'react-router-dom'
import { actions } from '../ducks/session';
import { selectors } from '../ducks/session'
import { DoctorNavigationBar } from '../../common/NavigationBar';
import { SessionOverlay } from '../common/SessionOverlay';
import AdminMessaging from './AdminMessaging'


class DoctorAdmin extends Component {

    constructor() {
        super();
        this.state = {
            views: [
              () => null,
              this.renderMessaging,
            ],
            currentView: 1,
        }

    }

    componentDidMount() {
        
    }

    renderMessaging = () => {
        return <AdminMessaging {...this.props} />
    }


    render() {
        return (
            <div id="" className="app-container doctor">

                {
                this.props.sessionExpires ?
                <SessionOverlay continueSession={this.props.continueSession} /> :
                ''
                }

                <DoctorNavigationBar
                    loggedIn={true}
                    logout={this.props.logout}
                    firstName={this.props.currentDoctor.firstName}
                    lastName={this.props.currentDoctor.lastName}
                    navMessage={this.props.currentMessage}
                />


                <div className="admin-container">

                    <div className="admin-panel">

                        <div className="admin-panel_sidenav">
                            <Link to="#" className="nav-item" onClick={() => this.setState({ currentView: 1 })}>Viesti apteekeille <i className="fas fa-chevron-right"></i></Link>
                        </div>

                        <div className="admin-content">
                            { 
                                // this.state.views[this.state.currentView]() 
                            }
                            <AdminMessaging 
                                message = {this.props.currentMessage}
                                saveDoctorMessage = {this.props.saveDoctorMessage}
                            />
                        </div>

                    </div>
                    
                </div>    
            </div>
        );
    }
}
/*
const AdminMessaging = (props) => {
    return (
        <div className="messaging">
            <h2>Viesti kaikille apteekeille</h2>
            <div className="form-container">
                <label>Otsikko</label>
                <input />
                <label>Lisätiedot</label>
                <input />
            </div>
            
            <label htmlFor="switch">
            <Switch
                onChange={this.handleChange}
                checked={false}
                onColor='#05B4AF'
                offColor='#DDDDDD'
                uncheckedIcon={false}
                checkedIcon={false}
                id="switch"
            />
            </label>
        </div>
    );
}
*/

const mapStateToProps = (state) => ({
    currentDoctor: selectors.getCurrentDoctor(state),
    sessionExpires: state.ui.sessionExpires,
    currentMessage: selectors.getCurrentMessage(state)
})

export default connect(mapStateToProps, { ...actions })(DoctorAdmin);