import React, { Component } from 'react'

import { connect } from 'react-redux'
import { actions } from '../ducks/session'
import Card from '../../common/Card'
import { PharmacyNavigation } from '../../common/NavigationBar';

class LoginPharmacy extends Component {

	constructor(props) {
		super(props)
		this.state = {
            username: '',
            password: ''
		}
    }


	onKeyPress = (e) => {
		if (e.key === 'Enter') this.loginPharma()
    }
    
    credentialsMissing() {
        const { username, password } = this.state;
        return !(username && password);
    }
    
	loginPharma = () => {
        console.log("login,",this.state.username)
		this.props.login(this.state.username, this.state.password)
    }

    getUsernameInput() {
		return [
            <p key='1' className="card__input-label">Käyttäjätunnus</p>,
            <input
                key='2'
                className="card__input"
                type="text"
                name="username"
                placeholder=""
                value={this.state.username}
                onKeyPress={this.onKeyPress}
                onChange={(e) => this.setState({ username: e.target.value })}
            />
		]
	}

	getPasswordInput() {
		return [
			<p key='3' className="card__input-label">Salasana</p>,
            <input
                id="password"
                key='4'
                className="card__input card__input--password"
                type="password"
                value={this.state.password}
                placeholder=""
                onKeyPress={this.onKeyPress}
                onChange={ e => this.setState({ password: e.target.value })}
            />
		]
    }
    
    getErrorLabel = () => {
		return this.props.loginFailed ? <div key='5' className="message--error">Kirjautumistiedot virheelliset. Yritä uudelleen</div> : null
	}
    
    render() {
	    return (
            <Card
				heading="Apteekkien kirjautuminen"
				cardPurposeStyle="card--login"
				input={[ this.getUsernameInput(), this.getPasswordInput(), this.getErrorLabel() ]}
				previousPath="/"
				loggedIn={false}
				disabled={this.credentialsMissing()}
                onSubmit={() => this.loginPharma()}
                buttontext="Kirjaudu"
                navigationBar={ <PharmacyNavigation /> }
			/>
        );
    }
    
}

export default connect(
	state => ({
		loginFailed: state.ui.loginFailed,
	}),
	{
	  ...actions
	}
)(LoginPharmacy)