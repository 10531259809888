const headers = {
	'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Cache': 'no-cache'
}

const POST = 'POST'
const GET = 'GET'
const DELETE = 'DELETE'
const PATCH = 'PATCH'

const apiCall = (url, method, body) => {

	console.log("apiCall:", url);
	let requestObject = {
		headers: headers,
		credentials: 'include'		
	}

	requestObject['method'] = method ? method : GET

	if (body) {
		requestObject['body'] = JSON.stringify(body)
	}

	return fetch(`${url}`, requestObject)
}

export default {
	
	fetchRegistration: () => apiCall('/ui-api/v1/registration'),
	createUser: (data) => apiCall('/ui-api/v1/my-user', POST, data),
	login: (email, passwd) => apiCall('/ui-api/v1/login', POST, { email: email, password: passwd }),
	logout: () => apiCall('/ui-api/v1/logout', POST),
	fetchUser: () => apiCall('/ui-api/v1/my-user'),
	fetchProducts: () => apiCall('/ui-api/v1/products'),
	updatePersonalData: (email, phone) => apiCall('/ui-api/v1/my-user', PATCH, { email: email, phone: phone }),
	setPassword: (password) => apiCall('/ui-api/v1/my-user/change-password', POST, { newPassword: password }),

	addItem2Cart: (type, productId, reason) =>
		apiCall(
			'/ui-api/v1/my-shopping-cart/item',
			POST, {
				type: type, productId: productId, reason: reason
			}),

	addPrescription2Cart: (problem, chat) => {

		return apiCall(
			'/ui-api/v1/my-shopping-cart/item',
			POST,
			{
				type: 'prescription',
				medicalProblem: problem,
				chat: JSON.stringify(chat)
			}
		)
	},

	removeItemFromCart: (rowId) => apiCall('/ui-api/v1/my-shopping-cart/item/' + rowId, DELETE),
	
	fetchCart: () => apiCall('/ui-api/v1/my-shopping-cart'),
	applyCoupon: (coupon) => apiCall('/ui-api/v1/my-shopping-cart/apply-coupon', POST, { code: coupon }),
	unApplyCoupon: () => apiCall('/ui-api/v1/my-shopping-cart/unapply-coupon', POST),
	
	payCart: (token) => {
		return apiCall(
			'/ui-api/v1/my-shopping-cart/pay',
			POST,
			token
		)
	},

	fetchOutstandingOrders: () => apiCall('/ui-api/v1/my-outstanding-orders'),
	fetchOrderHistory: () => apiCall('/ui-api/v1/my-order-history'),

	fetchFeedbackText: () => apiCall('/ui-api/v1/my-feedback-question'),
	giveFeedback: (rating, feedback) => apiCall('/ui-api/v1/my-feedback-question', POST, { score: rating, feedbackText: feedback }),

	// ------ DOCTOR ---------

	loginDoctor: (email, password) => apiCall('/ui-api/v1/doctor-login', POST, { email: email, password: password }),
	logoutDoctor: () => apiCall('/ui-api/v1/logout', POST),
	fetchDoctor: () => apiCall('/ui-api/v1/my-doctor-info'),
	fetchSystemStatus: () => apiCall('/ui-api/v1/system-status'),
	pickNextOrder: () => apiCall('/ui-api/v1/order/pick-first', POST),
	releaseOrder: () => apiCall('/ui-api/v1/order/release', POST),
  releaseOneOrder: (orderId) => apiCall(`/ui-api/v1/order/release-one/${orderId}`, POST),
  orderQueue: () => apiCall('/ui-api/v1/order/queue'),
	
	submitDecision: (itemId, decision, reasonForDecision) =>
		apiCall(
			'/ui-api/v1/order/process-item/' + itemId,
			POST, {
				decision: decision,
				reasonForDecision: reasonForDecision
			}),

	submitOrder: () => apiCall('/ui-api/v1/order/mark-delivered', POST),


	// ------- PHARMACY ----------
	loginPharmacist: (username, password) => {
		console.log('loginPharmacist')
		return apiCall('/ui-api/v1/pharmacist-login', POST, { username: username, password: password })
	},
	logoutPharmacist: () => apiCall('/ui-api/v1/logout', POST),
	fetchPharmacist: () => apiCall('/ui-api/v1/pharmacy-user'),
	fetchPharmacistOffices: () => {
		return apiCall('/ui-api/v1/users-offices')
	},
	setOffice: (officeId) =>  { 
		console.log("--------/ui-api/v1/selected-office--------",officeId)
		return apiCall('/ui-api/v1/selected-office/' + officeId, PATCH)
	},
	getOffice: () => apiCall('/ui-api/v1/users-session-office'),
	createPharmacyUser: (firstName, lastName, username, password) => {
		return apiCall('/ui-api/v1/create-pharmacy-user', POST, {
			firstName: firstName,
			lastName: lastName,
			username: username, 
			password: password
		})
	},
	updatePharmacyUser: (id, firstName, lastName, username, password) => apiCall('/ui-api/v1/update-pharmacy-user', POST, {
		id: id,
		firstName: firstName,
		lastName: lastName,
		username: username, 
		password: password
	}),
	deletePharmacyUser: (username) => {
		console.log("--------deletePharmacyUser->", username)
		return apiCall('/ui-api/v1/delete-pharmacy-user', DELETE, {username: username})
	},
	fetchPharmacyUsers: () => apiCall('/ui-api/v1/list-users'),
	savePatientInfo: (data) => apiCall('/ui-api/v1/patient-info', POST, data),

  setDeliveryOptionToChart: (option) => apiCall('/ui-api/v1/pharmacy-shopping-cart/delivery-option', POST, {
    id: option
  }),
	addItem2PharmacyCart: (type, productId, reason, pharmacyData) =>
		apiCall(
			'/ui-api/v1/pharmacy-shopping-cart/item',
			POST, {
				type, productId, reason, pharmacyData
			}),
	sendPharmacyCart2Doctor: () => apiCall('/ui-api/v1/pharmacy-shopping-cart/send', POST),
	fetchPharmacyCart: () => apiCall('/ui-api/v1/pharmacy-user-shopping-cart'),
	deletePharmacyCart: () => apiCall('/ui-api/v1/pharmacy-user-shopping-cart/clear', POST),
	
	removeItemFromList: (itemId) => apiCall('/ui-api/v1/check-order-item/'+itemId, POST),
	fetchPharmacyOrderHistory: (selectMode) => apiCall(`/ui-api/v1/pharmacy-order-history${selectMode.status}`),

	fetchCompletedOrders: (filter, sortOrder, offset) => apiCall('/ui-api/v1/history', POST, { filter, sortOrder, offset }),

	checkUserName: (userName) => apiCall(`/ui-api/v1/check-username/${userName}`),

	getSessionOffice: () => apiCall('/ui-api/v1/users-session-office'),

	setMessage: (data) => {
		console.log("setMessage",data)
		return apiCall('/ui-api/v1/set-pharmacy-message', POST, data )
	},
	getMessage: () => {
		console.log("--------getMessage")
		return apiCall('/ui-api/v1/get-pharmacy-message/1')
	},
	setMessageChecked: () => apiCall('/ui-api/v1/set-pharmacy-message-checked', POST),
	setMessageUnchecked: () => apiCall('/ui-api/v1/set-pharmacy-message-unchecked', POST),
	getMessageStatus: () => apiCall('/ui-api/v1/get-pharmacy-user-message-status'),

	startPaymentSession: () => apiCall('/ui-api/v1/start-payment-session', POST),
}
