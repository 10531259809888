import { combineReducers } from 'redux'
import registration from './registration'
import product from './product'
import session from './session'
import ui from './ui'
import profile from './profile'
import stripe from './stripe'

import { default as doctor } from '../doctor/ducks'

import { default as pharmacist } from '../pharmacy/ducks' 

import { routerReducer } from 'react-router-redux'

const reducer = combineReducers({ registration, product, session, stripe, ui, routerReducer, profile, doctor, pharmacist })

export default reducer