import { actionUtils, flatOrder } from './common'
import { apiConstants, LAST_DECIDED_TIME_FRAME } from '../common/constants'
import { actions as uiActions } from './ui'

export const types = {
	ADD_LOGIN_EMAIL: Symbol('session/LOGIN/ADD_EMAIL'),
	LOGIN: Symbol('session/LOGIN'),
	LOGOUT: Symbol('session//LOGOUT'),
	RECEIVE_USER_DATA: Symbol('session/LOGIN/RECEIVE_USER_DATA'),
	FETCH_CART: Symbol('product/FETCH_CART'),
	CHOOSE_PRODUCT: Symbol('product/CHOOSE_PRODUCT'),
	CHOOSE_PRESCRIPTION: Symbol('product/CHOOSE_PRESCRIPTION'),
	TOGGLE_CART_ITEM: Symbol('product/TOGGLE_CART_ITEM'),
	REMOVE_ITEM_FROM_CART: Symbol('session/REMOVE_ITEM_FROM_CART'),
	RECEIVE_CART: Symbol('product/RECEIVE_CART'),
	PAY_CART: Symbol('product/PAY_CART'),
	RECEIVE_FEEDBACK_TEXT: Symbol('session/RECEIVE_FEEDBACK_TEXT'),
	GIVE_FEEDBACK: Symbol('session/GIVE_FEEDBACK'),
	FETCH_SESSION_DATA: Symbol('session/FETCH_SESSION_DATA'),
	APPLY_COUPON: Symbol('session/APPLY_COUPON'),
	UN_APPLY_COUPON: Symbol('session/UN_APPLY_COUPON'),
}

export const userLevels = {
	CUSTOMER_USER: Symbol('session/CUSTOMER_USER'),
	DOCTOR: Symbol('session/DOCTOR'),
	PHARMACY_USER: Symbol('session/PHARMACY_USER'),
}

let initialState = {
	cart: {
		items: []
	},
	loginEmail: '',
	user: null
}

export default (state = initialState, action) => {

	switch (action.type) {

		case types.ADD_LOGIN_EMAIL:
			return {
				...state,
				loginEmail: action.email
			}

		case types.RECEIVE_USER_DATA:
			return {
				...state,
				user: action.data
			}

		case types.RECEIVE_CART:
			return {
				...state,
				cart: action.cart
			}

		case types.RECEIVE_FEEDBACK_TEXT:
			return {
				...state,
				feedbackText: action.feedbackText
			}

		case types.TOGGLE_CART_ITEM:

			const item = state.cart.items.find(i => i.productId === action.productId)
			let c = state.cart.items.map(i => i.productId !== item.productId ? i : { ...i })

			return {
				...state,
				cart: c
			}

		default:
			return state
	}
}

export const actions = {

	login: (email, password) => actionUtils.action(
		types.LOGIN, {
			email: email,
			password: password
		}),

	logout: () => (actionUtils.action(types.LOGOUT)),
	
	receiveUserData: (data) => (actionUtils.action(
		types.RECEIVE_USER_DATA, {
			data: { ...data }
		})),

	removeItemFromCart: (rowId) => actionUtils.action(types.REMOVE_ITEM_FROM_CART, { rowId: rowId }),

	chooseItemFromHistory: (id) => (dispatch, getState) => {

		const orders = getOrderHistory(getState())
		const p = orders.find(order => order.id === id)

		dispatch(actionUtils.action(
			types.CHOOSE_PRODUCT, {
				productId: p.productId,
				orderType: p.type,
				reason: p.reason
			}))
	},

	fetchCart: () => actionUtils.action(types.FETCH_CART),

	chooseProduct: (productId, type, reason) => actionUtils.action(
		types.CHOOSE_PRODUCT, {
			productId: productId,
			orderType: type,
			reason: reason
		}),
	
	choosePrescription: (problem, chat) => actionUtils.action(
		types.CHOOSE_PRESCRIPTION, {
			problem: problem,
			chat: chat
		}),

	payCart: (stripeToken) => (actionUtils.action(types.PAY_CART, { token: stripeToken })),
	giveFeedback: (rating, feedback) => actionUtils.action(types.GIVE_FEEDBACK, { rating: rating, feedback: feedback }),
	fetchSessionData: (userLevel) => actionUtils.action(types.FETCH_SESSION_DATA, { userLevel }),
	applyCoupon: (coupon) => actionUtils.action(types.APPLY_COUPON, { code: coupon }),
	unApplyCoupon: () => actionUtils.action(types.UN_APPLY_COUPON)
}


const getOrderHistory = state => {

	let allOrders = flatOrder(state.product.orderHistory)

	if (!allOrders.length) return []

	return allOrders.map(o => mapRow2Product(state, o)).reverse()
}

const mapRow2Product = (state, r) => {

	const p = state.product.products.find(p => p.productId === r.productId)
	
	const renewableTill = new Date(r.deliveryTime)
	renewableTill.setFullYear(renewableTill.getFullYear() + 2)
	
	return r.type === apiConstants.CART_PURCHASE_TYPE_NEW ?
		r :
		{
			...r,
			productId: p.productId,
			displayName: p.displayName,
			format: p.format,
			renewableTill: (renewableTill.getMonth() + 1) + '/' + renewableTill.getFullYear()
		}
}

const filterProduct = (these = [], those = []) => {
	const ids = these.map(p => p.productId)
	return those.filter(p => !p.productId ? true : !ids.includes(p.productId))
}

const waitingOrInCart = (state) => {

	const waiting = getOrderHistory(state).filter(o => o.decision === 'undecided')
	const cart = state.session.cart.items.filter(i => i.productId)
	
	return waiting.concat(cart)
}

// Unused
//const filterWaitingOrInCart = (state, products) => ( filterProduct(waitingOrInCart(state), products) )
const waitingOrders = state => (getOrderHistory(state).filter(o => o.decision === 'undecided'))

const lastDecided = (orderHistory) => {

	const delta = LAST_DECIDED_TIME_FRAME
	const now = new Date().getTime()

	return orderHistory.filter(prescription => {
		const deliveryTime = new Date(prescription.deliveryTime).getTime()
		return (now - deliveryTime) < delta
	})
}

const cents2Euros = (cents) => {
	return (cents / 100).toLocaleString("fi-FI", {style:"currency", currency: "EUR"})
}

export const selectors = {

    getOrderHistory: state => {
    	
    	const orderHistory = getOrderHistory(state).filter(p => p.decision !== 'undecided')
    	const waitingPrescriptions = waitingOrders(state) || []
    	const recentlyDecided = lastDecided(orderHistory).map(p => ({ ...p, recent: true }))

// is this a right place for this
if (recentlyDecided.length) {
	uiActions.showFeedback()
}

    	const decided = filterProduct(waitingOrInCart(state), filterProduct(recentlyDecided, orderHistory)) || []

    	return {
    		waitingPrescriptions: waitingPrescriptions,
    		recentlyDecided: recentlyDecided,
    		decided: decided
    	}
    },

	// getCart: state => {
	// 	if (state.session.cart.length < 1) return []
	// 	return state.session.cart.items.map(o => mapRow2Product(state, o))
	// },

	// getCart: state => (state.session.cart),

	// FIXME Temporary solution until Cards rewritten
	getCart: state => {

		const cart = state.session.cart
		const items = cart.items.map(r => mapRow2Product(state, r))
		
		let coupon = null

		if (cart.coupon) {
			coupon = {
				...cart.coupon,
				discountEuro: cents2Euros(cart.coupon.discountAmountCents)
			}
		}

		return {
			...cart,
			priceEuro: cents2Euros(cart.totalPrice),
			coupon: coupon,
			items: items
		}
	},

	getUnchosenProducts: state => {
	// Return products which are not in the cart or undecided to show on autocomnplete list
		const chosen = waitingOrInCart(state)
		return filterProduct(chosen, state.product.products)
	},

	// getIsCartDirty: state => (state.session.cart.length > 0),
	// getCartPrice: state => (state.session.cart.map(p => getPriceForType(p.type)).reduce((x, y) => x + y, 0))	
}



