import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Navbar from '../prescriptionlist/Navbar'
import NavigationBar from '../common/NavigationBar'
import { PersonalInfo } from './PersonalInfo';
import ChangePassword from './ChangePassword';
import { PrivacyPolicy } from './PrivacyPolicy';
import { TermsOfService } from './TermsOfService';
import NewChatComponent from '../chat/NewChatComponent'

import { selectors, actions } from '../ducks/profile'
import { actions as sessionActions } from '../ducks/session'

export class ProfileBase extends Component {

  constructor(props) {
    super(props)
    this.state = {
      views: [
        () => null,
        this.renderPersonalInfo,
        this.renderChangePassword,
        this.renderTermsOfService,
        this.renderPrivacyPolicy
      ],
      currentView: 1,
      showChat: false,
    }
  }

  displayOrder() {
    this.chatHandler();
    this.props.history.push("/reseptit");
  }

  chatHandler = () => {
    this.setState({ showChat : !this.state.showChat })
  }

  renderPersonalInfo = () => {
    return <PersonalInfo {...this.props} />
  }

  renderChangePassword = () => {
    return <ChangePassword {...this.props} />
  }

  renderTermsOfService = () => {
    return <TermsOfService {...this.props} />
  }

  renderPrivacyPolicy = () => {
    return <PrivacyPolicy {...this.props} />
  }
  onBackClick = () => {
    this.setState({ currentView: 0 })
  }

  render() {
    return (
      <div>
        <NavigationBar loggedIn={true}/>
        <div className="profile__container">
        {
          this.state.currentView > 0 && 
            <div className="hide-desktop profile__back" onClick={this.onBackClick} />
        }
          <div className="profile__links container">
              <h1 className="hide-desktop">Oma Tili</h1>
              <Link to="#" className="omat_tiedot" onClick={() => this.setState({ currentView: 1 })}>Yhteystiedot <i className="fas fa-chevron-right"></i></Link>

              <Link to="#"
              className="pin_koodi"
              onClick={() => this.setState({ currentView: 2 })}
              >
                Vaihda salasana <i className="fas fa-chevron-right"></i>
              </Link>

              <Link to="#" className="kirjaudu_ulos" onClick={() => this.props.logout()}>Kirjaudu ulos <i className="fas fa-chevron-right"></i></Link>
              
              <div className="secondary__links">
                <Link to="#" className="" onClick={() => this.setState({ currentView: 3 })}>Käyttöehdot</Link>
                <Link to="#" className="" onClick={() => this.setState({ currentView: 4 })}>Rekisteriseloste</Link>
              </div>

          </div>
          <div className={this.state.currentView > 0 ? 'showhide-toggle-mobile profile__content' : 'profile__content'}>
            { this.state.views[this.state.currentView]() }
          </div>
        </div>
        {
            this.state.showChat ?
            <NewChatComponent
              closeChat={e => this.displayOrder()}
              onAbort={this.chatHandler}
            /> :
            null
          }
        {/* Piilota mobiilinavigaatio jos jokin content on renderöity */}
        <Navbar openChat={this.chatHandler}/>
      </div>
    )
  }
}

export default connect(
  state => {
    const { email, phone } = selectors.getUser(state)
    return {
      email: email,
      phone: phone
    }
  },
  {
    ...actions,
    logout: sessionActions.logout
  }
)(ProfileBase)



