import React, { Component } from 'react'
import Card from '../common/Card'

import { STRONG_PASS } from '../common/constants'

class Password extends Component {

  render() {
    return (
      <div className="card__input--password_container">
      <p className="card__input-label">Salasana</p>
        <input
          className="card__input card__input--password"
          type="password"
          value={this.props.password1}
          placeholder=""
          onChange={ e => this.props.setPassword1(e.target.value)}
        />
        <p className="card__input-label">Vahvista salasana</p>
        <input
          className="card__input card__input--password"
          type="password"
          value={this.props.password2}
          placeholder=""
          onChange={ e => this.props.setPassword2(e.target.value)}
        />
        { this.props.error ? <p className="message message--error">{this.props.error}</p> : null }
      </div>
    )    
  }
}

export default class RegisterPassword extends Component {

  constructor(props) {
    super(props)
    this.state = {
      // password1: props.password,
      // password2: props.password,
      password1: '',
      password2: '',
      error: null
    }
  }

  passwordValid = () => {

    if (this.state.password1 !== this.state.password2) {
      this.setState({ error: 'Salasanat eivät täsmää.' })      
      return false
    }

    if (this.state.password1.length < 8) {
      this.setState({ error: 'Salasanan pitää olla vähintään kahdeksan merkkiä pitkä.' })      
      return false
    }

    if (!STRONG_PASS.test(this.state.password1)) {
      this.setState({ error: 'Salasanan pitää sisältää vähintään yksi pieni, yksi iso kirjain ja yksi numero.' })      
      return false      
    }

    return true
  }

  submit = () => {
    if (this.passwordValid()) {
      this.props.setPassword(this.state.password1) 
    }
  }

  render() {
    return (
      <Card
        progress={this.props.progress}
        heading="Salasana"
        subheading="Luodaan tilillesi salasana"
        description="Salasanan on oltava vähintään kahdeksan merkkiä pitkä, sen pitää sisältää vähintään yksi iso kirjain, yksi pieni kirjain ja yksi numero."
        input={
          <Password
            password1={this.state.password1}
            password2={this.state.password2}
            setPassword1={(p1) => this.setState({ password1: p1 })}
            setPassword2={(p2) => this.setState({ password2: p2 })}
            error={this.state.error}
          />
        }
        onSubmit={this.submit}
      />
    )
  }
}

