import React, { Component } from 'react'
import ButtonExpandText from '../../common/ButtonExpandText'

import { apiConstants } from '../../common/constants'
import AnimateHeight from 'react-animate-height';

const globalStatus = (p) => {
// attributes for all cards
// attributes by product type are defined in their components

  const inCart = !p.decision
  const waiting = p.decision === apiConstants.PRESCRIPTION_DECISION_UNDECIDED
  const id = inCart ? p.rowId : p.id

  let processStatus = {}

  if (inCart) {
    processStatus = { cardClass: ' renewable'}
  } else if (p.recent) {

    if (p.decision === apiConstants.PRESCRIPTION_DECISION_ACCEPTED) {
      processStatus = { cardClass: ' accepted' }
    } else if (p.decision.startsWith(apiConstants.PRESCRIPTION_DECISION_REJECTED)) {
      processStatus = { cardClass: ' declined' }
    }
  } else if (waiting) {
      processStatus = { cardClass: ' waiting' }
  } else {

    if (p.decision.startsWith(apiConstants.PRESCRIPTION_DECISION_REJECTED) || p.type === 'prescription') {
        processStatus = { cardClass: ' disabled' }
    } else {
    }
  }

  return {
    ...processStatus,
    id: id,
    decided: p.decision === apiConstants.PRESCRIPTION_DECISION_ACCEPTED || (p.decision && p.decision.startsWith(apiConstants.PRESCRIPTION_DECISION_REJECTED)),
    showPrice: inCart,
    price: p.price,
    checkboxDisabled: !inCart && (waiting || p.type === apiConstants.CART_PURCHASE_TYPE_NEW),
    checked: inCart || waiting
  }
}


class Reason4Decision extends Component {

  renderDetails = () => {
    if (!this.props.reason) return null

    const title = this.props.decision === apiConstants.PRESCRIPTION_DECISION_ACCEPTED ? 'Lisätietoa hyväksynnästä' : 'Hylkäyksen peruste';
    
    return (
      [ <h3 className="card__subheader" key="0">{title}</h3>, <p key="1">{this.props.reason}</p> ]
    );
  }

  render() {
    return this.renderDetails()
  }
}

class Topbar extends Component {
    
  removeCard(id)  {
      console.log(id)
      this.props.onRemove(id)
  }

  render() {

    const { text, prescriptionid, decision, deliveryTime, pharmacyChecked  } = this.props
    const d = new Date(deliveryTime)
    const fixTime = d.getHours() + ':' + (d.getMinutes() < 10 ? '0' : '') + d.getMinutes()
    return (
      <div className="topbar">
        {  decision !== 'undecided' && 
        <span className="created">{fixTime}</span>
        }

        <span className="status">{text}</span>

        {  (decision !== 'undecided' && pharmacyChecked !== 'checked') && 
            <span className="trash" onClick={ ()=>this.removeCard(prescriptionid) }><i className="fas fa-trash-alt"></i></span>
        }
      </div>
    )
  }
}

class RenewableCardBody extends Component {

  constructor(props) {
    super(props)
    this.state = { 
      hidden: true,
      height: 0
    }
  }

  toggleHidden = () => {
    const { height } = this.state;
    this.setState({
      hidden: !this.state.hidden,
      height: height === 0 ? 'auto' : 0,
    })
  }

  renderExpiration = () => {

    const { decision, renewableTill } = this.props

    if (decision === apiConstants.PRESCRIPTION_DECISION_ACCEPTED) {
      return (<p className="renewable__till">Uusittavissa {renewableTill} asti</p>)
    } else {
      return null
    }
  }

  render() {

    const { displayName, patientFirstName, patientLastName, patientHetu, format, reasonForDecision, decision, pharmacist } = this.props
    const { height } = this.state;

    return ( 
         <div className={ height === 0 ? "content extended" : "content extended rotate-arrow"}>
            <h2 onClick={this.toggleHidden}>{patientFirstName} {patientLastName}<ButtonExpandText /></h2>
              <AnimateHeight duration={ 350 } height={ height }  >
                <div>
                    <p>{patientHetu}</p>
                    <h3 className="card__subheader">{displayName}</h3>
                    <p>{format}</p>
                    <Reason4Decision decision={decision} reason={reasonForDecision} />
                    <h3 className="card__subheader">Käsittelijä</h3>
                    <p>{pharmacist}</p>
                </div>
              </AnimateHeight>
          </div>
      )
  }
}

class Card extends Component {

  render() {

    const { cardClass, decision, statusText, created, id, onRemove,deliveryTime, pharmacyChecked, ...other } = this.props

    // quickfix
    let statusClass = ''
    if (decision === 'rejected' || decision === 'rejected-with-coupon') {
        statusClass = 'declined'
    } else if (decision === 'approved') {
        statusClass = 'accepted'
    } else {
        statusClass = 'waiting'
    }

    return (
        <div className={"prescription__card " + statusClass}> 
          <Topbar 
            created={created}  
            deliveryTime={deliveryTime} 
            decision={decision} 
            text={statusText} 
            prescriptionid={id} 
            onRemove={onRemove}
            pharmacyChecked={pharmacyChecked} />
          <RenewableCardBody decision={decision} { ...other } />
        </div>
      )
  }
}

class CardContainer extends Component {

  render() {

    const { checkboxDisabled, checked, id, onRemove, ...other } = this.props

    return (

      <div className="prescription__container">
        <Card id={id} onRemove={onRemove} { ...other } />
      </div>
      )
  }
}

class Prescription extends Component {

  constructor(props) {
    super(props)
    this.state = { hidden: true }
  }

  toggleHidden = () => {
    this.setState({
      hidden: !this.state.hidden
    })
  }

  status = (p) => {

    const gStatus = globalStatus(p)
    
    let statusText = ''

    if (!p.decision)
      statusText = 'Reseptipyyntö'
    else if (p.decision === apiConstants.PRESCRIPTION_DECISION_ACCEPTED)
      statusText = 'Hyväksytty'
    else if (p.decision.startsWith(apiConstants.PRESCRIPTION_DECISION_REJECTED))
      statusText = 'Hylätty'
    else
      statusText = 'Odottaa käsittelyä'

    return {
      ...gStatus,
      statusText: statusText
    }
  }


  render() {

    const props = { ...this.status(this.props.prescription), ...this.props.prescription, onRemove: this.props.onRemove }
    return (
      <CardContainer {...props} />
    )
  }
}


const PrescriptionCard = (props) => {
  const { onRemove, prescription } = props
  return (
    <Prescription onRemove={onRemove} prescription={prescription} />
  );
};

export default PrescriptionCard