import React, { Component } from 'react'
import ProfileHeader from './ProfileHeader'
import AutosizeInput from '../common/AutoInput';
import { FINNISH_PHONE_NUMBER_FORMAT } from '../common/constants';

export class PersonalInfo extends Component {

  constructor(props) {
    super(props)
    this.state = {
      email: props.email,
      phone: props.phone,
      email_disabled: true,
      phone_disabled: true,
      email_edit_status: '',
      phone_edit_status: '',
      email_readonly: true,
      phone_readonly: true,
      phone_editing_allowed: true,
      email_editing_allowed: true,
      phone_error: false,
      email_error: false
    }
    
  }

  componentWillUnmount() {
    //kill lingering timeouts if any 
    var i = window.setTimeout(null,0);
    while (i--) {
        window.clearTimeout(i);
    }
  }

  validEmail() {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)
  } 

  validPhone() {
    let phone = this.sanitizePhone(this.state.phone);
    return FINNISH_PHONE_NUMBER_FORMAT.test(phone);
  } 

  sanitizePhone =(phone) => {
    return phone.replace(/ /g, "");
  }
  
  handleEmailEdit = () => {
    //this.setState( {email_disabled: !this.state.email_disabled} )
    if( this.state.email_editing_allowed) {
      setTimeout(() => this.inputEmail.focus(), 100)
    }
  }

  onFocusInput = e => {
    let temp_value = e.target.value
    e.target.value = ''
    e.target.value = temp_value

  }

  onFocusPhone = e => {
    this.setState({ phone_readonly: false, phone_edit_status: '', phone_error: false})
    this.onFocusInput(e)
  }

  onFocusEmail = e => {
    this.setState({ email_readonly: false, email_edit_status: '', email_error: false})
    this.onFocusInput(e)
  }
  onBlurEmail = e => {

    this.setState({ email_editing_allowed: false})

    if (this.validEmail() ) {
      setTimeout(() => this.inputEmail.blur(), 100)
      this.setState({ email_disabled: true, email_edit_status: 'edit-ok',  email_readonly: true, email: e.target.value })
      setTimeout(() => this.setState({ email_edit_status: '', email_editing_allowed: true}), 2000)
      this.props.updatePersonalData(this.state.email, this.state.phone)
    } else {
      this.setState({ email_disabled: true, email_edit_status: 'edit-error', mail_readonly: true, email_error: true })
      setTimeout(() => this.setState({ email_editing_allowed: true}), 1000)
      //console.log("non valid email")
    }

  } 

  handlePhoneEdit = () => {
    //this.setState( {phone_disabled: !this.state.phone_disabled} )
    if( this.state.phone_editing_allowed) {
      setTimeout(() => this.inputPhone.focus(), 100)
    }
  }

  onBlurPhone = e => {
    setTimeout(() => this.inputPhone.blur(), 100)
    this.setState({ phone_editing_allowed: false})
    
    if (this.validPhone() ) {
      this.setState({ phone_disabled: true, phone_edit_status: 'edit-ok', phone_readonly: true, phone: e.target.value })
      setTimeout(() => this.setState({ phone_edit_status: '', phone_editing_allowed: true}), 2000)
      this.props.updatePersonalData(this.state.email, this.sanitizePhone(this.state.phone))
    } else {
      this.setState({ phone_disabled: true, phone_edit_status: 'edit-error', phone_readonly: true, phone_error: true })
      setTimeout(() => this.setState({ phone_editing_allowed: true}), 1000)
    }
  }

  updateEmail = e => {
    if (e.key === 'Enter') this.onBlurEmail(e)
  }

  updatePhone = e => {
    if (e.key === 'Enter') this.onBlurPhone(e)
  }

  render() {
    return (
      <div className="profileForm__container profile__container">
      
        <ProfileHeader 
          title="Yhteystiedot"
          // onSubmit={() => this.props.updatePersonalData(this.state.email, this.state.phone)}
        />

        <div className={"input-wrapper " + this.state.email_edit_status}>
        <span>Sähköposti</span>
          <input
            value={this.state.email}
            onChange={e => this.setState({ email: e.target.value })}
            onKeyPress={this.updateEmail}
            type="email" id="email"
            className="--email"
            placeholder='etunimi.sukunimi@email.com'
            ref={(input) => { this.inputEmail = input; }}
            //disabled = {(this.state.email_disabled)? "disabled" : false}
            readOnly={this.state.email_readonly}
            onFocus={this.onFocusEmail}
            onBlur={this.onBlurEmail}
          />
          <span className={this.state.email_readonly ? "edit-value" : "edit-value editing"} onClick={this.handleEmailEdit}><span className="edit"></span></span>
        </div>


        <div className={"input-wrapper " + this.state.phone_edit_status}>
          <span className="phone-label">Puhelinnumero</span>
          <span className="phone-prefix">+358</span>
          <AutosizeInput
            name="phone"
            value={this.state.phone}
            onChange={e => this.setState({ phone: e.target.value })}
            onKeyPress={this.updatePhone}
            type="tel"
            className="--phone"
            id="phone"
            placeholder='esim. 040 123 4567'
            ref={(input) => { this.inputPhone = input; }}
            readOnly={this.state.phone_readonly}
            onFocus={this.onFocusPhone}
            onBlur={this.onBlurPhone}
          />
          <span className={this.state.phone_readonly ? "edit-value" : "edit-value editing"} onClick={this.handlePhoneEdit}><span className="edit"></span></span>
        </div>
        {/*

        <div className="input-wrapper">
        <span>Osoite</span>
          <input value={this.props.adress} type="text" className="--adress" id="adress" placeholder='Katunimi 1 as 1 20100 Turku'/>
         <span className="edit-value"><i className="fas fa-pencil-alt"></i></span>
        </div>
      */}

      { this.state.email_error ? <div className="profile_error">Sähköpostin muoto on virheellinen.</div> : null }
      { this.state.phone_error ? <div className="profile_error">Puhelinnumeron muoto on virheellinen.</div> : null }

      </div>
    )
  }
}

export default PersonalInfo
