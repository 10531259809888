import * as React from 'react';
import { connect } from 'react-redux';

import { actions, ORDER_FETCH_SIZE } from '../ducks/session';
import { selectors } from '../ducks/session';

class CompletedOrdersList extends React.Component {

    componentDidMount() {
        this.listElement.addEventListener('scroll', () => {
            const { filter, sortOrder, offset, delta, fetching, loadAdditionalOrders } = this.props;
            const scrollHeight = this.listElement.scrollHeight;
            const scrollTop = this.listElement.scrollTop;
            const listHeight = this.listElement.clientHeight;

            if((scrollHeight - scrollTop === listHeight) && delta !== 0 && !fetching) {
                loadAdditionalOrders(filter, sortOrder, offset + ORDER_FETCH_SIZE);
            }
        });
    }

    render() {
        const { orders, fetching, lazy_loading, orderCount, selectedOrder } = this.props;
        const hasNoMatches = orderCount === 0 && !fetching;
        return (
            <div className="order-list" ref={element => this.listElement = element}>
            {
                hasNoMatches && <div className="order-list__item">Historia on tyhjä</div>
            }
            {
                orders && (!fetching || lazy_loading) && orders.map((item, index) => 
                    <ConnectedOrder key={`order-${index}`} order={item} selected={selectedOrder && item.orderId === selectedOrder.orderId}/> )
            }
            {
                fetching && <div className="order-list__item__spinner"><i className="fas fa-spinner fa-spin spinner"></i></div>
            }
            </div>
        );
    }
}

const OrderItem = (props) => {
    const { patient, deliveryStatus, deliveryTime, deliveryBeforeTime } = props.order;
    const dateText = deliveryStatus === "delivered" ? `Käsitelty ${formatDate(deliveryTime)}` : timeLeft(deliveryBeforeTime);
    const dateColor = dateText.indexOf('ylitetty') > 0 ? '#FF9D00' : 'white';
    
    return (
        <div className={`order-list__item ${deliveryStatus} ${props.selected && 'active'}`} onClick={ (e) => props.viewCompletedOrder(props.order)}>
            <div className="order-list__item__name">{ `${patient.firstName} ${patient.lastName}` }</div>
            <div className="order-list__item__time" style={{ color: dateColor }}>{ dateText }</div>
        </div>
    )
}

const formatDate = (dateString) => {
    const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const asDate = new Date(dateString);
    const datePart = asDate.toLocaleDateString('fi-FI', dateOptions);
    const timePart = asDate.toLocaleDateString('fi-FI', { hour: 'numeric', minute: 'numeric' });
    return `${datePart} - ${timePart.split(' ')[2]}`;
}

const timeLeft = (deadline) => {
    const dl = new Date(deadline);
    const curr = new Date();
    let diff_m = Math.trunc ((dl.getTime() - curr.getTime()) / 1000 / 60);
    let text = '';
    if (diff_m < 0) {
        text = "Käsittelyssä - aika ylitetty ";
        diff_m = -diff_m;
    } else {
        text = "Käsittelyssä - aikaa jäljellä "
    }
    const days = Math.trunc(diff_m / (60*24));
    const hours = Math.trunc((diff_m - (days*60*24)) / 60);
    const minutes = diff_m - (days*60*24) - (hours*60);
    if (days > 0) {
        text += days + 'pv ';
    }
    return text + hours + 't ' + minutes + 'min';
}

const ConnectedOrder = connect((state) => ({}), {
    ...actions
})(OrderItem);

export default connect(state => ({
    orders: state.doctor.session.completedOrders,
    offset: state.doctor.session.offset,
    filter: state.doctor.session.filter,
    sortOrder: state.doctor.session.sortOrder,
    orderCount: state.doctor.session.orderCount,
    delta: state.doctor.session.delta,
    fetching: state.ui.fetching,
    lazy_loading: state.doctor.session.meta.lazy_loading,
    selectedOrder: selectors.getSelectedCompletedOrder(state),
}), ({ ...actions }))(CompletedOrdersList);