import { actionUtils } from '../../ducks/common'


export const types = {
	SESSION_EXPIRES: Symbol('doctor/ui/SESSION_EXPIRES'),
	SESSION_CONTINUED: Symbol('doctor/ui/SESSION_CONTINUED'),
	SHOW_SYSTEM_STATUS: Symbol('doctor/ui/SHOW_SYSTEM_STATUS'),
	HIDE_SYSTEM_STATUS: Symbol('doctor/ui/HIDE_SYSTEM_STATUS'),
	OPEN_ORDER_SORT_SELECTOR: Symbol('doctor/ui/OPEN_ORDER_SORT_SELECTOR'),
	CLOSE_ORDER_SORT_SELECTOR: Symbol('doctor/ui/CLOSE_ORDER_SORT_SELECTOR'),
}


let initialState = {
	sessionExpires: true,
	showSystemStatus: true,
	orderSelectorVisible: false,
}

export default (state = initialState, action) => {

	switch (action.type) {

		case types.SESSION_EXPIRES:
			return {
				...state,
				sessionExpires: true
			}

		case types.SESSION_CONTINUED:
			return {
				...state,
				sessionExpires: false
			}

		case types.SHOW_SYSTEM_STATUS:
		case types.HIDE_SYSTEM_STATUS:
			return {
				...state,
				showSystemStatus: !state.showSystemStatus
			}
		
		case types.OPEN_ORDER_SORT_SELECTOR:
			return {
				...state,
				orderSelectorVisible: true,
			}
		case types.CLOSE_ORDER_SORT_SELECTOR: 
			return {
				...state,
				orderSelectorVisible: false,
			}
		default:
			return state
	}
}

export const actions = {
	continueSession: () => actionUtils.action(types.SESSION_CONTINUED),
	hideSystemStatus: () => actionUtils.action(types.HIDE_SYSTEM_STATUS),
	showSystemStatus: () => actionUtils.action(types.SHOW_SYSTEM_STATUS),
	showCompletedOrdersSortSelector: () => actionUtils.action(types.OPEN_ORDER_SORT_SELECTOR),
	closeCompletedOrdersSortSelector: () => actionUtils.action(types.CLOSE_ORDER_SORT_SELECTOR),
}

export const selectors = {
	getSystemStatus: (state) => (state.doctor.ui.showSystemStatus)
}