import React, { Component } from 'react'
import ButtonCardBack from './ButtonCardBack'
import NavigationBar from './NavigationBar';
import { tagManager } from './constants'

class Card extends Component {

	render() {
		return (
	      
	      <div className={'card card--register ' + this.props.cardPurposeStyle}>
			{ this.props.navigationBar 
				? this.props.navigationBar
				: <NavigationBar loggedIn={this.props.loggedIn}/>}
	        <ButtonCardBack previousPath={this.props.previousPath} />
					{this.props.progress}
					<div className="flex__holder">
						<div className="flex__holder-content">
							<h1 className="--alone">{this.props.heading}</h1>
							<p className="card__subheading">{this.props.subheading}</p>
							<p>{this.props.description}</p>
							{this.props.inputLabel &&
							<p className="card__input-label">{this.props.inputLabel}</p>
							}
							{this.props.input}

							{this.props.forgot &&
								<div className="card__forgot-container">
								<p className="card__forgot-label">{this.props.forgot}</p>
								<a className="card__forgot" href={this.props.forgotUrl}>Kirjaudu pankkitunnuksilla</a>
								</div>
							}
						</div>
						<button id={this.props.buttonid} onClick={this.props.onSubmit} className="card__btn card__btn--next" type="button" disabled={this.props.disabled || false}>{this.props.buttontext ? this.props.buttontext : 'Jatka'}</button>
					</div>

        	
			{tagManager}
	      </div>
		)
	}
}

export default Card