import React, { Component } from 'react'
import ChatView from './ChatView'

import { apiConstants } from '../../common/constants'

class Buttons extends Component {
  render() {
    return (
      <div className={"phase-1" + this.props.classes} /* poista active kun siirtyy phase-2 */>
        <button className="prescription__decline" onClick={this.props.decline}>Hylkää</button>
        <button className="prescription__accept" onClick={this.props.accept}>Hyväksy</button>
      </div>
    )
  }
}

class TextArea extends Component {

  constructor(props) {
    super(props)
    this.state = {
      value: props.reason,
      showPreApproval: true
    }
    this.preApprovalToggle = this.preApprovalToggle.bind(this)
  }

  componentWillReceiveProps(props) {
    if (this.props.reason !== props.reason) {
      this.setState({ value: props.reason })
    }
  }

  styleProfile() {
    return {__html: '.cls-1 { fill: #fff; } .cls-1, .cls-2 { stroke: #fff; stroke-width: 2px; } .cls-2 { fill: none;}'}
  }

  onChange = ({target}) => {
    this.setState({ value: target.value })
  }
  preApprovalToggle() {
    const { showPreApproval } = this.state;
    this.setState({
      showPreApproval: !showPreApproval,
    });
  }
  render() {

    const { id, title } = this.props
    const disabled = !this.state.value || !this.state.value.trim()
    const { showPreApproval } = this.state;
    
    return (
      <div className={"phase-2 " + this.props.classes} /* lisää tähän classit: active sekä mikä valinta accept tai decline */>
      {showPreApproval && disabled ? (  
        <button className="phase-2-optional_approval" onClick={this.preApprovalToggle}>
          {title} <svg xmlns="http://www.w3.org/2000/svg" viewBox="-541.414 3222.879 10.935 17.814"><defs><style dangerouslySetInnerHTML={this.styleProfile()}></style></defs><g id="Group_422"><path id="Path_5" className="cls-1" d="M.573 0h9.172a.573.573 0 1 1 0 1.146h-9.172a.573.573 0 1 1 0-1.146z" transform="matrix(.707 -.707 .707 .707 -540 3231.589)"/><path id="Path_6" className="cls-2" d="M.573 0h9.172a.573.573 0 1 1 0 1.146h-9.172a.573.573 0 0 1 0-1.146z" transform="matrix(.707 .707 -.707 .707 -539.189 3231.172)"/></g></svg>
        </button>
        ) : (
          <div className="phase-2_decision_container">
            <h4 className="decision-title">{title}:</h4>
            <div className="decision__input-wrapper">
              <textarea placeholder={title} className="accept decline" value={this.state.value} onChange={this.onChange}/>
              <button disabled={disabled} className="submit-info" onClick={() => this.props.onSubmit(id, this.state.value)}><svg xmlns="http://www.w3.org/2000/svg" viewBox="-541.414 3222.879 10.935 17.814"><defs><style dangerouslySetInnerHTML={this.styleProfile()}></style></defs><g id="Group_422"><path id="Path_5" className="cls-1" d="M.573 0h9.172a.573.573 0 1 1 0 1.146h-9.172a.573.573 0 1 1 0-1.146z" transform="matrix(.707 -.707 .707 .707 -540 3231.589)"/><path id="Path_6" className="cls-2" d="M.573 0h9.172a.573.573 0 1 1 0 1.146h-9.172a.573.573 0 0 1 0-1.146z" transform="matrix(.707 .707 -.707 .707 -539.189 3231.172)"/></g></svg></button>
            </div>
          </div>
        )}
      </div>
    )
  }
}

class AcceptedTextArea extends Component {

  render() {

    const { id, decision, reasonForDecision, setReason } = this.props

    return (
        <TextArea
          classes="active accept"
          id={id}
          title="Lisätietoa hyväksynnästä"
          decision={decision}
          reason={reasonForDecision}
          onSubmit={setReason}
        />
    )
  }
}

class DeclinedTextArea extends Component {

  defaultReason = "Lääkettä ei voida myöntää annettujen tietojen perusteella."

  render() {

    const { id, decision, reasonForDecision, onSubmit } = this.props
    const reason = !reasonForDecision || !reasonForDecision.trim() ? this.defaultReason : reasonForDecision

    return (
        <TextArea
          classes="active decline"
          id={id}
          title="Lisää peruste hylkäykselle"
          decision={decision}
          reason={reason}
          onSubmit={onSubmit}
        />
    )
  }
}

class DecisionButtons extends Component {

  constructor(props) {
    super(props)
    this.state = {
      decision: props.decision
    }
  }

  componentWillReceiveProps(props) {
    if (this.props.decision !== props.decision) {
      this.setState({ decision: props.decision })
    }
  }

  phase = () => {
    return this.state.decision === apiConstants.PRESCRIPTION_DECISION_UNDECIDED ? 1 : 2
  }

  decisionButtonClasses = () => {
    return this.phase() === 1 ? ' active' : ''
  }

  submitRejection = (id, reason) => {
    this.props.decline(id, reason)
  }

  renderTextBox = (id, decision, other) => {

    const { onSubmit, ...rest } = other

    if (decision === apiConstants.PRESCRIPTION_DECISION_REJECTED_WITH_COUPON) {
      return <DeclinedTextArea id={id} { ...rest } onSubmit={this.submitRejection} />
    } else if (decision === apiConstants.PRESCRIPTION_DECISION_ACCEPTED) {
      return <AcceptedTextArea id={id} { ...rest } />
    }

    return null
  }

  decline = () => {
    this.setState({ decision: apiConstants.PRESCRIPTION_DECISION_REJECTED_WITH_COUPON })
  }

  render() {

    const { id, accept, decline, decision, ...rest } = { ...this.props, decision: this.state.decision }

    return (
        <div className="prescription__tasks">        
          <Buttons classes={this.decisionButtonClasses()} accept={() => accept(id)} decline={this.decline} />
          { this.renderTextBox(id, decision, rest) }
        </div>
      )
  }
}

class Title extends Component {

  renderTime = () => {

    const datetime = new Date(this.props.decisionTime)
    const date = datetime.toLocaleDateString("fi-FI", { timezone: 'EET' })
    const time = datetime.toLocaleTimeString("fi-FI", { timezone: 'EET' })

    return (<div className="prescription__datetime" key="1"><b>{date}</b> - {time}</div>)
  }

  renderTitle = () => {

    const { decision, originalTitle } = this.props
    let title = originalTitle
    let status = ''

    if (decision !== apiConstants.PRESCRIPTION_DECISION_UNDECIDED) {
      
      if (decision === apiConstants.PRESCRIPTION_DECISION_ACCEPTED) {
        title = 'HYVÄKSYTTY'
        status = ' accepted'
      } else {
        title = 'HYLÄTTY'
        status = ' declined'
      }
      return [ <div key="0" className={"prescription__type" + status}>{title}</div>, this.renderTime() ]
    }

    return (<div className="prescription__type">{title}</div>)
  }

  render() {
    return this.renderTitle()
  }
}

class Prescription extends Component {

  decisionReason = () => {

    let prescription = this.props.chosenPrescription
    
    if (prescription.decision === 'approved') {
      return <div className="prescription__reason--doctor"><span className="prescription__reason--doctor-decision">Hyväksynnän peruste:</span> {prescription.reasonForDecision}</div>
    }
    else if (prescription.decision === 'rejected' || prescription.decision === 'rejected-with-coupon') {
      return <div className="prescription__reason--doctor"><span className="prescription__reason--doctor-decision">Hylkäyksen peruste:</span> {prescription.reasonForDecision}</div>
    }
    return prescription.decisionReason
  }

  render() {

    const { medicalProblem, chat, decisionReason, reasonGiven, readOnly, ...rest } = this.props
    return (
      <div className={`prescription-container prescription ${readOnly && "readonly"}`}>
        <Title originalTitle="Reseptipyyntö" { ...rest } />
        <div className="prescription__drug">{medicalProblem}</div>

        {
          reasonGiven ?
          this.decisionReason() :
          !readOnly && <DecisionButtons {...rest} />
        }

        <ChatView chat={chat} />
      </div>
      )
  }  
}

class Renewal extends Component {

  decisionReason = () => {

    let prescription = this.props.chosenPrescription

    if (prescription.decision === 'approved') {
      return <div className="prescription__reason--doctor"><span className="prescription__reason--doctor-decision">Hyväksynnän peruste:</span> {prescription.reasonForDecision}</div>
    }
    else if (prescription.decision === 'rejected' || prescription.decision === 'rejected-with-coupon') {
      return <div className="prescription__reason--doctor"><span className="prescription__reason--doctor-decision">Hylkäyksen peruste:</span> {prescription.reasonForDecision}</div>
    }

    return null
  }

  formatPharmacyInfo() {
    const { pharmacyData } = this.props;
    if(!pharmacyData) return '';
    if(pharmacyData.pharmacyPhone) {
      return `(${pharmacyData.pharmacy}, ${pharmacyData.pharmacyPhone}, ${pharmacyData.pharmacist})`;
    }
    return `(${pharmacyData.pharmacy}, ${pharmacyData.pharmacist})`;
  }


  render() {

    const { displayName, reason, format, decisionReason, reasonGiven, readOnly, pharmacyData, ...rest } = this.props
    return (
      <div className={`prescription-container prescription renewal ${readOnly && "readonly"}`}>
        <Title originalTitle="Uusinta" { ...rest } />
        <div className="prescription__drug">{displayName}</div>
        <div className="prescription__reason">{`${reason} ${this.formatPharmacyInfo()}`}</div>
        <div className="prescription__medicinetype_header">Lääkemuoto:</div>
        <div className="prescription__medicinetype">{format}</div>

        {
          reasonGiven ?
          this.decisionReason() :
          !readOnly && <DecisionButtons {...rest} />
        }

      </div>
      )
  }  
}

export class OrderPrescription extends Component {

  renderProduct() {

    const reason = this.props.chosenPrescription.reasonForDecision
    const reasonGiven = reason && reason.trim()

    if (this.props.chosenPrescription.type === 'renewal')
      return <Renewal { ...this.props.chosenPrescription } { ...this.props } reasonGiven={reasonGiven} />
    else
      return <Prescription { ...this.props.chosenPrescription } { ...this.props } reasonGiven={reasonGiven} />
  }

  render() {
    return this.props.chosenPrescription ? this.renderProduct() : null
  }
}

export default OrderPrescription
