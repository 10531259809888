import React, { Component } from 'react'
import PrivacyPolicyText from '../common/TietosuojaSelosteText';

export class PrivacyPolicy extends Component {

  render() {
    return (
      <div className=" profile__container legal-text">
        <PrivacyPolicyText />
      </div>
    )
  }
}

export default PrivacyPolicy
