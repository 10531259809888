export const chat = {
	1: {
        question: 'Tarvitsemme sinulta hieman taustatietoja oireistasi ja terveydentilastasi.<br /><br />Kertoisitko ensin liittyyvätkö oireet:<br /><br /><strong>Nenään</strong> (kirkaseritteinen nuha, tukkoisuus, aivastelu)<br /><br /><strong>Silmiin</strong> (kutina, vetisyys)',
        type: 'oneOf',
		answers: [{
			text: 'Nenään',
            next: 2
		},{
            text: 'Silmiin',
            next: 2
		},{
            text: 'Nenään ja silmiin',
            next: 2
        }]
    },
    2: {
        question: 'Allergiaoireita voi esiintyä:<br /><br /><strong>Kausiluonteisena</strong> (jaksottainen oireilu) tai <strong>ympärivuotisena</strong> (jatkuva oireilu).<br /><br />Miten allergiaoireesi esiintyy?',
		type: 'oneOf',
		answers: [{
			text: 'Kausiluonteisena',
			next: 3
		}, {
			text: 'Ympärivuotisena',
			next: 3
        }]
    },
    3: {
        question: 'Mille kaikelle olet allerginen?',
        type: 'textInput',
        answers: [],
        next: 4
    },
    4: {
        question: 'Oletko raskaana?',
        type: 'oneOf',
		answers: [{
			text: 'Kyllä',
			next: 5
		}, {
			text: 'Ei',
			next: 5
		}]
    },
    5: {
        question: 'Onko sinulla lääkeaine&shy;allergioita?',
        type: 'oneOf',
		answers: [{
			text: 'Kyllä',
			next: 6
		}, {
			text: 'Ei',
			next: 7
		}]
    },
    6: {
        question:'Kertoisitko mitä lääkeaineallergioita sinulla on?',
        type: 'textInput',
        placeholder: 'Allergiani',
        answers: [],
        next: 7
    },
    7: {
        question:'Kiitos vastauksistasi. Ilmoittamasi tietojen perusteella reseptipyyntösi voidaan välittää eteenpäin Reseptorin lääkärille, joka tekee lopullisen päätöksen reseptin myöntämisestä.',
        answers: null,
        canOrder: true,
        discardOnSave: true,
    }    
}