/* eslint-disable no-unused-expressions */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { actions } from '../ducks/session'
import { selectors } from '../ducks/session'

import { PharmacyNavigation } from '../../common/NavigationBar';
import UserList from './UserList'
import UserInfo from './UserInfo'
import NewUser from './NewUser'
import DeleteUser from './DeleteUser'
import NotificationBar from '../app/NotificationBar';

import 'react-responsive-modal/lib/react-responsive-modal.css'
import Modal from 'react-responsive-modal/lib/css'



export class PharmacyAdmin extends Component {

    constructor(props) {
        super(props)
        this.state = {
            chosenUser: null,
            newUserModalOpen: false,
            deleteUserModalOpen: false,
            usernameOfDeleteUser: null
        }
    } 

    componentDidMount() {
        this.props.fetchPharmacyUsers()
        this.props.getMessageStatus();
        this.props.getMessage();

        const interval = setInterval(() => {
            this.props.getMessageStatus();
            this.props.getMessage();
        }, 10000)

        this.setState({ 
            interval: interval
        })
    }



    componentWillUnmount = () => {
        clearInterval(this.state.interval)
    }

  componentWillReceiveProps(nextProps) {
      if(!this.state.chosenUser && (nextProps.currentUsers && nextProps.currentUsers.pharmacyusers.length > 0)) {
        this.setState({ chosenUser: nextProps.currentUsers.pharmacyusers[0].id });
      }
  }

  onOpenNewUserModal = () => {
    this.setState({ newUserModalOpen: true });
  };

  onCloseNewUserModal = () => {
    this.setState({ newUserModalOpen: false });
  };

  onOpenDeleteUserModal = () => {
    this.setState({ deleteUserModalOpen: true });
  };

  onCloseDeleteUserModal = () => {
    this.setState({ deleteUserModalOpen: false });
  };

  getCurrentUser = () => {
    return this.state.chosenUser === null ? null : this.props.currentUsers.pharmacyusers.find(p => p.id === this.state.chosenUser)
 }

  chooseUser = (id) => {
      console.log("chooseUser",id)
    this.setState({ chosenUser: id })
  }

  saveUser = (first,last,user,pass) => {
    this.props.createPharmacyUser(first,last,user,pass)
  }

  updateUser = (first,last,user,pass) => {
    console.log("update",this.state.chosenUser, first,last,user,pass)
    this.props.updatePharmacyUser(this.state.chosenUser, first,last,user,pass)
  }

  confirmDeleteUser = (username) => {
    this.setState({usernameOfDeleteUser: username})
    this.onOpenDeleteUserModal()
  }

  deleteUser = () => {
    console.log("delete:",this.state.usernameOfDeleteUser)
    this.props.deletePharmacyUser(this.state.usernameOfDeleteUser)
    this.setState({usernameOfDeleteUser: null})
    this.onCloseDeleteUserModal();
  }
  handleNotification = () => {
    if( this.props.messageStatus) {
        this.props.uncheckMessage()
    } else {
        this.dismissNotification()
    }
}
  dismissNotification = () => {
    this.props.checkMessage()
 }


  render() {
    const chosenUser = this.getCurrentUser()
    const { newUserModalOpen, deleteUserModalOpen  } = this.state;

    return (
        <div className="pharmacy pharmacy-admin">

            <PharmacyNavigation
                loggedIn={true}
                isAdmin={true}
                apteekki={this.props.currentOffice}
                navMessage={this.props.currentMessage}
                handleNotification={this.handleNotification}
                checkStatus={this.props.messageStatus}
            />

            <NotificationBar
                    message={this.props.currentMessage}
                    dismissNotification={this.dismissNotification}
                    messageStatus={this.props.messageStatus}
                />

            <div className="pharmacy-admin-panel">
                <div className="view-title">
                    <h2>Käyttäjätunnukset {this.state.etunimi} </h2>
                    <button className="pharmacy-add-user" onClick={this.onOpenNewUserModal}><i className="fas fa-plus-circle"  data-fa-transform="grow-2" ></i> Lisää käyttäjä</button> 

                </div>

                <div className="pharmacy-user-content">
                    <div className="pharmacy-user-list">
                        <UserList 
                            pharmacyusers={this.props.currentUsers}
                            chooseUser={this.chooseUser}
                            chosenUser={chosenUser}
                        />
                    </div>
                    <UserInfo
                        confirmDeleteUser={this.confirmDeleteUser}
                        updateUser={this.updateUser}
                        chosenUser={chosenUser}
                    />
                </div>
            </div>


            <Modal 
                open={newUserModalOpen} 
                onClose={this.onCloseNewUserModal} 
                classNames={{modal:'new-user-modal'}}>
                <NewUser
                    onClose={this.onCloseNewUserModal}
                    saveUser={this.saveUser}
                />
            </Modal>

            <Modal 
                open={deleteUserModalOpen} 
                onClose={this.onCloseDeleteUserModal} 
                classNames={{modal:'delete-user-modal'}}>
                <DeleteUser
                    onClose={this.onCloseDeleteUserModal}
                    deleteUser={this.deleteUser}
                />
            </Modal>

        </div>
    )
  }
}

export default connect(
    state => ({
        currentOffice: selectors.getCurrentOffice(state),
        currentUsers: selectors.getCurrentPharmacyUsers(state),
        user_saved: state.pharmacist.session.pharmacy_user_meta.saved,
        userSaveFailed: state.pharmacist.session.pharmacy_user_meta.save_failed,
        currentMessage: selectors.getCurrentMessage(state),
        messageStatus: selectors.getMessageStatus(state),
    }), 
    { 
        ...actions 
    }
)(PharmacyAdmin)



