import React, { Component } from 'react'

import { apiConstants } from '../../common/constants'

export default class SendToClient extends Component {
	
	toggleCoupon = (e) => {

		const p = this.props.order.prescriptions.find(p => p.id === parseInt(e.target.value))

		if (p.decision === apiConstants.PRESCRIPTION_DECISION_REJECTED) {
			this.props.decline(p.id, p.reasonForDecision, apiConstants.PRESCRIPTION_DECISION_REJECTED_WITH_COUPON)
		} else {
			this.props.decline(p.id, p.reasonForDecision, apiConstants.PRESCRIPTION_DECISION_REJECTED)
		}
	}

	rejectedPrescriptions = () => {
		return this.props.order.prescriptions
			.filter(p =>
				p.decision === apiConstants.PRESCRIPTION_DECISION_REJECTED
				|| p.decision === apiConstants.PRESCRIPTION_DECISION_REJECTED_WITH_COUPON
			)
	}

	render() {

		const order = this.props.order
		const rejected = this.rejectedPrescriptions()

		return (
          <div className="content__side">
            <h2 className="title">Lähetä tilauksen tiedot asiakkaalle</h2>
            {
            	!rejected.length || (
        		<div>
	            	<p className="side__text">Luo kuponkikoodi hylätyille resepteille</p>

		            <div className="checkbox-container">
		            	{
			            	rejected.map(p => (
			    				<div className="checkbox-container-item" key={p.id}>        		
					                <input
						                className="card__checkbox"
						                type="checkbox" 
						                id={'checkboxid_' + p.id}
						                onChange={this.toggleCoupon}
						                value={p.id}
						                checked={p.decision === apiConstants.PRESCRIPTION_DECISION_REJECTED}
					                />
									<label htmlFor={'checkboxid_' + p.id} className="card__label--checkbox">
										{p.displayName || p.medicalProblem}
									</label>
								</div>
							))
		            	}
		            </div>
	            </div>
	            )
          	}
        
            <button className="order__done" onClick={() => this.props.submitOrder(order.id)}>Lähetä</button>

            { /* näytä tämä kun valmis  eli jononäkymä
            <QueueSwitchPanel />
            <button className="next-order btn--green">Seuraava työ</button>
             */ }
                  
          </div>

		)
	}
}