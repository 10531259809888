import React, { Component } from 'react'
import OrderPrescription from './OrderPrescription'
import ContentHeader from './ContentHeader'
import PrescriptionList from './PrescriptionList'

import { apiConstants } from '../../common/constants'

import SendToClient from './SendToClient'

export class OrderContainer extends Component {

  constructor(props) {
    
    super(props)
    this.state = {
      orderDone: false,
      chosenPrescription: props.readOnly ? props.currentOrder.prescriptions[0].id : null,
    }
  }

  choosePrescription = (id) => {
    this.setState({ orderDone: false, chosenPrescription: id })
  }
  
  getCurrentPrescription = () => {
    return this.state.chosenPrescription === null ? null : this.props.currentOrder.prescriptions.find(p => p.id === this.state.chosenPrescription)
  }

  submitOrder = () => {
    this.props.submitOrder(this.props.currentOrder.id)
    this.setState({ ...this.state, chosenPrescription: null })
  }

  nextPrescription = () => {
    this.choosePrescription(this.props.currentOrder.prescriptions.find(p => p.decision === 'undecided').id)
  }

  releaseOneOrder = (orderId) => {
    this.props.releaseOneOrder(orderId);
    this.props.resetOrderState(); // to refresh main view's state
  }

  renderOrder() {
    const order = this.props.currentOrder
    const { patient } = order
    const orderStatus = order.deliveryStatus === 'processing' ? { text: 'Käsitelty', onSubmit: () => this.submitOrder() } : { text: 'Sulje', onSubmit: () => this.setState({ orderDone: true }) }
    const orderDisabled = order.prescriptions.filter(p => p.decision === apiConstants.PRESCRIPTION_DECISION_UNDECIDED).length > 0
    const chosenPrescription = this.getCurrentPrescription()

    return (
      <div className={this.props.scope==='history' ? "order__container order__approved" : "order__container"}>

        <ContentHeader
          patient={patient}
          order={order}
          chosenPrescription={chosenPrescription}
          onSubmit={orderStatus.onSubmit}
          onCancel={() => this.releaseOneOrder(order.orderId)}
          readOnly={this.props.readOnly}
          scope={this.props.scope}
        />

        <PrescriptionList
          prescriptions={order.prescriptions}
          chosenPrescription={chosenPrescription}
          choosePrescription={this.choosePrescription}
          cancelDecision={this.props.cancelDecision}
          readOnly={this.props.readOnly}
        />

        <main className="content__main">
          { this.state.orderDone ? this.renderOrderDone() : this.renderPrescription() }
        </main>

        {
          (!orderDisabled && !this.props.readOnly) && <SendToClient order={order} decline={this.props.decline} submitOrder={this.props.submitOrder} />
        } 

      </div>
    )
  }

  renderOrderDone() {
    return (
    <div>
      <h1>Tilaus käsitelty</h1>
      <p>{new Date().toLocaleDateString()}</p>
      </div>
    )
  }

  renderPrescription() {

    const chosenPrescription = this.getCurrentPrescription()

    return (
          <OrderPrescription
            chosenPrescription={chosenPrescription}
            decline={this.props.decline}
            accept={this.props.accept}
            cancel={this.props.cancelDecision}
            setReason={this.props.setReason}
            readOnly={this.props.readOnly}
          />
    )
  }

  render() {
    return (
      this.props.currentOrder ? this.renderOrder() : null
    )
  }
}

export default OrderContainer
