import { actionUtils } from './common'

export const types = {
	REQUEST: Symbol('ui/REQUEST'),
	RECEIVE: Symbol('ui/RECEIVE'),
	HIDE_FEEDBACK: Symbol('ui/HIDE_FEEDBACK'),
	LOGIN: Symbol('ui/LOGIN'),
	COUPON_SUBMITTED: Symbol('ui/COUPON_SUBMITTED')
}

let initialState = {
	fetching: false,
	showFeedbackForm: false,
	feedbackShown: false,
	loginFailed: false,
	couponSubmitted: false
}

export default (state = initialState, action) => {

	switch (action.type) {

		case types.REQUEST:
			return {
				...state,
				fetching: true
			}

		case types.RECEIVE:
			return {
				...state,
				fetching: false
			}

		case types.HIDE_FEEDBACK:
			return {
				...state,
				showFeedbackForm: false
			}

		case types.LOGIN:
			return {
				...state,
				loginFailed: !action.loginSuccess
			}

		case types.COUPON_SUBMITTED:
			return {
				...state,
				couponSubmitted: action.couponSubmitted,
			}

		default:
			return state
	}
}

export const selectors = {
	isFetching: (state) => state.ui.fetching,
	showFeedbackForm: (state) => { return state.session.user && state.ui.showFeedbackForm }
}

export const actions = {
	startRequest: () => ({ type: types.REQUEST }),
	endRequest: () => ({ type: types.RECEIVE }),
	login: (loginSuccess) => ({ type: types.LOGIN, loginSuccess: loginSuccess }),
	couponSubmitted: (submitted) => ({ type: types.COUPON_SUBMITTED, couponSubmitted: submitted }),
	hideFeedback: () => actionUtils.action(types.HIDE_FEEDBACK),
	showFeedback: () => state => {
console.log(state)
	}
}