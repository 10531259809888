import { call, put, select } from 'redux-saga/effects'
import { actions } from '../ducks/ui'
import { types as productTypes } from '../ducks/product'
import { types as sessionTypes } from '../ducks/session'
import { types as doctorTypes } from '../doctor/ducks/session'
import { types as pharmacistTypes } from '../pharmacy/ducks/session'

import { push } from 'react-router-redux'			

import api from '../api'

export function* makeRequest() {

	// "Arguments is not an array" :(
	var args = [].slice.call(arguments)
	
	const response = yield call(...args)

	try {
		
		if (!response.ok) {
			throw response
		}

		return response
	
	} catch (response) {
	
		const status = response.status

		if (status === 401) {
			console.log("Session expired. Redirect to login.")
			yield put(push('/login'))
		} else {
			throw response
		}
	}
}

const fetchDoctor = makeRequest.bind(null, api.fetchDoctor)
const fetchSystemStatus = makeRequest.bind(null, api.fetchSystemStatus)
const orderQueue = makeRequest.bind(null, api.orderQueue)

const fetchCart = makeRequest.bind(null, api.fetchCart)
const completedOrdersFetcher = makeRequest.bind(null, api.fetchCompletedOrders);

const fetchPharmacist = makeRequest.bind(null, api.fetchPharmacist)
const fetchPharmacistOffices = makeRequest.bind(null, api.fetchPharmacistOffices)
const fetchSelectedOffice = makeRequest.bind(null, api.getSessionOffice);

export function* fetchSystemStatusSaga() {

	try {

		const response = yield call(fetchSystemStatus)
    const system = yield response.json()
    
    const queueResponse = yield call(orderQueue)
		const queue = yield queueResponse.json()

		yield put({
      type: doctorTypes.RECEIVE_SYSTEM_STATUS,
      system: system,
      orderQueue: queue.data
    });

		if (system.pickedOrder) {
			yield put({ type: doctorTypes.RECEIVE_NEXT_ORDER, order: system.pickedOrder })
		}

	} catch (e) {
		console.log('Fetching next order failed with ', e)
	}

}

const sortDrugs = (p1, p2) => {

	const nameOrder = p1.name.toLowerCase().localeCompare(p2.name.toLowerCase())

	if (!nameOrder) {

		const laakemuoto1 = p1.data.laakemuoto.toLowerCase()
		const laakemuoto2 = p2.data.laakemuoto.toLowerCase()
		
		const formatOrder = laakemuoto1.localeCompare(laakemuoto2)

		if (!formatOrder) {
			return p1.data.vahvuus.toLowerCase().localeCompare(p2.data.vahvuus.toLowerCase())
		}

		return formatOrder
	}

	return nameOrder
} 

export function* fetchProductData() {

	let products = yield select(getProducts)
	if (products.length) {
		// Products already fetched. Return them.
		return
	}

	try {

		const response = yield call(fetchProducts)

		if (!response.ok) {
			throw new Error('API call failed with ', response)
		}

		const data = yield response.json()

		const products = data.data.filter(p => p.available).sort(sortDrugs).map(p => (
			{
				productId: p.id,
				displayName: p.name + ' ' + p.data.vahvuus,
				format: p.data.laakemuoto
			}
		))

		yield put({
			type: productTypes.RECEIVE_PRODUCT_DATA,
			products: products
		})

	} catch (e) {
		console.log('Fetching products failed ', e)
	}
}

export function* fetchDoctorSession() {

	const fetchResponse = yield call(fetchDoctor)
	const data = yield fetchResponse.json()

	yield put({ type: doctorTypes.RECEIVE_LOGIN, user: data })

	yield call(fetchSystemStatusSaga)
	yield call(fetchProductData)
}

export function* fetchPharmacistSession() {
	const fetchResponse = yield call(fetchPharmacist)
	const data = yield fetchResponse.json()
	
	yield put({ type: pharmacistTypes.RECEIVE_PHARMACIST_DATA, data: data })

	yield call(getOffices)
	yield call(fetchProductData)
	const selectedOfficeResponse = yield call(fetchSelectedOffice);
	const selectedOffice = yield selectedOfficeResponse.json();
	if(selectedOffice) {
		yield put({ type: pharmacistTypes.RECEIVE_OFFICE, data: selectedOffice });
	} else {
		yield put(push('/toimipaikka'));
	}
}

export function* getOffices() {
	console.log("getOffices")
	const fetchResponse = yield call(fetchPharmacistOffices)
	const data = yield fetchResponse.json()
	console.log('fetchPharmacistOffices', data)
	yield put({ type: pharmacistTypes.RECEIVE_CURRENT_OFFICES, offices: data })
}

export function* putCurrentCart(cart) {

	yield put({
		type: sessionTypes.RECEIVE_CART,
		cart: cart
	})	
}

export function* fetchCurrentCart() {

	const response = yield call(fetchCart)

	if (!response.ok) {
		throw new Error('API call failed with ', response)
	}

	const data = yield response.json()

	yield call(putCurrentCart, data)
}

export function* fetchCompletedOrders(filter, sortOrder, offset) {
	yield put(actions.startRequest());
	const response = yield call(completedOrdersFetcher, filter, sortOrder, offset);
	const orderData = yield response.json();

	yield put({ type: doctorTypes.RECEIVE_COMPLETED_ORDERS, payload: { data: orderData.data, count: orderData.count }});
	yield put(actions.endRequest());
}

export const getProducts = state => state.product.products
const fetchProducts = makeRequest.bind(null, api.fetchProducts)
