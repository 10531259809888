import React, { Component } from 'react'

class MarketingText extends Component {

	render() {
		return (
        <div className="modal-text">
          <h2>Markkinointiviestintä</h2>
          <p>Hyväksymällä markkinointiviestien vastaanottamisen annat suostumuksesi antamiesi yhteystietojen käyttämiseen markkinointitarkoituksiin, mukaan lukien sähköiseen suoramarkkinointiin ja annat suostumuksesi siihen, että tietojasi saa luovuttaa Terveyspalvelu Reseptori Oy:n yhteistyökumppaneille, mukaan lukien kolmansissa maissa sijaitseville kumppaneille, henkilötietojen tallentamista ja muuta henkilötietojen käsittelyä varten, kaupallisissa tarkoituksissa sekä kehittääksemme liiketoimintaamme sekä vakuutat tutustuneesi Terveyspalvelu Reseptori Oy:n tietosuojaselosteeseen, jossa on tarkemmat kuvaukset henkilötietojen käsittelystä ja luovuttamisesta ja tulleesi tietoiseksi oikeuksistasi rekisteröitynä ja henkilötietojesi käsittelyyn liittyvään asianmukaiseen informaatioon. </p>
        </div>
		)
	}
}

export default MarketingText