import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
	types
} from '../ducks/registration'

import { actions as sessionActions } from '../ducks/session'

import { push } from 'react-router-redux'

import api from '../api'
import { makeRequest } from './common'
import { loginUser } from './session'

const fetchRegistration = makeRequest.bind(null, api.fetchRegistration)
const createUser = makeRequest.bind(null, api.createUser)

function* fetchTupasData() {

	try {

		const response = yield call(fetchRegistration)

		if (!response.ok) {
			throw new Error('API call failed with ', response)
		}

		const data = yield response.json()

		yield put({
			type: types.RECEIVE_TUPAS_DATA,
			data: data
		})

	} catch (e) {
		console.log('Returning from TUPAS failed ', e)
		yield put(push('/'))
	}
}

function* create() {

	try {

		const r = yield select(state => state.registration)

		const data = {
		  		firstName: r.firstName,
		  		lastName: r.lastName,
		  		email: r.email,
		  		phone: r.phone,
		  		password: r.password,
		  		allowMarketing: r.marketingAllowed,
		  		approvedEulaVersion: "1"
		  	}

		yield call(createUser, data)
		yield put(sessionActions.receiveUserData(data))
		yield call(loginUser, r.email, r.password)
		// yield call(fetchCurrentCart)

		yield put(push('/reseptit'))

	} catch (e) {
		yield put({ type: 'REGISTRATION_FAILED' , error: e})
	}
}

export function* tupasListener() {
	yield takeLatest('FETCH_REGISTRATION', fetchTupasData)
}

export function* createUserListener() {
	yield takeLatest('CREATE_USER', create)
}
