import React, { Component } from 'react'
//import { apiConstants } from '../../common/constants'

export default class User extends Component {


  render() { 
    /*
    let typeText = this.props.type === 'renewal' ? 'Uusinta' : 'Reseptipyyntö'
    let active = this.props.active ? " active" : ''
    

    if (this.props.decision !== apiConstants.PRESCRIPTION_DECISION_UNDECIDED) {
      active += this.props.decision === apiConstants.PRESCRIPTION_DECISION_ACCEPTED ? ' accepted' : ' declined'
    }
    */
    let active = this.props.active ? " active" : ''

    return (
    
        <div className={"user-item" + active} onClick={this.props.onClick.bind(null, this.props.userId)}>
        {this.props.firstname} {this.props.lastname} <i className="fas fa-chevron-right"></i>
        { /*
      <li>
        <div className={"prescription__order "+ this.props.type + active} onClick={this.props.onClick.bind(null, this.props.prescriptionId)}>
          <div className="type">{typeText}</div>
          <div className="name">{this.props.name}</div>
          <div className="undo-button" onClick={() => this.onAcceptedAndUndoClick(this.props.prescriptionId)}></div>            
        </div>
      </li>
        */ }
        </div>
    )
  }
}