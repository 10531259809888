import React, { Component } from 'react'
import TosText from "../common/TosText"

export class TermsOfService extends Component {

  render() {
    return (
      <div className="profile__container legal-text">
      <TosText />
      </div>
    )
  }
}

export default TermsOfService
