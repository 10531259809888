import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export class Navbar extends Component {

  styleProfile() {
		// return {__html: '.cls-1 {opacity: 0.8;}'}
  }
  
  linkClass(viewName) {
    // eslint-disable-next-line no-restricted-globals
    return viewName === location.pathname ? 'active' : '';
  }
  
  render() {
    return (
      <div className="nav-container">
        <nav>
          <Link to="/reseptit" className={this.linkClass('/reseptit')}>
            <svg xmlns="http://www.w3.org/2000/svg" className="prescription" viewBox="0 0 22 27"> 
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1"><path d="M20,2V25H2V2H20m1-2H1A1,1,0,0,0,0,1V26a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V1a1,1,0,0,0-1-1Z"/><path d="M15,8H7A1,1,0,0,1,7,6h8a1,1,0,0,1,0,2Z"/><path d="M15,13H7a1,1,0,0,1,0-2h8a1,1,0,0,1,0,2Z"/><path d="M15,20H7a1,1,0,0,1,0-2h8a1,1,0,0,1,0,2Z"/></g>
              </g>
            </svg >
          </Link>
          <button className="button__add" onClick={this.props.openChat}>
            <svg xmlns="http://www.w3.org/2000/svg" className="add_recipe" viewBox="0 0 27 27">
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1"><path d="M25.5,15H1.5a1.5,1.5,0,0,1,0-3h24a1.5,1.5,0,0,1,0,3Z"/><path d="M13.5,27A1.5,1.5,0,0,1,12,25.5V1.5a1.5,1.5,0,0,1,3,0v24A1.5,1.5,0,0,1,13.5,27Z"/></g>
              </g>
            </svg>
          </button>
          <Link to="/profile" className={this.linkClass('/profile')}>
            <svg className="profile" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1"><path d="M16,25.29C10.38,25.29,6.15,23,6.15,20c0-2.28,2-4.09,5.43-4.84l.61-.14.39.49a5.29,5.29,0,0,0,3.78,1.6A5.36,5.36,0,0,0,20,15.67l.41-.48.61.16C25.38,16.5,25.85,19,25.85,20,25.85,23,21.62,25.29,16,25.29Zm-4.53-8c-2.09.57-3.32,1.57-3.32,2.75,0,1.53,3.36,3.25,7.85,3.25s7.85-1.72,7.85-3.25c0-1.09-1-2-2.76-2.6a7.73,7.73,0,0,1-4.73,1.71A7.6,7.6,0,0,1,11.47,17.29Z"/><path d="M16,2A14,14,0,1,1,2,16,14,14,0,0,1,16,2m0-2A16,16,0,1,0,32,16,16,16,0,0,0,16,0Z"/><path d="M16.35,16a4.75,4.75,0,1,1,4.75-4.75A4.75,4.75,0,0,1,16.35,16Zm0-7.5a2.75,2.75,0,1,0,2.75,2.75A2.75,2.75,0,0,0,16.35,8.5Z"/></g>
              </g>
            </svg>
          </Link>
        </nav>

      {
        this.props.cartDirty ?
          <Link to="/handling" className="checkout-link" id="btn-siirry-kassalle">
            <button className="checkout active">
              <div>Siirry kassalle</div>
              <div className="price">{this.props.cartPriceEuro}</div>
            </button>
          </Link>
        :
          null
      }

      </div>
    )
  }
}

export default Navbar
