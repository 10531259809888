import React, { Component } from 'react'

export class ButtonChatBack extends Component {

  getStyle() {
    /* TODO: väliaikainen SVG, vaihda uuteen */
		return {__html: '#Group_422 .cls-1 { fill: #fff; } #Group_422 .cls-1, .cls-2 { stroke: #fff; stroke-width: 2px; } #Group_422 .cls-2 { fill: none; }'}
  }
  
  render() {
    /* TODO: väliaikainen SVG, vaihda uuteen */
    return (
      <button className="chat__back" onClick={this.props.closeChat}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-541.414 3222.879 10.935 17.814">
          <defs>
            <style dangerouslySetInnerHTML={this.getStyle()}>
            </style>
          </defs>
          <g id="Group_422">
            <path id="Path_5" className="cls-1" d="M.573 0h9.172a.573.573 0 1 1 0 1.146h-9.172a.573.573 0 1 1 0-1.146z" transform="matrix(.707 -.707 .707 .707 -540 3231.589)"/>
            <path id="Path_6" className="cls-2" d="M.573 0h9.172a.573.573 0 1 1 0 1.146h-9.172a.573.573 0 0 1 0-1.146z" transform="matrix(.707 .707 -.707 .707 -539.189 3231.172)"/>
          </g>
        </svg>
      </button>
    )
  }
}

export default ButtonChatBack
