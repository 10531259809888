import React, { Component } from 'react'

class TietosuojaSelosteText extends Component {

	render() {
		return (
        <div className="modal-text">
          <h2>Tietosuojaseloste</h2>
          <p>TERVEYSPALVELU RESEPTORI OY:N TIETOSUOJA-SELOSTE<br />
          REKISTERISELOSTE JA INFORMOINTIASIAKIRJA REKISTERÖIDYLLE v.0.1 – 22.2.2018<br />
          Euroopan parlamentin ja neuvoston asetuksen (EU) 2016/679, ”Yleinen tietosuoja-asetus”) ja henkilötieto-lain 10 & 24 §:n perusteella.</p>
          
          <h3>Yleistä</h3>
          <p>Tämä on Terveyspalvelu Reseptori Oy:n informointiasiakirja, jonka tarkoituksena on informoida teitä henkilötietojenne käsittelystä, käsittelyn perusteista sekä oikeuksistanne toimestamme tapahtuvaan henkilötietojenne käsittelyyn.</p>
          <p>Pyydämme teitä perehtymään huolellisesti tähän asiakirjaan ja ottamaan yhteyttä meihin, mikäli teillä tulee kysyttävää asian suhteen.</p>
        
          <h3>Rekisterinpitäjä</h3>
          <p>Nimi: Terveyspalvelu Reseptori Oy</p>
          <p>Osoite: Teknobulevardi 3-5<br /> 01530 Vantaa</p>
          <p>Sähköpostiosoite: info@reseptori.fi</p>

          <h3>Tietosuojavastaava</h3>
          <p>Nimi: Karri Wirén (Concludentia/Wirka Oy)<br />
          Osoite: Mannerheimintie 12 B<br />          
          Sähköpostiosoite: karri.wiren@concludentia.com<br />          
          Puhelinnumero: 040 569 3475<br />          
          Verkkosivut www.concludentia.com</p>
          
          <h3>Rekisteri</h3>
          <p>Terveyspalvelu Reseptori Oy:n asiakas- ja potilasrekisteri</p>    


          <h3>Yhteisrekisteri eri itsenäisten rekisterinpitäjien välillä</h3>
          <p> Kyseessä on yhteisrekisteri Terveyspalvelu Reseptori Oy:n ja sen kanssa yhteistyötä tekevien itsenäisten ammatinharjoittajien tai erillisten yhtiöiden kautta toimivien ammatinharjoittajien välillä.</p>
          <p>Edellä mainittujen tahojen rekisterit ovat teknisesti eri rekistereitä ja itsenäiset ammatinharjoittajat tai edellä tarkoitetut erilliset yhtiöt ovat itsenäisiä rekisterinpitäjiä ja vastaavat omalta osaltaan rekisterinpidosta ja siihen liittyvistä velvollisuuksista. </p>
          <p>Terveyspalvelu Reseptori Oy:n vastaa asiakas- ja potilasrekisteriin liittyvästä tietosuojasta sekä tietoturvasta sekä rekisterin teknisestä ylläpidosta ja voitte kohdistaa rekisteriin liittyvät pyyntönne ja kysymyksenne teitä hoitaneen itsenäisen ammatinharjoittajan lisäksi Terveyspalvelu Reseptori Oy:n tietosuojavastaavalle. </p>

          <h3>Rekisteröityjen ryhmät ja henkilötietoryhmät</h3>
          <p>Teistä kerätään tietoja, koska olette:<br />
          -	Asiakkaamme / potilaamme tai teitä hoitaa meihin sopimussuhteessa oleva itsenäinen ammatinharjoittaja</p>

          <p>Teistä kerätään seuraavia tietoja:<br />
          -	Nimi<br />
          -	Puhelinnumero<br />
          -	Osoite<br />
          -	Sähköpostiosoite<br />
          -	Henkilötunnus<br />
          -	IP-osoite<br />
          -	Kanta-asiakastiedot<br />
          -	Ajanvaraustanne koskevat tiedot<br />
          -	Palveluita koskevat laskutus ja maksaja tiedot<br />
          -	Henkilötietojenne käsittelyä koskevat suostumukset ja kiellot<br />
          -	Terveydentilaanne koskevat tiedot<br />
          -	Toimittamanne terveys- ja omahoitotiedot<br />
          -	Lääketieteellisiä tutkimuksia koskevat tiedot<br />
          -	Lääkitystänne koskevat tiedot<br />
          -	Lähiomaistanne koskevat tiedot</p>


          <h3>Henkilötietojen lähteet</h3>
          <p>Teitä koskevat tiedot saadaan ensisijaisesti teiltä itseltänne sopimuksen teon yhteydessä ja asiakassuhteen aikana.</p>
          
          <p>Teitä koskevia tietoja saadaan myös seuraavista lähteistä:<br />
          -	muilta terveydenhuollon toimintayksiköiltä ja itsenäisiltä ammatinharjoittajilta, jotka ovat osallistuneet hoitoonne<br />
          -	julkiset rekisterit kuten väestörekisteri<br />
          -	yhteistyökumppaneiltamme antamanne suostumuksen perusteella</p>
          
          

          <h3>Henkilötietojen käsittelyn tarkoitus ja oikeusperusta</h3>
          <p>Teitä koskevat henkilötiedot kerätään, niitä käsitellään ja käytetään sekä tallennetaan tietojärjestelmiin:</p>

          <p>-	asiaa koskevan antamanne suostumuksen perusteella ja tietojanne käytetään tuon suostumuksen perusteella asiakassuhteen hoitamiseksi, sopimussuhteen täytäntöön panemiseksi, hoitonne toteuttamiseksi ja markkinoinnin toteuttamiseksi suostumuksestanne ilmenevissä rajoissa<br />
          -	koska olette sopimussuhteessa meihin ja/tai yhteistyökumppaniimme ja käsittely on tarpeen sopimussuhteen täytäntöön panemiseksi ja hoitonne toteuttamiseksi<br />
          -	lainsäädännöstä, erityisesti potilaan asemasta ja oikeuksista annetun lain ja kirjanpitolaista johtuvien ja näiden nojalla annettujen säädösten sisältämien meitä koskevien velvoitteiden toteuttamiseksi 
          -	yleisen edun mukaisten tehtävien suorittamiseksi kun on kyse tartuntatautien vastustamisessa tarvittavista tiedoista <br />
          -	mahdollisen myöhemmän teidän ja meidän väliseen suhteeseen liittyvän oikeusvaateen laatimiseksi, esittämiseksi ja puolustamiseksi </p>
          

          <h3>Velvollisuus antaa henkilötietoja ja antamatta jättämisen seuraukset</h3>
          <p>Teidän tulee antaa itseänne koskevia henkilötietoja potilaan asemasta ja oikeuksista annettuun lakiin ja sen perusteella annettuun potilasasiakirjaasetukseen perustuen.</p>
          <p>Teillä on velvollisuus antaa sopimussuhteeseen perustuen itseänne koskevat henkilötiedot, jotka ovat tarpeen sopimuksen täytäntöön panemiseksi, hoitonne toteuttamiseksi ja sopimussuhteeseen liittyvien oikeuksien ja velvollisuuksien toteuttamiseksi mukaan lukien sopimussuhteeseen perustuvien oikeusvaateiden esittämiseksi tai niitä vastaan puolustautumiseksi. </p>
          <p>Mikäli ette anna teitä koskevia henkilötietoja, ei sopimusta voida panna täytäntöön eikä hoitosuhdetta käynnistää.</p>
          

          <h3>Suostumuksen peruuttaminen</h3>
          <p>Teillä on oikeus peruuttaa antamanne henkilötietojenne käsittelyä koskeva suostumus. </p>
          <p>Voitte peruuttaa antamanne suostumuksen toimittamalla asiaa koskevan sähköpostin osoitteeseen karri.wiren@concludentia.com. </p>
          <p>Suostumustanne koskevalla peruutuksella ei ole vaikutusta peruutusta edeltävän henkilötietojenne käsittelyn lainmukaisuuteen </p>
          

          <h3>Henkilötietojen luovuttaminen</h3>
          <p>Tietojanne luovutetaan tai voidaan luovuttaa seuraaville vastaanottajille:<br />
          -	Tilitoimistollemme kirjanpitoomme ja laskutukseen liittyvien tehtävien hoitamiseksi <br />
          -	Tietojärjestelmän toimittajallemme tietojen tallentamista ja tietoihin liittyvän tietojärjestelmän toimittamista varten<br />
          -	Perintätoimistollemme saataviemme perimiseksi<br />
          -	Yhteistyössä kanssamme toimiville itsenäisille ammatinharjoittajille tai erillisille yrityksille hoitonne niin edellyttäessä <br />
          -	Toisille terveydenhuollon toimintayksiköille hoitonne niin edellyttäessä<br />
          -	Kelan ylläpitämään reseptiarkistoon ja Kanta-palveluiden asiakastiedon arkistoon<br />
          -	Läheisellenne tilanteissa, joissa olette tajuttomuuden tai muun vastaavan syyn vuoksi estynyt ilmaisemaan suostumustanne, ellei ole syytä olettaa, että kieltäisitte luovuttamisen<br />
          -	Tuomioistuimelle, muulle viranomaiselle tai vastaavalle taholle, jolla on lakisääteinen oikeus tietojen saantiin<br />
          -	Toiselle luonnolliselle tai oikeushenkilölle, jonka elintärkeiden etujen toteuttamiseksi tieto on tarpeen, mikäli ei ole katsottava, että teidän oikeutenne yksityisyyteen ohittaa edellä tarkoitetun käyttötarpeen oikeusperustana<br />
          -	Tietoja voidaan luovuttaa tutkimuskäyttöön siinä laajuudessa ja niiltä osin kuin lainsäädännössä todetaan</p>
          
          

          <h3>Henkilötietojen automaattinen käsittely (profilointi ja muut vastaavat toimet)</h3>
          <p>Henkilötietojenne käsittelyyn ei liity automaattista käsittelyä kuten profilointia tai muita vastaavia toimia.</p>

          <h3>Tietojen siirto kolmansiin maihin tai kansainvälisille järjestöille</h3>
          <p>Tietojanne ei siirretä kolmansiin maihin tai kansainvälisille järjestöille.</p>

          <h3>Kuvaus rekisterin suojauksen periaatteista </h3>
          <p>Tietojanne käsitellään erittäin luottamuksellisesti ja ainoastaan tässä Tietosuojaselosteessa kuvatuin tavoin ja kuvattuihin käyttötarkoituksiin.</p>
          <p>Tiedot on suojattu sekä teknisesti että organisatorisesti siten, että:<br />
          1)	tietojen käsittelyyn liittyviä laitteita voivat käyttää vain tietojen käsittelyyn oikeutetut henkilöt, mikä varmistetaan käyttäjätunnuksin ja salasanoin (Laitteille pääsyn valvonta)<br />
          2)	tietoja voivat käsitellä ainoastaan tietojen käsittelyjärjestelmän käyttöön oikeutetut henkilöt ja vain siinä laajuudessa kuin heidän tehtäviensä hoito sitä nimenomaisesti edellyttää (Pääsyn valvonta), mikä on varmistettu organisatorisin ja sopimusoikeudellisin toimen pitein<br />
          3)	laitteiden kautta tapahtuva tietojen luvaton lukeminen, jäljentäminen, muuttaminen tai poistaminen on estetty (Tietovälineiden valvonta) toteuttamalla asianmukaiset tekniset toimenpiteet kuten laitteiden kryptaus ja suojaaminen salasanoin ja käyttäjätunnuksin<br />
          4)	henkilötietojen luvaton syöttäminen järjestelmään sekä järjestelmään tallennettujen henkilötietojen luvaton tarkastelu, muuttaminen tai poistaminen on estetty käyttöoikeuksin ja sitä valvotaan lokitiedoin (Tallentamisen valvonta)<br />
          5)	estetään palomuurein ja vastaavin teknisin ratkaisuin asiattomia käyttämästä henkilötietojen käsittelyyn liittyvää tietojärjestelmää (Käyttäjien valvonta)<br />
          6)	voidaan tarkistaa ja todeta mille tahoille henkilötietoja on siirretty tai asetettu saataville tai voidaan siirtää tai asettaa saataville tiedonsiirtolaitteiden avulla (Tiedonvälityksen valvonta), mikä toteutetaan lokitietojen avulla<br />
          7)	voidaan tarkistaa ja todeta jälkikäteen, mitä henkilötietoja on syötetty automatisoituihin käsittelyjärjestelmiin, milloin niitä on syötetty ja kuka henkilötietoja on syöttänyt (Tietojen syöttämisen valvonta), joka toteutetaan lokitietojen seurannalla<br />
          8)	voidaan estää henkilötietojen asiaton lukeminen, jäljentäminen, muuttaminen tai poistaminen niitä siirrettäessä tai tietovälineitä kuljetettaessa (Siirron valvonta) mikä toteutetaan käyttämällä teknisiä tietoja kuten kryptausta ja pseudonymisointia<br />
          9)	voidaan varmistaa, että järjestelmä toimi ja että havaituista toimintojen virheistä ilmoitetaan (Luotettavuus) ja ettei järjestelmän toimintahäiriö voi vahingoittaa tallennettuja henkilötietoja (Eheys) mikä toteutetaan järjestelmään toteutettavin kuormitustestein ja muita vastaavia teknisiä toimenpiteitä käyttäen</p>


          <h3>Tietojen säilyttämisaika</h3>
          <p>Tietoja säilytetään vähintään niin kauan kuin on tarpeen tietojen käsittelyperusteeseen, kuten sopimussuhteeseen liittyvän, perustuvan oikeudellisen vaateen laatimiseksi, esittämiseksi tai puolustamiseksi.</p> 
          <p>Mikäli tietojen säilyttäminen perustuu lainsäädäntöön, säilytetään tietoja vähintään sanotun lainsäädännön mukainen vähimmäisaika. Potilastietojen osalta tietojen säilyttämisaika määräytyy potilasasiakirja-asetuksen liitteessä todetun mukaisesti.</p> 
          
          

          <h3>Tietojen käyttäminen muihin kuin edellä esitettyihin käyttötarkoituksiin</h3>
          <p>Tietojanne ei käsitellä muihin kuin edellä mainittuihin käyttötarkoituksiin ilman teidän erillistä nimenomaista suostumustanne</p>
          

          <h3>Omiin tietoihinne liittyvät oikeutenne</h3>
          <p>Teillä on  alla olevat oikeudet henkilötietojenne käsittelyyn liittyen. Mikäli haluatte käyttää alla olevia oikeuksianne tai teillä tulee kysyttävää niihin liittyen, olkaa yhteydessä sähköpostitse osoitteeseen: karri.wiren@concludentia.com.</p>  

          <ul>
          <li><p><strong>Oikeus päästä tietoihin</strong><br />
            Teillä on oikeus saada tieto tässä asiakirjassa luetelluista tiedoista sekä vahvistus siitä käsitelläänkö henkilötietojanne. Teillä on myös oikeus saada jäljennös teitä koskevista käsiteltävistä henkilötiedoista.</p>
          </li>
          <li><p><strong>Oikeus tietojen oikaisemiseen</strong><br />          
          Teillä on oikeus pyytää epätarkkojen ja virheellisten henkilötietojenne oikaisua sekä oikeus saada puutteelliset henkilötiedot täydennettyä toimittamalla asiaa koskeva lisäselvitys.</p>
          <p>Mikäli olemme luovuttaneet henkilötietojenne, ilmoitamme jokaiselle vastaanottajalle, jolle henkilötietoja on luovutettu, tässä tarkoitetun oikeutenne käyttämistä ja sen vaikutuksista henkilötietoihinne, paitsi jos tämä osoittautuu mahdottomaksi tai vaatii kohtuutonta vaivaa. Ilmoitamme pyynnöstänne teille näistä vastaanottajista.</p>
        </li>

          <li><p><strong>Oikeus tietojen poistamiseen (”Oikeus tulla unohdetuksi”)</strong><br />             
          Teillä on oikeus pyytää, että poistamme teitä koskevat tiedot, mikäli:</p>
          
          <p>
          -	tietoja ei enää tarvita alkuperäiseen käyttötarkoitukseen eikä niitä ole säilytettävä lainsäädännöstä johtuvasta syystä<br /> 
          -	peruutatte antamanne tietojen käsittelyä koskevan suostumuksen eikä tietojen käsittelylle tai säilyttämiselle ole lainsäädännöstä johtuvaa perustetta<br /> 
          -	tietojen käsittely perustuu meidän tai kolmannen tahon oikeutettujen etujen toteuttamiseksi ja turvaamiseksi liittyvään toimeen, jota vastustatte erityiseen tilanteeseenne liittyvällä perusteella ellei käsittelyyn ole olemassa huomattavan tärkeää ja perusteltua syytä tai ellei se ole tarpeen oikeusvaateen laatimiseksi, esittämiseksi tai puolustamiseksi
          -	tietojen käsittely perustuu suoramarkkinointiin tai siihen liittyviin toimiin <br /> 
          -	henkilötietoja on käsitelty lainvastaisesti<br /> 
          -	henkilötiedot on poistettava meitä koskevan lakisääteisen velvoitteen noudattamiseksi<br /> 
          -	kyseessä ovat alaikäisen tiedot, jotka on kerätty suoraan häneen kohdistettavien sähköisesti tarjottavien etäpalveluiden perusteella</p>
          
          <p>Jos olemme julkistaneet henkilötietojanne ja pyytäessänne tietoja poistettavaksi, teillä on oikeus pyytää meitä ottamaan yhteyttää niihin rekisterinpitäjiin, jotka ovat tietojanne käyttäneet ja pyytää näitä tietojen poistoa koskevasta pyynnöstänne ja pyytää heitä poistamaan henkilötietoihin liittyvät linkit tai näiden henkilötietojen jäljennökset tai kopiot.</p>
          
          <p>Tietojen poistamista ei toteuteta, mikäli tietojen käsittely on tarpeen:<br />
          
          -	sananvapautta ja tiedonvälityksen vapautta koskevan oikeuden käyttämiseksi;<br />
          -	meitä koskevan lakisääteisen velvoitteen noudattamiseksi<br />
          -	kansanterveyteen liittyvää yleistä etua koskevasta syystä<br />
          -	yleisen edun mukaisia arkistointitarkoituksia taikka tieteellisiä tai historiallisia tutkimustarkoituksia tai tilastollisia tarkoituksia varten<br />
          -	oikeudellisen vaateen laatimiseksi, esittämiseksi tai puolustamiseksi.</p>
          
          <p>Mikäli olemme luovuttaneet henkilötietojenne, ilmoitamme jokaiselle vastaanottajalle, jolle henkilötietoja on luovutettu, tässä tarkoitetun oikeutenne käyttämistä ja sen vaikutuksista henkilötietoihinne, paitsi jos tämä osoittautuu mahdottomaksi tai vaatii kohtuutonta vaivaa. Ilmoitamme pyynnöstänne teille näistä vastaanottajista.</p>
          </li>
          
          <li><p><strong>Oikeus käsittelyn rajoittamiseen</strong><br />         
          Teillä on oikeus pyytää henkilötietojenne käsittelyn rajoittamista, jonka aikana saamme käsitellä, säilyttämistä lukuun ottamatta, ainoastaan seuraavin perustein:</p>
          
          <p>- teidän suostumuksella<br />
          -	oikeudellisen vaateen laatimiseksi, esittämiseksi tai puolustamiseksi<br />
          -	kolmannen osapuolen oikeuksien suojaamiseksi <br />
          -	tärkeää yleistä etua koskevista syistä</p>
          
          <p>Voitte pyytää käsittelyn rajoittamista:</p>
          
          <p>-	siksi ajaksi, jonka tietojen paikkansapitävyyden varmistaminen vie teidän kiistäessänne tietojen paikkansapitävyyden<br />
          -	kun käsittely on lainvastaista ja vastustatte tietojenne poistamista ja vaaditte käytön rajoittamista<br />
          -	kun tietojen käsittelyn käsittelyperuste ei enää täyty, mutta tarvitsette tietoja oikeudellisen vaateen laatimiseksi, esittämiseksi tai puolustamiseksi</p>
          
          <p>Mikäli tietojenne käsittelyä rajoitetaan, teemme teille ilmoituksen, ennen kuin käsittelyä koskeva rajoitus poistetaan</p>
          
          <p>Mikäli olemme luovuttaneet henkilötietojenne, ilmoitamme jokaiselle vastaanottajalle, jolle henkilötietoja on luovutettu, tässä tarkoitetun oikeutenne käyttämistä ja sen vaikutuksista henkilötietoihinne, paitsi jos tämä osoittautuu mahdottomaksi tai vaatii kohtuutonta vaivaa. Ilmoitamme pyynnöstänne teille näistä vastaanottajista.</p>
          </li>

          <li><p><strong>Oikeus siirtää tiedot järjestelmästä toiseen</strong><br />         
          Teillä on oikeus saada teitä koskevat henkilötiedot, jotka olette toimittaneet meille, jäsennellyssä, yleisesti käytetyssä ja koneellisesti luettavassa muodossa, ja oikeus siirtää kyseiset tiedot toiselle rekisterinpitäjälle tai suoraan meiltä kyseiselle rekisterinpitäjälle, jos se on teknisesti mahdollista, meidän estämättä:</p>
          
          <p>-	jos käsittely suoritetaan automaattisesti;<br />
          -	ja se perustuu antamaanne suostumukseen tai sopimukseen, jossa olette osapuolena tai tällaista sopimusta edeltävien toimenpiteiden toteuttamiseen pyynnöstänne;<br />
          -	eikä vaikuta haitallisesti muiden oikeuksiin ja vapauksiin </p>
          
          <p>Tässä tarkoitetun oikeutenne käyttö ei rajoita edellä mainittua oikeuttanne tietojen poistamiseen. </p>
          </li>

          <li><p><strong>Oikeus vastustaa henkilötietojen käsittelyä</strong><br />          
          Teillä on oikeus henkilökohtaiseen erityiseen tilanteeseenne liittyvällä perusteella milloin tahansa vastustaa teitä koskevien henkilötietojen käsittelyä, joka on tarpeen meidän tai kolmannen osapuolen oikeutettujen etujen toteuttamiseksi, ellemme voi osoittaa, että käsittelyyn on olemassa huomattavan tärkeä ja perusteltu syy tai jollei se ole tarpeen oikeusvaateen laatimiseksi, esittämiseksi tai puolustamiseksi.</p>
          
          <p>Teillä on oikeus milloin tahansa vastustaa teitä koskevien henkilötietojen käsittelyä suoramarkkinointia ja siihen liittyviä tehtäviä, kuten profilointia, varten, jonka jälkeen tietojanne ei käytetä toimestamme enää tällaiseen tarkoitukseen.</p>
          </li>

          <li><p><strong>Oikeus olla joutumatta pelkkään henkilötietojen automaattiseen käsittelyyn, kuten profilointiin, perutustuvan päätöksen kohteeksi</strong><br />   
          Teillä on oikeus olla joutumatta sellaisen päätöksen kohteeksi, joka perustuu pelkästään automaattiseen käsittelyyn, kuten profilointiin, ja jolla on teitä koskevia oikeusvaikutuksia tai joka vaikuttaa teihin vastaavalla tavalla merkittävästi ellei päätös:</p>
          
          <p>-	ole välttämätön välisemme sopimuksen tekemistä tai täytäntöönpanoa varten<br />
          - ole hyväksytty lainsäädännön nojalla; tai<br />
          -	perustu nimenomaiseen suostumukseenne</p>
          
          <p>Jos päätös perustuu välisemme sopimuksen tekemiseen tai täytäntöönpanemiseen tai suostumukseenne, on teillä oikeus vaatia, että tiedot käsittelee puolestamme luonnollinen henkilö sekä oikeus esittää kantanne ja riitauttaa päätös.</p>
          </li>
          </ul>


          <h3>Oikeus tehdä valitus valvontaviranomaiselle</h3>
          <p>Mikäli katsotte, että henkilötietojesi käsittelyä koskevassa toiminnassamme rikotaan lainsäädäntöön perustuvia velvollisuuksiamme ja oikeuksianne voitte, selvitettyänne asiaanne ensin kanssamme, kääntyä Tietosuojavaltuutetun puoleen:</p>

          <strong>Tietosuojavaltuutetun toimisto</strong>
          <p>Käyntiosoite: Ratapihantie 9, 6. krs, 00520 Helsinki</p>
          <p>Postiosoite: PL 800, 00521 Helsinki</p>          
          <p>Vaihde: 029 56 66700</p>
          <p>Faksi: 029 56 66735</p>
          <p>Sähköposti: tietosuoja@om.fi</p>
          <p>Tietosuojavaltuutettu antaa henkilötietojen käsittelyä koskevaa ohjausta ja neuvontaa sekä valvoo henkilötietojen käsittelyn lainmukaisuutta.</p>
          

        </div>
		)
	}
}

export default TietosuojaSelosteText