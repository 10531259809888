import React from 'react';
import { connect } from 'react-redux'
import StarRatingComponent from 'react-star-rating-component';
import { actions } from '../ducks/session'
import { actions as uiActions } from '../ducks/ui'
 
class FeedbackForm extends React.Component {

  constructor() {

    super();

    this.state = {
      rating: 1,
      rating_empty_initial: 0,
      showTextField: false,
      feedback: ''
    };

    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    // close
  }
  

  onStarClickEmptyInitial(nextValue, prevValue, name) {
    console.log('name: %s, nextValue: %s, prevValue: %s', name, nextValue, prevValue)
    this.props.giveFeedback(nextValue)
    this.setState({ rating_empty_initial: nextValue, showTextField: true })
  }

  renderTextInput = () => {
    return (
      <div>
        <p>Anna palautetta</p>
        <input type="text" value={this.state.feedback} onChange={e => this.setState({ feedback: e.target.value })} className="feedback_input" placeholder="" />
        <button
          onClick={() => {
            this.props.giveFeedback(this.state.rating, this.state.feedback)
            this.props.hideFeedback()
          }}
          className="btn btn--green"
          type="button"
        >Lähetä</button>
      </div>
    )
  } 

  render() {

//    const { rating } = this.state;
    
    return (                
      <div className="feedback-form">
        <h2>{this.props.question}</h2>
        <div style={{fontSize: 24}}>
            <StarRatingComponent 
              name="feedback_stars" 
              starCount={5}
              starColor="#ffb400"
              emptyStarColor="#ffffff"
              value={this.state.rating_empty_initial}
              onStarClick={this.onStarClickEmptyInitial.bind(this)}
            />
        </div>
        { this.state.showTextField ? this.renderTextInput() : '' }       
      </div>
    );
  }
}

export default connect(
  state => ({ question: state.session.feedbackText }),
  {
    ...actions,
    ...uiActions
  }
)(FeedbackForm)