import React, { Component } from 'react'
import { apiConstants } from '../../common/constants'

export default class Prescription extends Component {

  onAcceptedAndUndoClick (id) {
    this.props.cancelDecision(id)
  }

  render() { 
    
    let typeText = this.props.type === 'renewal' ? 'Uusinta' : 'Reseptipyyntö'
    let active = this.props.active ? " active" : ''
    

    if (this.props.decision !== apiConstants.PRESCRIPTION_DECISION_UNDECIDED) {
      active += this.props.decision === apiConstants.PRESCRIPTION_DECISION_ACCEPTED ? ' accepted' : ' declined'
    }

    return (    
      <li>
        <div className={"prescription__order "+ this.props.type + active} onClick={this.props.onClick.bind(null, this.props.prescriptionId)}>
          <div className="type">{typeText}</div>
          <div className="name">{this.props.name}</div>
          <div className={`undo-button ${ this.props.readOnly && "disable-pointer-events"}`} onClick={() => !this.props.readOnly && this.onAcceptedAndUndoClick(this.props.prescriptionId)}></div>            
        </div>
      </li>
    )
  }
}