
export const types = {
	RECEIVE_STRIPE_DATA: Symbol('stripe/RECEIVE_STRIPE_DATA'),
}

let initialState = {
    sessionId: ''
}

export default (state = initialState, action) => {
	
	switch (action.type) {

		case types.RECEIVE_STRIPE_DATA:
			return {
				...state,
                sessionId: action.data.sessionId
			}

		default:
			return state
			
	}

}

export const actions = {

	fetchStripeSession: () => ({ type: 'FETCH_STRIPE_SESSION' }),

}