import React, { Component } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default class ContentHeader extends Component {

  state = {
    hetu: '',
    copied: false,
    isNameCopied: false,
    isHetuCopied: false
  };

  componentWillUnmount() {
    //kill lingering timeouts if any 
    var i = window.setTimeout(null,0);
    while (i--) {
        window.clearTimeout(i);
    }
  }

  nameCopied() {
    this.setState({isNameCopied: true})
    setTimeout(() => this.setState({ isNameCopied: false}), 2000)
  }

  hetuCopied() {
    this.setState({isHetuCopied: true})
    setTimeout(() => this.setState({ isHetuCopied: false}), 2000)
  }

  formatDate = (dateString) => {
    if (!dateString || dateString === '') {
      return '-';
    }
    const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const asDate = new Date(dateString);
    const datePart = asDate.toLocaleDateString('fi-FI', dateOptions);
    const timePart = asDate.toLocaleDateString('fi-FI', { hour: 'numeric', minute: 'numeric' });
    return `${datePart} - ${timePart.split(' ')[2]}`;
  }

  timeLeft = (deadline) => {
    const dl = new Date(deadline);
    const curr = new Date();
    let diff_m = Math.trunc ((dl.getTime() - curr.getTime()) / 1000 / 60);
    let text = '';
    if (diff_m < 0) {
      text = "Aika ylitetty: ";
      diff_m = -diff_m;
    } else {
      text = "Aikaa jäljellä: ";
    }
    const days = Math.trunc(diff_m / (60*24));
    const hours = Math.trunc((diff_m - (days*60*24)) / 60);
    const minutes = diff_m - (days*60*24) - (hours*60);
    if (days > 0) {
      text += days + 'pv ';
    }
    return text + hours + 't ' + minutes + 'min';
  }

  render() {
    const { firstName, lastName, hetu } = this.props.patient
    const { processingStartTime, deliveryBeforeTime, doctor, deliveryStatus } = this.props.order
    const fullName = `${firstName} ${lastName}`
    const dueTime = this.timeLeft(deliveryBeforeTime);
    const timeClass = dueTime.indexOf('ylitetty') > 0 ? 'negative' : 'positive';
    const doctorName = typeof doctor === 'undefined' ? '' : `${doctor.firstName} ${doctor.lastName}`

    return (
        <header className="content__header">
          <div className="patientinfo_container">

            <div className="name">
              <h1>{fullName}</h1>
              <div className="copybutton_container">
              {this.state.isNameCopied ?
                <div className="copy-checked"><i className="fas fa-check-circle fa-2x"></i></div>
              :
                <CopyToClipboard text={fullName} onCopy={() => this.nameCopied()}>
                <button className="btn__clipboard"><i className="far fa-clone fa-flip-vertical fa-sm"></i></button>
                </CopyToClipboard>
              }
              </div>
            </div>

            <div className="hetu">
            { /* <input value={hetu} onChange={({ target: { hetu } }) => this.setState({ hetu, copied: false })}  */ }
              <span>{hetu}</span>
              <div className="copybutton_container">
              { this.state.isHetuCopied ?
                <div className="copy-checked"><i className="fas fa-check-circle fa-2x"></i></div>
              : 
                <CopyToClipboard text={hetu} onCopy={() => this.hetuCopied()}>
                  <button className="btn__clipboard"><i className="far fa-clone fa-flip-vertical fa-sm"></i></button>
                </CopyToClipboard>
              }
              </div>
            </div>

          </div>

          { this.props.scope==='history' && deliveryStatus==='processing' &&
            <div className="prescriptioninfo_container">
              <div className="column">
                <div className="handling_doctor">{doctorName}</div>
                <div className="processing_start">{this.formatDate(processingStartTime)}</div>
              </div>
              <div className="column">
                <div className={`time_left ${timeClass}`}>{dueTime}</div>
                <button className="prescription__return" onClick={this.props.onCancel}>Palauta</button>
              </div>
            </div>
          }
          
          { /*this.state.copied ? <div className="notification__clipboardz">Sosiaaliturvatunnus kopioitu leikepöydälle <i className="far fa-copy" data-fa-transform="grow-2"></i></div> 
          : null
          */
         }
        </header>
    )
  }  
}