import { call, fork, put, take, select } from 'redux-saga/effects'
import { types } from '../ducks/session'
import { push } from 'react-router-redux'
import api from '../../api'


import {
	makeRequest,
	fetchProductData,
	getProducts,
	fetchPharmacistSession
} from '../../sagas/common'

import { actions as uiActions } from '../../ducks/ui'

const login = makeRequest.bind(null, api.loginPharmacist)
const logout = makeRequest.bind(null, api.logoutPharmacist)
const createPharmacyUser = makeRequest.bind(null, api.createPharmacyUser)
const deletePharmacyUser = makeRequest.bind(null, api.deletePharmacyUser)
const updatePharmacyUser = makeRequest.bind(null, api.updatePharmacyUser)
const fetchPharmacistOffices = makeRequest.bind(null, api.fetchPharmacistOffices)
const setOffice = makeRequest.bind(null, api.setOffice)
const getOffice = makeRequest.bind(null, api.getOffice)
const fetchPharmacyUsers = makeRequest.bind(null, api.fetchPharmacyUsers)
const savePatientInfo = makeRequest.bind(null, api.savePatientInfo)
const setDeliveryOption = makeRequest.bind(null, api.setDeliveryOptionToChart);
const sendPharmacyCart2Doctor = makeRequest.bind(null, api.sendPharmacyCart2Doctor)
const deletePharmacyCart = makeRequest.bind(null, api.deletePharmacyCart)
const removeItemFromPharmacyList = makeRequest.bind(null, api.removeItemFromList)
const checkUserName = makeRequest.bind(null, api.checkUserName)
const setMessageChecked = makeRequest.bind(null, api.setMessageChecked)
const setMessageUnchecked = makeRequest.bind(null, api.setMessageUnchecked)
const getMessageStatus = makeRequest.bind(null, api.getMessageStatus)

function* loginSaga(username, password) {
	console.log('loginSaga')
	yield call(login, username, password)
	yield call(fetchPharmacistSession)
}



export function* fetchPharmacistOfficesListener() {
	while (true) {

		yield take(types.RECEIVE_CURRENT_OFFICES)
	
		try {
			yield put(uiActions.startRequest())		
			yield call(fetchPharmacistOfficesaa)
		} finally {
			yield put(uiActions.endRequest())		
		}	
	}
}

export function* fetchPharmacistOfficesaa() {
	console.log("fetchPharmacistOfficesaa")
	const response = yield call(fetchPharmacistOffices) 
	const offices = yield response.json()
	console.log("oiffects",offices)
	yield put({ type: types.RECEIVE_CURRENT_OFFICES, offices: offices })
}

export function* setOfficeListener() {

	while (true) {
		const action = yield take(types.SET_OFFICE)

		try {
			
			yield put(uiActions.startRequest())	
			yield selectOffice(action.id);
		} catch(e) {

			console.log('epäonnistui',e)
			yield put({ type: 'SET_OFFICE_FAILED' , error: e})
		
		} finally {
			yield put(uiActions.endRequest())		
		}
	}
}

function* selectOffice(id) {
	yield call(setOffice, id)	
	yield call(getMyOffice)
	//yield fork(getMessage)
	yield put(push('/reseptitapteekki'))
}

function* getMyOffice() {
	const fetchResponse = yield call(getOffice)
	const data = yield fetchResponse.json()
	console.log("getMyOffice:",data)
	yield put({ type: types.RECEIVE_OFFICE, data: data })
}

export function* createPharmacyUserListener() {
	while (true) {
	
		const action = yield take(types.CREATE_PHARMACY_USER)

		try {
			yield put(uiActions.startRequest())
			const response = yield call(createPharmacyUser, action.firstName, action.lastName, action.username, action.password)
			const user = yield response.json();
			yield put({ type: types.CREATE_PHARMACY_USER_SUCCESS, user });
		} catch (e) {
			console.log(e);
			yield put({ type: types.CREATE_PHARMACY_USER_FAILED })
		} finally {
			yield put(uiActions.endRequest())		
		}
	}
}

export function* savePatientInfoListener() {
	while (true) {
	
		const action = yield take(types.SAVE_PATIENT_INFO)

		try {

			const data = {
				firstName: action.firstName,
				  lastName: action.lastName,
				  hetu: action.hetu
			}

			yield put(uiActions.startRequest())
			const response = yield call(savePatientInfo, data)

			const responsedata = yield response.json()
			console.log('%csavePatientInfo',responsedata, 'color:white; background-color: blue')

			yield put({ type: types.PATIENT_INFO, patientInfo: { ...data } }) 
		} finally {
			yield put(uiActions.endRequest())		
		}
	}
}

function* listPharmacyUsers() {
	const  response = yield call(fetchPharmacyUsers)
	const users = yield response.json()

	yield put({ type: types.RECEIVE_PHARMACY_USERS, currentUsers: users })
}


export function* fetchPharmacyUsersListener() {
	while(true) {
		yield take(types.FETCH_PHARMACY_USERS)
		try {

			yield put(uiActions.startRequest())
			yield call(listPharmacyUsers)
			
		} finally {
			yield put(uiActions.endRequest())		
		}
	}
}


export function* deletePharmacyUserListener() {
	while(true) {
		const action = yield take(types.DELETE_PHARMACY_USER)
		try {

			yield put(uiActions.startRequest())
			yield call(deletePharmacyUser, action.username)
			yield call(listPharmacyUsers)
			
		} finally {
			yield put(uiActions.endRequest())		
		}
	}
}


export function* updatePharmacyUserListener() {
	while(true) {
		const action = yield take(types.UPDATE_PHARMACY_USER)
		try {

			yield put(uiActions.startRequest())
			yield call(updatePharmacyUser, action.id, action.firstName, action.lastName, action.username, action.password)
			yield call(listPharmacyUsers)
			
		} finally {
			yield put(uiActions.endRequest())		
		}

	}
}

export function* addItem2PharmacyCartListener() {
	console.log("-----------------addItem2PharmacyCartListener")
	while (true) {

		const action = yield take(types.CHOOSE_PRODUCT)
		try {
			yield put(uiActions.startRequest())
			yield call(addItem2PharmacyCart, action.orderType, action.productId, action.reason, action.pharmacyData)
		} finally {
			yield put(uiActions.endRequest())		
		}			
	}
}

function* addItem2PharmacyCart(type, productId, reason, pharmacyData) {
	const response = yield call(makeRequest, api.addItem2PharmacyCart, type, productId, reason, pharmacyData)

	if (!response.ok) {
		throw new Error('API call failed with ', response)
	}

	console.log('%cITEM ADDED TO CART', 'color:blue')
}

export function* setDeliveryOptionListener() {
	while (true) {
		
		const action = yield take(types.SET_DELIVERY_OPTION)
		try {
			
			yield put(uiActions.startRequest())
			yield call(makeRequest, setDeliveryOption, action.option)

		} catch (e) {
			console.error('Cannot set delivery option', e)
		} finally {
			yield put(uiActions.endRequest())		
		}
	}
}

export function* sendCartListener() {
	console.log("-----------------sendCartListener")
	while (true) {
		
		yield take(types.SEND_CART)

		try {
			
			yield put(uiActions.startRequest())
			yield call(sendCart2Doc)

		} catch (e) {
			console.error('SEND_CART failed',e)
		} finally {
			yield put(uiActions.endRequest())		
		}
	}
}

 function* sendCart2Doc() {
	const response = yield call(makeRequest, sendPharmacyCart2Doctor)

	const data = yield response.json()
	console.log("sendCart2Doc response:", data)
	yield call(clearCart);
	yield put({
		type: types.CART_SENT_SUCCESS,
		order: data,
	});
}

function* clearCart() {
	const deleteCartResponse = yield call(deletePharmacyCart)
	const deleteCartResponseData =  yield deleteCartResponse.json()

	console.log("deleteCartResponseData response:", deleteCartResponseData)

	yield put({ type: types.PATIENT_INFO,  patientInfo: {firstName:'', lastName:'', hetu:''} })

}

export function* fetchPharmacyCartListener() {
	console.log("fetchPharmacyCartListener")
	
	try {
		yield put(uiActions.startRequest())
		yield call(fetchPharmacyCart)

	} catch (e) {
		console.log(' failed ', e)
	} finally {
		yield put(uiActions.endRequest())		
	}
}


function* fetchPharmacyCart() {
	const response = yield call(makeRequest, api.fetchPharmacyCart)

	if (!response.ok) {
		throw new Error('API call failed with ', response)			
	}

	const data = yield response.json()
	console.log('fetchPharmacyCart' ,data)

	yield put({
		type: types.RECEIVE_PHARMACY_CART,
		cart: data
	})	
	
}
export function* removeItemFromListListener() {
	while (true) {
	
		const action = yield take(types.REMOVE_ITEM_FROM_LIST)

		try {
			yield put(uiActions.startRequest());
			yield call(removeItemFromList, action.itemId);
			yield call(fetchPharmacyCart);
			yield put({
				type: types.ITEM_REMOVED_FROM_LIST,
				itemId: action.itemId
			});
		} catch (e) {
			console.error('REMOVE_ITEM_FROM_LIST failed',e)
		} finally {
			yield put(uiActions.endRequest())		
		}			

	}
}
function* removeItemFromList(id) {
	console.log('removeItemFromList', id)
	const response = yield call(removeItemFromPharmacyList, id)

	if (!response.ok) {
		throw new Error('API call failed with ', response)
	}
}


export function* deletePharmacyCartListener() {
	
	while (true) {
	
		yield take(types.DELETE_PHARMACY_CART)

		try {
			yield put(uiActions.startRequest())
			yield call(clearCart)
		} finally {
			yield put(uiActions.endRequest())		
		}			
	}
}


export function* fetchPharmacyOrderHistoryListener() {
	while (true) {
		yield take(types.FETCH_PHARMACY_ORDER_HISTORY)
		yield fork(fetchPharmacyOrderHistory)
	}
}

function* fetchPharmacyOrderHistory() {

	const products = yield select(getProducts);
	if(!products || products.length === 0) {
		// We need to have products available to correctly populate orders
		yield call(fetchProductData);
	}
	const selectMode = yield select((state) => state.pharmacist.session.selectedListMode);
	const response = yield call(makeRequest, api.fetchPharmacyOrderHistory, selectMode)

	if (!response.ok) {
		throw new Error('API call failed with ', response)			
	}

	const data = yield response.json()
	yield put({
		type: types.RECEIVE_PHARMACY_ORDER_HISTORY,
		products: data.data
	})
}



export function* getMessageListener() {
	//console.log("getMessageListener")
	while (true) {
	
		yield take(types.GET_MESSAGE)

		try {
			yield put(uiActions.startRequest())
			yield call(getMessage)

		} catch (e) {
			console.log('Message missing')
		} finally {
			yield put(uiActions.endRequest())		
		}
	}
}


function* getMessage() {

	const response = yield call(makeRequest, api.getMessage)

	if (!response.ok) {
		throw new Error('API call failed with ', response)			
	}

	const data = yield response.json()
	//console.log('******************** getMessage data:' ,data)

	yield put({
		type: types.RECEIVE_CURRENT_MESSAGE,
		data: data
	})	
	
}

export function* setMessageCheckedListener() {
	while(true) {
		yield take(types.SET_MESSAGE_CHECKED)
		try {

			yield put(uiActions.startRequest())
			yield call(setMessageChecked)
			yield call(getMessage)
			yield call(getCurrentMessageStatus)
			
		} finally {
			yield put(uiActions.endRequest())		
		}

	}
}

export function* setMessageUncheckedListener() {
	while(true) {
		yield take(types.SET_MESSAGE_UNCHECKED)
		try {

			yield put(uiActions.startRequest())
			yield call(setMessageUnchecked)
			//yield call(getMessage)
			yield call(getCurrentMessageStatus)
			
		} finally {
			yield put(uiActions.endRequest())		
		}

	}
}

export function* currentMessageStatusListener() {
	while (true) {
	
		yield take(types.GET_MESSAGE_STATUS)

		try {
			yield put(uiActions.startRequest());
			yield call(getCurrentMessageStatus)
		} catch (e) {
			console.error('failed',e)
		} finally {
			yield put(uiActions.endRequest())		
		}			

	}
}

function* getCurrentMessageStatus() {
	const response = yield call(getMessageStatus)

	if (!response.ok) {
		throw new Error('API call failed with ', response)			
	}

	const data = yield response.json()
	yield put({
		type: types.RECEIVE_MESSAGE_STATUS,
		status: data
	});
}


export function* logoutListener() {
	while (true) {
		yield take(types.LOGOUT)
		yield call(logout)
		yield put(push('/loginapteekki'))
	}
}

export function* loginListener() {
	console.log('pharmacist loginListener')
	while (true) {

		const action = yield take(types.LOGIN)

		try {

			yield put(uiActions.startRequest())
			yield call(loginSaga, action.username, action.password)

			const officeList = yield select((state) => state.pharmacist.session.currentOffices);
			if(officeList && officeList.pharmacyOffices && officeList.pharmacyOffices.length > 1) {
				yield put(push('/toimipaikka'))
			} else if(officeList.pharmacyOffices.length === 1) {
				yield selectOffice(officeList.pharmacyOffices[0].id);
			} else {
				// No office available. Not really much we can do about it except warn user about that in office select page.
				yield put(push('/toimipaikka'))
			}
		} catch (e) {
			console.log('Login failed', e);
			yield put(uiActions.login(false));
		} finally {
			yield put(uiActions.endRequest())		
		}

	}
}

export function* checkUserNameListener() {
	while(true) {
		const action = yield take(types.CHECK_USERNAME);
		const response = yield call(checkUserName, action.userName)
		const data = yield response.json()
		yield put({ type: types.CHECK_USERNAME_RESULT, result: data.usernameExists });
	}
}


