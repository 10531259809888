import React, { Component } from 'react'
import { connect } from 'react-redux';
import { selectors } from '../ducks/session'

import BubbleDoctor from '../../chat/BubbleDoctor'
import BubbleSelf from '../../chat/BubbleSelf'
import SelectButton from '../../chat/SelectButton'
import Input from '../../chat/Input'
import InputAutoComplete from '../../chat/InputAutoComplete'
import MultiChoice from '../../chat/MultiChoice'
import SelectOne from '../../chat/SelectOne';

import { chatTypes, chatEngine } from './Chat'


class PharmacyChat extends Component {

	constructor(props) {
		super(props)
		this.state = {}
	}

  scrollToBottom = () => {
    let endPos = this.messagesEnd.offsetTop;
    this.chatContainer.scrollTop = endPos;
  }

  componentDidMount() {
    this.initChat();
  }

  componentDidUpdate(prevProps) {
    this.scrollToBottom();
    if(prevProps.patient !== this.props.patient) {
      this.initChat();
    }
  }

  setCurrentChat = (chat) => {
    this.setState({ chat: chat })
  }

  initChat() {
    const { patient, chooseProduct, sendToDoctor, setDeliveryOption } = this.props;
    const chat = chatEngine(
      this.setCurrentChat,
      patient,
      chooseProduct,
      sendToDoctor,
      setDeliveryOption
    );

    this.setState({ chat: chat })
  }

	render() {

    if (!this.state.chat) return null

    const selectedPatient = this.props.patient ? this.props.patient : { firstName: '', lastName: '' };

    return (

      <div className="">

        <button className="chat-cancel" onClick={this.props.abort}><i className="fas fa-chevron-left"></i> Keskeytä</button>

        <div className="chat__container" ref={el => { this.chatContainer = el; }} id="chat__container">

          <BubbleDoctor content={'Hae uusittavaa lääkettä asiakkaalle <b>' + selectedPatient.firstName.split(" ", 1) + ' ' + selectedPatient.lastName + ' ' + selectedPatient.hetu +'</b>'} />

          {
            this.state.chat.map((c, i) => {

              switch (c.type) {

                case chatTypes.TYPE_ANSWER:
                  return <BubbleSelf key={i} content={c.text} />

                case chatTypes.TYPE_SELECT:
                  return (
                      <div className="select__options" key={i}>
                      { c.options.map((o, ind) => <SelectButton key={ind} onClick={o.callback} content={o.text} id={o.id} />) }
                      </div>
                    )

                case chatTypes.TYPE_MULTICHOICE:
                  return (
                      <MultiChoice key={i} answers={c.answers} getSelected={c.callback} />
                  )

                case chatTypes.TYPE_INPUT_AUTO_COMPLETE:
                  return (
                      <InputAutoComplete
                      key={i}
                      products={this.props.products}
                      addProduct={(product) => c.select(product)}
                      placeholder='Etsi lääkettä'
                      />
                    )

                case chatTypes.TYPE_INPUT:
                  return <Input key={i} valueChanged={c.callback} placeholder={c.text} />
                
                case chatTypes.TYPE_BLANK:
                return (
                    <span key={i}></span>
                )

                case chatTypes.TYPE_RADIO:
                  return <SelectOne onChange={c.callback} options={c.options} key={i}/>

                default:
                  return <BubbleDoctor key={i} content={c.text} />
              }

            })
          }

          <div ref={el => { this.messagesEnd = el; }} style={{"paddingTop": "4rem"}} />
        </div>      
      </div>
      )
  }
}

export default connect(state => ({
  patient: state.pharmacist.session.patientInfo,
  products: selectors.getProducts(state),
}), {})(PharmacyChat)
