import React, { Component } from 'react'
import Card from '../common/Card'

import { connect } from 'react-redux'
import { actions } from '../ducks/registration'

class RegisterHello extends Component {

  render() {
    return (
      <Card
        progress={this.props.progress}
        heading={"Hei "+this.props.firstName+","}
        subheading="Luodaan sinulle oma tili"
        description="Sen avulla voit uusia reseptejäsi sekä seurata niiden uusimisen edistymistä"
        input=""
        previousPath="/"
        onSubmit={() => this.props.startRegistration()}
      />
    )
  }
}

export default connect(
  state => {
    return {
      firstName: state.registration.firstName
    }
  },
  {
    ...actions
  }
)(RegisterHello)