export const actionUtils = {
	action: (type, payload = {}) => {
		return dispatch => {
			dispatch({ type: type, ...payload })
		}
	}
}

export const flatOrder = (data) => {
	return data.map(o => {		
		return o.prescriptions.map(

				// https://github.com/Industry62/reseptori-webapp/issues/80
				// If order is not delivered none of the prescriptions are decided as far as UI is concerned
				p => ({ ...p, deliveryTime: o.deliveryTime, decision: o.deliveryStatus !== 'delivered' ? 'undecided' : p.decision })
		)
	}).reduce(((a, b) => { return a.concat(b) }), [])
}

export const pharmacyFlatOrder = (data) => {
	//console.log("pharmacyFlatOrder data:", data)
	return data.map(o => {		
		return o.prescriptions.map(

				p => ({ ...p, 
					deliveryTime: o.deliveryTime, 
					patientHetu: o.patientHetu, 
					patientFirstName: o.patientFirstName,
					patientLastName: o.patientLastName,
					created: o.created,
					decision: o.deliveryStatus !== 'delivered' ? 'undecided' : p.decision,
					pharmacist: p.pharmacyData ? p.pharmacyData.pharmacist : '-',
				})
		)
	}).reduce(((a, b) => { return a.concat(b) }), [])
}