import React, { Component } from 'react'
import { tupasUrl } from '../common/constants'

class CreateAccountSection extends Component {

	render() {
		return (
            <div className="section--cta">
                <h2 className="section__heading">{this.props.heading}</h2>
                <button className="cta cta--register" onClick={() => window.location.replace(tupasUrl())}>Luo tili</button>
            </div>
		)
	}
}

export default CreateAccountSection
