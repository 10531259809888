import React, { Component } from 'react'
import PrescriptionCard from './PrescriptionCard'

export class List extends Component {
    select = ({target}) => {
        this.props.select(parseInt(target.id, 10), target.checked)
      }
  render() {
    return (

      <div className="list-cart">
      { /*
        <div className="list-header-container">
          <h1>Valitut reseptit</h1>
          <p className="list-desc">Siirry kassalle viimeistelläksesi tilauksesi</p>
        </div>
        */ }

        {
          this.props.list.map((p, i) => {
              //console.log({p})
            return (<PrescriptionCard
              key={i}
              prescription={p}
              onRemove={(id) => this.props.removeItem(id)}
              //onChange={({target}) => this.props.removeItem(target.id)}
            />)
          })
          
        }


      </div>
      )    
  }
}


