import React, { Component } from 'react'
//import ButtonProfileBack from './ButtonProfileBack'

export class ProfileHeader extends Component {

  render() {
    return (
      <div className="profile__header">
        {/** 
          * Näytä ProfileBack jos input kentät ovat koskemattomia tai muutokset tallennettu / kumottu 
          * Tämä painike piilottaa ProfileBasein avoinna oleva children -> Eli takaisin perus profiilinäkymään
          *
        <ButtonProfileBack className="hide-desktop" />
        */ }
        

 {/* 18.4. napit piilottu, koska tallennus tehdään eri tavalla */ }
    {/*    <button className="edit edit-cancel">Kumoa</button>  */ }
        <h1>{this.props.title}</h1>
    {/*    <button className="edit edit-update" onClick={this.props.onSubmit}>Valmis</button>  */ }
      </div>
    )
  }
}

export default ProfileHeader
