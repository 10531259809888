import React from 'react';
import PropTypes from 'prop-types';

const RadioButton = ({ label, checked, onChange }) => (
  <label className="Radiobutton">
    <span className="Radiobutton-label">{label}</span>
    <input
      onChange={(e) => onChange()}
      checked={checked}
      type="radio"
    />
    <span class="Radiobutton-checkmark" />
  </label>
);

RadioButton.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
}

export default RadioButton;