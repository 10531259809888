import React, { Component } from 'react'
import Autosuggest from 'react-autosuggest'

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import UAParser from 'ua-parser-js'

const EMPTY_LIST_COMPONENT = { __html: '<div><b>Hakemaasi lääkettä ei löydy</b></div><div>Reseptorin kautta ei voi uusia huumavien eikä PKV (pääasiassa keskushermostoon vaikuttavien) lääkkeiden reseptejä</div>' }
const EMPTY_LIST = (<div dangerouslySetInnerHTML={EMPTY_LIST_COMPONENT} style={{textAlign: 'center'}}></div>)

const FIX_BODYSCROLL_CLASS = "no-scroll";

export class InputAutoComplete extends Component {

  	constructor(props) {
		super(props)
		this.state = {
			value: '',
			suggestions: []
		}
	}

  fixIos = (value) => {
	
	const targetElement = document.querySelector('#react-autowhatever-1');

	let parser = new UAParser();
	let parser_result = parser.getResult();

	console.log(parser_result.os.name);
	
	if(parser_result.os.name === 'iOS'){ 
		if(value === 'lock') {
			disableBodyScroll(targetElement);
			document.body.classList.add(FIX_BODYSCROLL_CLASS);
		} else {
			clearAllBodyScrollLocks();
			document.body.classList.remove(FIX_BODYSCROLL_CLASS);
		}
	}

  }
  componentDidMount() {
	this.fixIos('lock')
   }
   
   componentWillUnmount() {
	this.fixIos('unlock')
   }

  setStateParameter = (name, value) => {
  	let state = this.state
  	state[name] = value
  	this.setState(state)
  }

  onSuggestionsFetchRequested = ({ value }) => {
  	this.setStateParameter('suggestions', this.getSuggestions(value))
  }

  onSuggestionsClearRequested = () => {
  	let state = this.state
  	state.suggestions = []
  	this.setState(state)
  }

  getSuggestions = value => {

  	const inputValue = value.trim().toLowerCase()
  	const inputLength = inputValue.length

    if (inputLength === 0) return []

    const filtered = this.props.products.filter(product =>
      product.displayName.toLowerCase().slice(0, inputLength) === inputValue
    )

    return filtered.length ? filtered : [ { displayName: EMPTY_LIST, empty: true } ]
  }

  getSuggestionValue = suggestion => suggestion.empty ? '' : suggestion.displayName
  renderSuggestion = suggestion => suggestion.empty ? EMPTY_LIST : suggestion.displayName + ' ' + suggestion.format

  onChange = (event, { newValue }) => {
  	let state = this.state
  	state.value = newValue
  	this.setState(state)
  }

  onBlur = (event, { newValue }) => {
  	let state = this.state
  	state.suggestions = []
  	state.value = ''
  	this.setState(state)
  }

  onSuggestionSelected = (event, { suggestion }) => {
    if (suggestion.empty) return
    this.props.addProduct(suggestion)
  }

  render() {

  	let inputProps = {
  		value: this.state.value,
  		onChange: this.onChange,
  		onBlur: this.onBlur,
  		placeholder: this.props.placeholder,
  		className: 'chat__input chat__autoComplete'
  	}

  	return (

  		<Autosuggest
	  		suggestions={this.state.suggestions}
	  		onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
	  		onSuggestionsClearRequested={this.onSuggestionsClearRequested}
	  		onSuggestionSelected={this.onSuggestionSelected}
	  		getSuggestionValue={this.getSuggestionValue}
	  		renderSuggestion={this.renderSuggestion}
	  		inputProps={inputProps}
  		/>

  		)
  	}
  }

  export default InputAutoComplete
