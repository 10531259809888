import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { selectors, actions } from '../ducks/session';

import BubbleDoctor from './BubbleDoctor'
import BubbleSelf from './BubbleSelf'
import SelectButton from './SelectButton'
import Input from './Input'
import InputAutoComplete from './InputAutoComplete'
import MultiChoice from './MultiChoice'
import ButtonChatBack from './ButtonChatBack'

import { chatTypes, chatEngine } from './Chat'


class ChatComponent extends Component {

	constructor(props) {
		super(props)
    this.state = {}
    this.choosePrescription = this.choosePrescription.bind(this);
	}

  scrollToBottom = () => {
    let endPos = this.messagesEnd.offsetTop;
    this.chatContainer.scrollTop = endPos;
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  setCurrentChat = (chat) => {
    this.setState({ chat: chat })
  }

  choosePrescription(problem, chat) {
    const filteredChat = chat.filter(item => !item.discardOnSave);
    this.props.choosePrescription(problem, filteredChat);
  }

  componentDidMount() {
    
    const chat = chatEngine(
      this.setCurrentChat,
      this.props.user,
      this.choosePrescription,
      this.props.chooseProduct,
      this.props.closeChat
    )

    this.setState({ chat: chat })
	}

	render() {

    if (!this.state.chat) return null

    return (

      <div className="chat">

        <ButtonChatBack closeChat={this.props.onAbort} />

        <div className="chat__container" ref={el => { this.chatContainer = el; }} id="chat__container">

          <h1>Hae Reseptiä</h1>
          <BubbleDoctor content={'Hei ' + this.props.user.firstName.split(" ", 1) + '!'} />

          {
            this.state.chat.map((c, i) => {

              switch (c.type) {

                case chatTypes.TYPE_ANSWER:
                  return <BubbleSelf key={i} content={c.text} />

                case chatTypes.TYPE_SELECT:
                  return (
                      <div className="select__options" key={i}>
                      { c.options.map((o, ind) => <SelectButton key={ind} onClick={o.callback} content={o.text} id={o.id} />) }
                      </div>
                    )

                case chatTypes.TYPE_MULTICHOICE:
                  return (
                      <MultiChoice key={i} answers={c.answers} getSelected={c.callback} />
                  )

                case chatTypes.TYPE_INPUT_AUTO_COMPLETE:
                  return (
                      <InputAutoComplete
                      key={i}
                      products={this.props.products}
                      addProduct={(product) => c.select(product)}
                      placeholder='Etsi lääkettä'
                      />
                    )

                case chatTypes.TYPE_INPUT:
                  return <Input key={i} valueChanged={c.callback} placeholder={c.text} />

                default:
                  return <BubbleDoctor key={i} content={c.text} />
              }

            })
          }

          <div ref={el => { this.messagesEnd = el; }} style={{"paddingTop": "4rem"}} />
        </div>      
      </div>
      )
  }
}

export default withRouter(connect(state => ({
  user: state.session.user,
  products: selectors.getUnchosenProducts(state),
}), { 
  choosePrescription: actions.choosePrescription, 
  chooseProduct: actions.chooseProduct
})(ChatComponent));
