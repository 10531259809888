import React, { Component } from 'react'
import Card from '../common/Card'

import 'react-responsive-modal/lib/react-responsive-modal.css';
import Modal from 'react-responsive-modal/lib/css';
import TosText from "../common/TosText"
import MarketingText from '../common/MarketingText';
import TietosuojaSelosteText from '../common/TietosuojaSelosteText';

export default class RegisterTOS extends Component {

  constructor(props) {
    super(props)
    this.state = {
      eulaAccepted: props.eulaAccepted,
      marketingAllowed: props.marketingAllowed,
      modalOpen: false,
      modalMarketingOpen: false,
      modalRekisteriselosteOpen: false
    }
  }

  onOpenModal = () => {
    this.setState({ modalOpen: true });
  };

  onCloseModal = () => {
    this.setState({ modalOpen: false });
  };

  onOpenMarketingModal = () => {
    this.setState({ modalMarketingOpen: true });
  };

  onCloseMarketingModal = () => {
    this.setState({ modalMarketingOpen: false });
  };
  onOpenRekisteriselosteModal = () => {
    this.setState({ modalRekisteriselosteOpen: true });
  };

  onCloseRekisteriselosteModal = () => {
    this.setState({ modalRekisteriselosteOpen: false });
  };
  getInput() {
    return (
      <div className="flex__holder">
        <input
          className="card__checkbox"
          type="checkbox" id="terms"
          checked={this.state.eulaAccepted}
          onChange={e => this.setState({ eulaAccepted: e.target.checked })}
        />
        <label htmlFor="terms" className="card__label--checkbox">Hyväksyn <button type="button" className="link-button" onClick={this.onOpenModal}>käyttöehdot</button> sekä henkilötietojeni tallennuksen <button type="button" className="link-button" onClick={this.onOpenRekisteriselosteModal}>rekisteriselosteen</button> mukaisesti</label>
        
        <input
          className="card__checkbox"
          type="checkbox"
          id="marketing"
          checked={this.state.marketingAllowed}
          onChange={e => this.setState({ marketingAllowed: e.target.checked })}
        />
        <label htmlFor="marketing" className="card__label--checkbox">Minulle saa lähettää <button type="button" className="link-button" onClick={this.onOpenMarketingModal}>ehtojen</button> mukaisia markkinointiviestejä</label>

     
      </div>
      )
  }

  render() {
    const { modalOpen, modalMarketingOpen, modalRekisteriselosteOpen } = this.state;
    return (
      <div>
        <Card
          progress={this.props.progress}
          heading="Käyttöehdot"
          subheading=""
          description="Tutustu ja hyväksy alla olevat ehdot ja suostumukset rekisteröityäksesi palveluun."
          input={this.getInput()}
          disabled={!this.state.eulaAccepted}
          buttontext="Valmis"
          buttonid="btn-rekisterointi-valmis"
          onSubmit={() => this.props.createUser(this.state.eulaAccepted, this.state.marketingAllowed) }
        />
        <Modal open={modalOpen} 
          onClose={this.onCloseModal} 
          classNames={{modal: 'tos-modal'}}>
          <TosText />
        </Modal>

        <Modal open={modalMarketingOpen} 
          onClose={this.onCloseMarketingModal} 
          classNames={{modal: 'modal-text'}}>
          <MarketingText />
        </Modal>

        <Modal open={modalRekisteriselosteOpen} 
          onClose={this.onCloseRekisteriselosteModal} 
          classNames={{modal: 'modal-text'}}>
          <TietosuojaSelosteText />
        </Modal>

      </div>
    )
  }
}
